import { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { IoMdAdd, IoMdCheckmark, IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import productsData from '../../json/products.json';
import servicesData from '../../json/services.json';
import { useNavigate } from "react-router-dom";

const getCategoryList = (orderType) => {
    // Проверяем тип заказа и возвращаем соответствующий список категорий
    if (orderType === 'Товары') {
        return productsData.products.map(item => item.category);
    } else if (orderType === 'Услуги') {
        return servicesData.services.map(item => item.category);
    } else {
        return []; // Возвращаем пустой массив в случае некорректного значения orderType
    }
};

const getSubcategoryList = (orderType, filter) => {
    let subcategoryList = [];

    // Проверяем тип заказа и возвращаем соответствующий список подкатегорий
    if (orderType === 'Товары') {
        subcategoryList = productsData.products.find(item => item.category === filter)?.subcategory || [];
    } else if (orderType === 'Услуги') {
        subcategoryList = servicesData.services.find(item => item.category === filter)?.subcategory || [];
    }

    return subcategoryList;
};

function RegProviderPage() {
    const [step, setStep] = useState(1);
    const [data, setData] = useState({
        fullname: "",
        surname: "",
        login: "",
        email: "",
        company: "",
        entity: "",
        bin: "",
        occupation: "",
        password: "",
        confirmPassword: "",
        city: "",
        category: "",
    });
    const [isOpenCity, setIsOpenCity] = useState(false);
    const [typesOfCargo, setTypesOfCargo] = useState([]);
    const [isOpenCategory, setIsOpenCategory] = useState(false);
    const [isOpenSubcategory, setIsOpenSubCategory] = useState(false);
    const categories = getCategoryList(data.occupation);
    const subcategories = getSubcategoryList(data.occupation, data.category);
    const [isCheckbox, setIsCheckbox] = useState(false);
    const [isOpenEntity, setIsOpenEntity] = useState(false);
    const [isHiddenPassword, setIsHiddenPassword] = useState(true);
    const [isHiddenConfirmPassword, setIsHiddenConfirmPassword] = useState(true);
    const [message, setMessage] = useState();
    const availableCities = [
        "Алматы", "Астана", "Шымкент", "Актобе", "Караганда",
        "Тараз", "Павлодар", "Семей", "Усть-Каменогорск", "Талдыкорган",
        "Кызылорда", "Атырау", "Петропавловск", "Туркестан", "Темиртау"
    ];
    const navigate = useNavigate();

    const selectTypesOfCargo = (cargo) => {
        // Проверяем, есть ли тип груза в массиве
        const cargoIndex = typesOfCargo.indexOf(cargo);

        if (cargoIndex !== -1) {
            // Если тип груза уже присутствует, удаляем его
            const updatedTypes = [...typesOfCargo];
            updatedTypes.splice(cargoIndex, 1);
            setTypesOfCargo(updatedTypes);
        } else {
            // Если тип груза отсутствует, добавляем его
            setTypesOfCargo((prevTypes) => [...prevTypes, cargo]);
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
    };  

    const checkAccount = async () => {
        const isValidEmail = (email) => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const allowedDomains = [
                "gmail.com", "yahoo.com", "outlook.com", "hotmail.com", "mail.ru", "yandex.ru", "icloud.com",
                "aol.com", "mail.com", "rambler.ru", "web.de", "gmx.de", "qq.com", "163.com", "126.com",
                "sina.com", "sohu.com", "uol.com.br", "bol.com.br"
                // Добавьте другие допустимые домены сюда
            ];
    
            if (!emailRegex.test(email)) {
                return false;
            }
    
            const emailDomain = email.split('@')[1];
            return allowedDomains.includes(emailDomain);
        };
    
        if (!isValidEmail(data.email)) {
            setMessage("Некорректный email адрес или запрещенный домен.");
            return;
        }
    
        try {
            const response = await fetch("https://bigsnab.kz/api-checkAccount", {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ email: data.email })
            });
    
            const responseJson = await response.json();
    
            if (responseJson.success) {
                setStep(2);
                setMessage("");
            } else {
                setMessage(responseJson.message);
            }
        } catch (error) {
            setMessage("Ошибка при проверке аккаунта.");
            console.error("Error checking account:", error);
        }
    };
    
    const selectCategory = (category) => {
        setData({
            ...data,
            category: category
        });
        setIsOpenCategory(false);
    }

    const selectEntity = (entity) => {
        setData({
            ...data,
            entity: entity
        });
        setIsOpenEntity(false);
    }

    const regProvider = async () => {
        if (data.confirmPassword === data.password) {      
            if (data.confirmPassword.length > 8) {
                setMessage();
                try {
                    const response = await fetch('https://bigsnab.kz/api-regProviderSite', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            fullname: data.fullname,
                            surname: data.surname,
                            city: data.city,
                            email: data.email,
                            login: data.login,
                            password: data.password,
                            company: data.company,
                            productType1: typesOfCargo[0] || null,
                            productType2: typesOfCargo[1] || null,
                            productType3: typesOfCargo[2] || null,
                            productType4: typesOfCargo[3] || null,
                            productType5: typesOfCargo[4] || null,
                            entity: data.entity,
                            bin: data.bin,
                            occupation: data.occupation,
                            category: data.category,
                        })
                    });
        
                    if (response.ok) {
                        setMessage();
                        const responseJson = await response.json();
                        document.cookie = `auth_token=${responseJson.auth_token}; path=/`;
                        window.location.reload();
    
                    } else {
                        console.error('Registration failed:', response.statusText);
                    }
                } catch (error) {
                    console.error('Ошибка при отправке запроса:', error);
                }
            } else {
                setMessage("Пароль должен быть выше 8 символов");
            }
        } else {
            setMessage("Пароли не совпадают");
        }
    }; 

    const selectCity = (city) => {
        setData({ ...data, city: city });
        setIsOpenCity(false);
    }    

    return (
        <div className="bg-white">
            <div className="h-screen w-screen justify-center items-center flex flex-col">
                <p className="text-2xl font-semibold text-[#5BB600] mobile:mb-6 desktop:mb-12">BigSnab</p>
                <div className="bg-white mobile:w-11/12 tablet:w-8/12 laptop:w-5/12 desktop:w-4/12 desktop2:w-96 shadow-xl rounded-tl-[50px] rounded-br-[50px]">
                    <div className="bg-[#438501] rounded-tl-[50px] rounded-br-[50px] mobile:px-6 tablet:px-8 py-6">
                        { step === 1 && (
                            <>
                                <p className="text-lg text-white text-center font-semibold">Создание аккаунт поставщика</p>
                                <div className="mt-6">
                                    <div className="w-full bg-white p-3 rounded-xl mt-4">
                                        <input name="fullname" value={data.fullname} onChange={handleChange} className="bg-transparent outline-none text-md w-full" placeholder="Имя"/>
                                    </div>
                                    <div className="w-full bg-white p-3 rounded-xl mt-4">
                                        <input name="surname" value={data.surname} onChange={handleChange} className="bg-transparent outline-none text-md w-full" placeholder="Фамилия"/>
                                    </div>
                                    <div className="w-full bg-white p-3 rounded-xl mt-4">
                                        <input name="login" value={data.login} onChange={handleChange} className="bg-transparent outline-none text-md w-full" placeholder="Логин"/>
                                    </div>
                                    <div className="w-full bg-white p-3 rounded-xl mt-4">
                                        <input name="email" value={data.email} onChange={handleChange} className="bg-transparent outline-none text-md w-full" placeholder="Email"/>
                                    </div>
                                    <div className="mt-4 relative">
                                        <div className={ isOpenCity ? "w-full bg-white p-4 rounded-t-xl rounded-b-none flex flex-row justify-between items-center" : "w-full bg-white p-3 rounded-xl flex flex-row justify-between items-center" }>
                                            <p className="text-md opacity-40">{ data.city ? data.city : "Город" }</p>
                                            <button onClick={() => setIsOpenCity(!isOpenCity)} className="hover:opacity-50">
                                                { isOpenCity ? <IoIosArrowUp className="text-lg text-black/40"/> : <IoIosArrowDown className="text-lg text-black/40"/> }
                                            </button>
                                        </div>
                                        { isOpenCity && (
                                            <div className="bg-white rounded-b-xl p-3 absolute z-10 w-full h-32 overflow-y-auto shadow-2xl flex flex-col items-start">
                                                {availableCities.map((city, index) => (
                                                    <button
                                                        key={index}
                                                        onClick={() => selectCity(city)}
                                                        className="hover:opacity-50 py-1 w-full flex flex-row items-center justify-between"
                                                    >
                                                        <p className="text-md">{city}</p>
                                                    </button>
                                                ))}
                                            </div>
                                        ) }
                                    </div>
                                </div>
                            </>
                        ) }
                        { step === 2 && (
                            <>
                                <p className="text-lg text-white text-center font-semibold">Чем вы занимаетесь?</p>
                                <p className="text-lg text-white text-center mt-1">Определите свою нишу</p>
                                <div className="flex flex-row space-x-12 justify-center mt-6">
                                    <button onClick={() => setData({ ...data, occupation: "Услуги" })} className={ data.occupation === 'Услуги' ? "flex flex-col space-y-2 justify-center items-center opacity-50" : "flex flex-col space-y-2 justify-center items-center hover:opacity-50" }>
                                        <div className="bg-white rounded-full p-6">
                                            <img className="w-12 h-12" src={require("../../images/icons/servicesIcon.png")} alt="services"/>
                                        </div>
                                        <p className="text-lg text-white font-semibold">Услуги</p>
                                    </button>
                                    <button onClick={() => setData({ ...data, occupation: "Товары" })} className={ data.occupation === 'Товары' ? "flex flex-col space-y-2 justify-center items-center opacity-50" : "flex flex-col space-y-2 justify-center items-center hover:opacity-50" }>
                                        <div className="bg-white rounded-full p-6">
                                            <img className="w-12 h-12" src={require("../../images/icons/productsIcon.png")} alt="products"/>
                                        </div>
                                        <p className="text-lg text-white font-semibold">Товары</p>
                                    </button>
                                </div>
                            </>
                        ) }
                        { step === 3 && (
                            <>
                                <p className="text-lg text-white text-center font-semibold">Создание аккаунт поставщика</p>
                                <div className="mt-6">
                                    <div className="w-full bg-white p-3 rounded-xl mt-4">
                                        <input name="company" value={data.company} onChange={handleChange} className="bg-transparent outline-none text-md w-full" placeholder="Название организации"/>
                                    </div>
                                    <div className="mt-4 relative">
                                        <div className={ isOpenCategory ? "w-full bg-white p-4 rounded-t-xl rounded-b-none flex flex-row justify-between items-center" : "w-full bg-white p-3 rounded-xl flex flex-row justify-between items-center" }>
                                            <p className="text-lg opacity-40">{ data.category ? data.category : `Категория ${ data.occupation === 'Товары' ? 'товаров' : 'услуг' }` }</p>
                                            <button onClick={() => setIsOpenCategory(!isOpenCategory)}>
                                                { isOpenCategory ? <IoIosArrowUp className="text-lg text-black/40"/> : <IoIosArrowDown className="text-lg text-black/40"/> }
                                            </button>
                                        </div>
                                        { isOpenCategory && (
                                            <div className="bg-white rounded-b-xl p-4 absolute z-10 w-full h-32 overflow-y-auto shadow-2xl flex flex-col items-start justify-start">
                                                { categories.map((item, index) => (
                                                    <button key={index} onClick={() => selectCategory(item)} className="hover:opacity-50 py-1">
                                                        <p className="text-lg text-left">{item}</p>
                                                    </button>
                                                )) }
                                            </div>
                                        ) }
                                    </div>
                                    <div className="mt-4 relative">
                                        <div className={ isOpenSubcategory ? "w-full bg-white p-4 rounded-t-xl rounded-b-none flex flex-row justify-between items-center" : "w-full bg-white p-3 rounded-xl flex flex-row justify-between items-center" }>
                                            <p className="text-lg opacity-40">{typesOfCargo.length > 0 ? typesOfCargo.join(', ') : `Подкатегория ${data.occupation === 'Товары' ? 'товаров' : 'услуг'}`}</p>
                                            <button onClick={() => setIsOpenSubCategory(!isOpenSubcategory)}>
                                                { isOpenSubcategory ? <IoIosArrowUp className="text-lg text-black/40"/> : <IoIosArrowDown className="text-lg text-black/40"/> }
                                            </button>
                                        </div>
                                        { isOpenSubcategory && (
                                            <div className="bg-white rounded-b-xl p-4 absolute z-10 w-full h-32 overflow-y-auto shadow-2xl flex flex-col items-start">
                                                { subcategories.length > 0 ? (
                                                    <>
                                                        { subcategories.map((item, index) => (
                                                            <button key={index} onClick={() => selectTypesOfCargo(item)} className="hover:opacity-50 w-full py-1 flex flex-row items-center justify-between">
                                                                <p className="text-lg text-left">{item}</p>
                                                                { typesOfCargo.includes(item) ? (
                                                                    <IoMdCheckmark className="text-lg text-[#5BB600]"/>
                                                                ) : (
                                                                    <IoMdAdd className="text-lg text-black/40"/>
                                                                ) }
                                                            </button>
                                                        )) }
                                                    </>
                                                ) : (
                                                    <div className="flex justify-center items-center w-full h-full">
                                                        <p className="text-lg text-black text-center">Сначала выберите категорию</p>
                                                    </div>
                                                ) }
                                            </div>
                                        ) }
                                    </div>
                                    <div className="mt-4 relative">
                                        <div className={ isOpenEntity ? "w-full bg-white p-4 rounded-t-xl rounded-b-none flex flex-row justify-between items-center" : "w-full bg-white p-3 rounded-xl flex flex-row justify-between items-center" }>
                                            <p className="text-lg opacity-40">{ data.entity ? data.entity : 'Юр.лицо' }</p>
                                            <button onClick={() => setIsOpenEntity(!isOpenEntity)}>
                                                { isOpenEntity ? <IoIosArrowUp className="text-lg text-black/40"/> : <IoIosArrowDown className="text-lg text-black/40"/> }
                                            </button>
                                        </div>
                                        { isOpenEntity && (
                                            <div className="bg-white rounded-b-xl p-4 absolute z-10 w-full shadow-2xl flex flex-col items-start">
                                                <button onClick={() => selectEntity("Юр.лицо")} className="hover:opacity-50">
                                                    <p className="text-lg text-black py-1">Юр.лицо</p>
                                                </button>
                                                <button onClick={() => selectEntity("Физ.лицо")} className="hover:opacity-50">
                                                    <p className="text-lg text-black py-1">Физ.лицо</p>
                                                </button>
                                            </div>
                                        ) }
                                    </div>
                                    <div className="w-full bg-white p-3 rounded-xl flex flex-row justify-between items-center mt-4">
                                        <input name="bin" value={data.bin} onChange={handleChange} className="bg-transparent outline-none text-md w-full" placeholder={ data.entity === 'Юр.лицо' ? "БИН" : "ИИН" }/>
                                    </div>
                                </div>
                            </>
                        ) }
                        { step === 4 && (
                            <>
                                <p className="text-lg text-white text-center font-semibold">Создание аккаунт поставщика</p>
                                <div className="space-y-6 mt-4">
                                    <div className="w-full bg-white p-3 rounded-xl flex flex-row justify-between items-center">
                                        <input type={ isHiddenPassword && "password" } value={data.password} name="password" onChange={handleChange} className="bg-transparent outline-none text-md w-full" placeholder="Пароль"/>
                                        <button onClick={() => setIsHiddenPassword(!isHiddenPassword)} className="hover:opacity-50">
                                            { isHiddenPassword ? <FiEye className="text-lg text-[#91949E]"/> : <FiEyeOff className="text-lg text-[#91949E]"/> }
                                        </button>
                                    </div>
                                    <div className="w-full bg-white p-3 rounded-xl flex flex-row justify-between items-center">
                                        <input type={ isHiddenConfirmPassword && "password" } value={data.confirmPassword} name="confirmPassword" onChange={handleChange} className="bg-transparent outline-none text-md w-full" placeholder="Подтвердите пароль"/>
                                        <button onClick={() => setIsHiddenConfirmPassword(!isHiddenConfirmPassword)} className="hover:opacity-50">
                                            { isHiddenConfirmPassword ? <FiEye className="text-lg text-[#91949E]"/> : <FiEyeOff className="text-lg text-[#91949E]"/> }
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) }
                    </div>
                    <div className="px-8 py-2">
                        { message && <p className="text-lg text-red-500 text-center">{message}</p> }
                        { step === 1 && (
                            <>
                                <div className="flex flex-row items-center space-x-2 py-4">
                                    <button 
                                        onClick={() => setIsCheckbox(!isCheckbox)} 
                                        className={`w-5 h-5 ${isCheckbox ? "bg-[#438501] justify-center items-center" : "border-2 border-[#91949E]"} hover:opacity-50 rounded-md flex flex-shrink-0 flex-grow-0`}>
                                        { isCheckbox && <IoMdCheckmark className="text-xs text-white"/> }
                                    </button>
                                    <p className="text-[#91949E]">Я согласен на обработку персональных данных</p>
                                </div>
                                <button disabled={!data.fullname || !data.surname || !data.login || !data.email || !data.city || !isCheckbox} onClick={() => checkAccount()} className={ !data.fullname || !data.surname || !data.login || !data.email || !data.city || !isCheckbox ? "bg-[#438501] opacity-50 p-3 w-full rounded-xl" : "bg-[#438501] hover:opacity-50 p-3 w-full rounded-xl" }>
                                    <p className="text-md text-white">Далее</p>
                                </button>
                            </>
                        )}
                        { step === 2 && (
                            <>
                                <button disabled={!data.occupation} onClick={() => setStep(3)} className={ !data.occupation ? "bg-[#438501] opacity-50 p-3 w-full rounded-xl mt-4" : "bg-[#438501] hover:opacity-50 p-3 w-full rounded-xl mt-4" }>
                                    <p className="text-md text-white">Далее</p>
                                </button>
                            </>
                        )}
                        { step === 3 && (
                            <>
                                <button disabled={!data.company || !data.category || !subcategories || !data.entity || !data.bin} onClick={() => setStep(4)} className={ !data.company || !data.category || !subcategories.length > 0 || !data.entity || !data.bin ? "bg-[#438501] opacity-50 p-3 w-full rounded-xl mt-4" : "bg-[#438501] hover:opacity-50 p-3 w-full rounded-xl mt-4"}>
                                    <p className="text-md text-white">Далее</p>
                                </button>
                            </>
                        )}
                        { step === 4 && (
                            <>
                                <button disabled={!data.password || !data.confirmPassword} onClick={regProvider} className={ !data.password || !data.confirmPassword ? "bg-[#438501] opacity-50 p-3 w-full rounded-xl mt-4" : "bg-[#438501] hover:opacity-50 p-3 w-full rounded-xl mt-4"}>
                                    <p className="text-md text-white">Зарегистрироваться</p>
                                </button>
                            </>
                        )}
                        <p className="text-md text-center py-2 text-[#91949E]">Есть аккаунт? <button onClick={() => navigate('/auth')} className="hover:opacity-50"><p className="text-[#5BB600]">Войдите</p></button></p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default RegProviderPage;