function UnitsList({ setUnit, setUnitName, onClose }) {

    const selectUnit = (unitName, unit) => {
        setUnit("unit", unit);
        setUnitName(unitName);
        onClose();
    }
    
    return (
        <div className="fixed inset-0 z-20 flex justify-center items-center">
            <div className="fixed inset-0 bg-black bg-opacity-50" onClick={onClose}></div>
            <div className="mobile:w-10/12 tablet:w-4/12 desktop:w-1/4 absolute z-30 bg-white p-6 px-12 rounded-tl-[50px] rounded-br-[50px]">
                <p className='text-lg font-semibold text-center'>Выбор единицы измерения</p>
                <div className='overflow-y-auto max-h-96'>
                    <button onClick={() => selectUnit("Килограмм", "кг")} className='border-b-[1px] border-b-black/20 w-full py-2 hover:opacity-50'>
                        <p className='text-lg'>Килограмм (кг)</p>
                    </button>
                    <button onClick={() => selectUnit("Центнер", "ц")} className='border-b-[1px] border-b-black/20 w-full py-2 hover:opacity-50'>
                        <p className='text-lg'>Центнер (ц)</p>
                    </button>
                    <button onClick={() => selectUnit("Тонна", "т")} className='border-b-[1px] border-b-black/20 w-full py-2 hover:opacity-50'>
                        <p className='text-lg'>Тонна (т)</p>
                    </button>
                    <button onClick={() => selectUnit("Миллиметр", "мм")} className='border-b-[1px] border-b-black/20 w-full py-2 hover:opacity-50'>
                        <p className='text-lg'>Миллиметр (мм)</p>
                    </button>
                    <button onClick={() => selectUnit("Сантиметр", "см")} className='border-b-[1px] border-b-black/20 w-full py-2 hover:opacity-50'>
                        <p className='text-lg'>Сантиметр (см)</p>
                    </button>
                    <button onClick={() => selectUnit("Метр", "м")} className='border-b-[1px] border-b-black/20 w-full py-2 hover:opacity-50'>
                        <p className='text-lg'>Метр (м)</p>
                    </button>
                    <button onClick={() => selectUnit("Квадратный сантиметр", "см²")} className='border-b-[1px] border-b-black/20 w-full py-2 hover:opacity-50'>
                        <p className='text-lg'>Квадратный сантиметр (см²)</p>
                    </button>
                    <button onClick={() => selectUnit("Квадратный метр", "м²")} className='border-b-[1px] border-b-black/20 w-full py-2 hover:opacity-50'>
                        <p className='text-lg'>Квадратный метр (м²)</p>
                    </button>
                    <button onClick={() => selectUnit("Кубический сантиметр", "см³")} className='border-b-[1px] border-b-black/20 w-full py-2 hover:opacity-50'>
                        <p className='text-lg'>Кубический сантиметр (см³)</p>
                    </button>
                    <button onClick={() => selectUnit("Кубический метр", "м³")} className='border-b-[1px] border-b-black/20 w-full py-2 hover:opacity-50'>
                        <p className='text-lg'>Кубический метр (м³)</p>
                    </button>
                    <button onClick={() => selectUnit("Литр", "л")} className='border-b-[1px] border-b-black/20 w-full py-2 hover:opacity-50'>
                        <p className='text-lg'>Литр (л)</p>
                    </button>
                </div>
            </div>
        </div>
    )
};

export default UnitsList;