import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactCodeInput from "react-code-input";
import { FiEye, FiEyeOff } from "react-icons/fi";

function RecoveryPass() {
    const navigation = useNavigate();
    const [step, setStep] = useState(1);
    const [data, setData] = useState({
        email: "",
        code: "",
        newPassword: "",
        confirmPassword: ""
    });
    const [message, setMessage] = useState();
    const [isHiddenNewPassword, setIsHiddenNewPassword] = useState(true);
    const [isHiddenConfirmPassword, setIsHiddenConfirmPassword] = useState(true);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
    };

    const handleCodeChange = (value) => {
        setData({
            ...data,
            code: value
        });
    };                         
    

    const sendEmail = async () => {
        try {
            const response = await fetch('https://bigsnab.kz/api-passwordRecovery', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ methodRecovery: 'email', recoveryValue: data.email })
            });
            const responseJson = await response.json();
            if (responseJson.success) {
                setStep(2);
                setMessage();
            } else {
                if (responseJson.message === 'Пользователь с таким email не найден') {
                    setMessage(responseJson.message)
                } else {
                    setMessage();
                }
            }
        } catch (error) {
            console.error('Error sending code:', error);
        }
    };

    const sendCode = async () => {
        try {
            const response = await fetch('https://bigsnab.kz/api-verifyRecoveryCode', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ recoveryCode: data.code })
            });
            const responseJson = await response.json();
            if (responseJson.success) {
                setMessage();
                setStep(3)
            } else {
                setMessage(responseJson.message)
            }
        } catch (error) {
            console.error('Error sending code:', error);
        }
    };

    const changePassword = async () => {
        if (data.newPassword === data.confirmPassword) {
            if (data.confirmPassword.length > 8) {
                setMessage();
                try {
                    const response = await fetch('https://bigsnab.kz/api-updatePassword', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ email: data.email, newPassword: data.newPassword  })
                    });
        
                    const responseJson = await response.json();
                    if (responseJson.success) {
                        setMessage()
                        alert("Пароль успешно изменён. Теперь вы можете вернуться к авторизации");
                        navigation("/auth")
                    } else {
    
                    }
                    
                } catch (error) {
                    console.error('Error changed passwod:', error);
                }
            } else {
                setMessage("Пароль должен быть выше 8 символов");
            }
        } else {
            setMessage("Пароли не совпадают")
        }
    };

    return (
        <div className="bg-white">
            <div className="h-screen w-screen justify-center items-center flex flex-col">
                <p className="text-2xl font-semibold text-[#5BB600] mobile:mb-6 desktop:mb-12">BigSnab</p>
                <div className="bg-white mobile:w-11/12 tablet:w-8/12 laptop:w-5/12 desktop:w-4/12 desktop2:w-3/12 shadow-xl rounded-tl-[50px] rounded-br-[50px]">
                    <div className="bg-[#438501] rounded-tl-[50px] rounded-br-[50px] mobile:px-6 tablet:px-12 py-6">
                        <p className="text-lg text-white text-center font-semibold">Смена пароля</p>
                        { step === 1 && (
                            <>
                                <p className="text-md mt-1 text-white text-center">Пожалуйста, укажите адрес электронной почты, который вы указывали при регистрации.</p>
                                <div className="w-full bg-white p-4 rounded-xl mt-4">
                                    <input name="email" value={data.email} onChange={handleChange} className="bg-transparent outline-none text-lg w-full" placeholder="Email"/>
                                </div>
                            </>
                        ) }
                        { step === 2 && (
                            <>
                                <p className="text-md mt-1 text-white text-center">Введите шестизначный код, который пришел на вашу почту.</p>
                                <ReactCodeInput
                                    fields={6}
                                    name="code"
                                    value={data.code}
                                    onChange={handleCodeChange}
                                    inputStyle={{
                                        width: '50px', // Устанавливаем ширину каждой ячейки
                                        height: '50px', // Устанавливаем высоту каждой ячейки
                                        margin: '0 5px', // Устанавливаем отступ между ячейками
                                        fontSize: '24px', // Размер шрифта в ячейках
                                        textAlign: 'center', // Выравнивание текста по центру
                                        borderRadius: '10px', // Скругление углов ячеек
                                        border: '1px solid #CFCFCF', // Стиль границы ячеек,
                                        outline: 'none', // Убираем обводку при фокусе
                                        '&:focus': {
                                            borderColor: '#44CD8D', // Цвет границы при фокусе
                                        },
                                        marginTop: 12
                                    }}
                                    autoFocus // Автоматически фокусировать первую ячейку при загрузке
                                />
                            </>
                        ) }
                        { step === 3 && (
                            <>
                                <p className="text-md mt-1 text-white text-center">Введите новый пароль, который будет действительным.</p>
                                <div className="w-full bg-white p-4 rounded-xl flex flex-row justify-between items-center mt-4">
                                    <input type={ isHiddenNewPassword && "password" } value={data.newPassword} name="newPassword" onChange={handleChange} className="bg-transparent outline-none text-lg" placeholder="Пароль"/>
                                    <button onClick={() => setIsHiddenNewPassword(!isHiddenNewPassword)} className="hover:opacity-50">
                                        { isHiddenNewPassword ? <FiEye className="text-lg text-[#91949E]"/> : <FiEyeOff className="text-lg text-[#91949E]"/> }
                                    </button>
                                </div>
                                <div className="w-full bg-white p-4 rounded-xl flex flex-row justify-between items-cente mt-4">
                                    <input type={ isHiddenConfirmPassword && "password" } value={data.confirmPassword} name="confirmPassword" onChange={handleChange} className="bg-transparent outline-none text-lg" placeholder="Подтвердите пароль"/>
                                    <button onClick={() => setIsHiddenConfirmPassword(!isHiddenConfirmPassword)} className="hover:opacity-50">
                                        { isHiddenConfirmPassword ? <FiEye className="text-lg text-[#91949E]"/> : <FiEyeOff className="text-lg text-[#91949E]"/> }
                                    </button>
                                </div>
                            </>
                        ) }
                    </div>
                    <div className="px-12 py-6">
                        { message && <p className="text-red-500 text-center text-md mb-6">{message}</p> }
                        { step === 1 && (
                            <button onClick={sendEmail} disabled={!data.email} className={ !data.email ? "bg-[#438501] opacity-50 p-4 w-full rounded-xl" : "bg-[#438501] hover:opacity-50 p-4 w-full rounded-xl" }>
                                <p className="text-lg text-white">Далее</p>
                            </button>
                        ) }
                        { step === 2 && (
                            <button onClick={sendCode} disabled={!data.email} className={ !data.email ? "bg-[#438501] opacity-50 p-4 w-full rounded-xl" : "bg-[#438501] hover:opacity-50 p-4 w-full rounded-xl" }>
                                <p className="text-lg text-white">Далее</p>
                            </button>
                        ) }
                        { step === 3 && (
                            <button onClick={changePassword} disabled={!data.email} className={ !data.email ? "bg-[#438501] opacity-50 p-4 w-full rounded-xl" : "bg-[#438501] hover:opacity-50 p-4 w-full rounded-xl" }>
                                <p className="text-lg text-white">Сохранить</p>
                            </button>
                        ) }
                        <p className="text-md text-center py-2 text-[#91949E]">Есть аккаунт? <button onClick={() => navigation("/auth")} className="hover:opacity-50"><p className="text-[#5BB600]">Войдите</p></button></p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default RecoveryPass;