import productsData from '../../json/products.json'; 
import servicesData from '../../json/services.json'
import { useState } from 'react';

function Cities({ onClose, setCity }) {
    const availableCities = [
        "Алматы", "Астана", "Шымкент", "Актобе", "Караганда",
        "Тараз", "Павлодар", "Семей", "Усть-Каменогорск", "Талдыкорган",
        "Кызылорда", "Атырау", "Петропавловск", "Туркестан", "Темиртау"
    ];

    const handleCity = (city) => {
        setCity("city", city);
        onClose();
    };

    return (
        <div className="fixed inset-0 z-20 flex justify-center items-center">
            <div className="fixed inset-0 bg-black bg-opacity-50" onClick={onClose}></div>
            <div className="mobile:w-10/12 tablet:w-4/12 desktop:w-1/4 absolute z-30 bg-white p-6 px-12 rounded-tl-[50px] rounded-br-[50px]">
                <p className='text-lg font-semibold text-center'>Выбор города</p>
                <div className='overflow-y-auto max-h-96'>
                    { availableCities.map((item, index) => (
                        <button key={index} onClick={() => handleCity(item)} className='border-b-[1px] border-b-black/20 w-full py-2 hover:opacity-50'>
                            <p className='text-lg'>{item}</p>
                        </button>
                    )) }
                </div>
            </div>
        </div>
    )
};

export default Cities;