import { useState } from "react";
import Navbar from "../ux/Navbar";
import PaySubscription from "../ux/PaySubscription";

function Subscription() {
    const [isOpenSubscription, setIsOpenSubscription] = useState(false);
    const [selectedPrice, setSelectedPrice] = useState(); 
    const [selectedSubscription, setSelectedSubscription] = useState();
    

    const openSubscriptionModal = (price, subscription) => {
        setSelectedPrice(price);
        setSelectedSubscription(subscription);
        setIsOpenSubscription(true);
    }

    return (
        <div>
            <div className="w-full h-screen justify-center items-center flex flex-col">
                <div className="w-full h-full p-2 flex mobile:flex-col laptop:flex-row mobile:overflow-y-auto laptop:overflow-y-hidden justify-center items-center mobile:space-x-0 laptop:space-x-6 mobile:space-y-12 laptop:space-y-0">
                    <div className="mobile:mt-[1000px] laptop:mt-[0px] bg-gradient-to-br from-[#438501] to-[#79E300] relative p-6 flex flex-col justify-center items-center w-64 rounded-tl-[64px] rounded-br-[64px] h-4/6">
                        <p className="text-lg font-semibold text-white">Бронза</p>
                        <p className="text-lg font-light text-white">1 месяц | 1000 тг</p>
                        <div className="mt-6 space-y-2">
                            <p className="text-center text-white mobile:text-md laptop:text-lg font-light">До 3 пользователей в команде</p>
                            <p className="text-center text-white mobile:text-md laptop:text-lg font-light">До 3 товаров в ассортименте</p>
                            <p className="text-center text-white mobile:text-md laptop:text-lg font-light">До 6 откликов на заказы в месяц</p>
                        </div>
                        <button onClick={() => openSubscriptionModal(1000, "Бронза")} className="p-4 bg-white rounded-xl z-0 mt-6 hover:opacity-50">
                            <p className="text-[#438501] text-lg">Приобрести</p>
                        </button>
                    </div>
                    <div className="bg-gradient-to-br from-[#438501] to-[#79E300] relative p-6 flex flex-col justify-center items-center w-64 rounded-tl-[64px] rounded-br-[64px] h-4/6">
                        <p className="text-lg font-semibold text-white">Серебро</p>
                        <p className="text-lg font-light text-white">1 месяц | 2500 тг</p>
                        <div className="mt-6 space-y-2">
                            <p className="text-center text-white mobile:text-md laptop:text-lg font-light">До 6 пользователей в команде</p>
                            <p className="text-center text-white mobile:text-md laptop:text-lg font-light">До 6 товаров в ассортименте</p>
                            <p className="text-center text-white mobile:text-md laptop:text-lg font-light">До 12 откликов на заказы в месяц</p>
                        </div>
                        <button onClick={() => openSubscriptionModal(2500, "Серебро")} className="p-4 bg-white rounded-xl z-0 mt-6 hover:opacity-50">
                            <p className="text-[#438501] text-lg">Приобрести</p>
                        </button>
                    </div>
                    <div className="bg-gradient-to-br from-[#438501] to-[#79E300] p-6 flex relative flex-col justify-center items-center w-64 rounded-tl-[64px] rounded-br-[64px] h-4/6">
                        <p className="text-lg font-semibold text-white">Золото</p>
                        <p className="text-lg font-light text-white">1 месяц | 5000 тг</p>
                        <div className="mt-6 space-y-2">
                            <p className="text-center text-white mobile:text-md laptop:text-lg font-light">До 12 пользователей в команде</p>
                            <p className="text-center text-white mobile:text-md laptop:text-lg font-light">До 12 товаров в ассортименте</p>
                            <p className="text-center text-white mobile:text-md laptop:text-lg font-light">До 16 откликов на заказы в месяц</p>
                        </div>
                        <button onClick={() => openSubscriptionModal(5000, "Золото")} className="p-4 bg-white rounded-xl z-0 mt-6 hover:opacity-50">
                            <p className="text-[#438501] text-lg">Приобрести</p>
                        </button>
                    </div>
                    <div className="bg-gradient-to-br from-[#438501] to-[#79E300] p-6 flex relative flex-col justify-center items-center w-64 rounded-tl-[64px] rounded-br-[64px] h-4/6">
                        <p className="text-lg font-semibold text-white">Платина</p>
                        <p className="text-lg font-light text-white">1 месяц | 6500 тг</p>
                        <div className="mt-6 space-y-2">
                            <p className="text-center text-white mobile:text-md laptop:text-lg font-light">До ∞ пользователей в команде</p>
                            <p className="text-center text-white mobile:text-md laptop:text-lg font-light">До ∞ товаров в ассортименте</p>
                            <p className="text-center text-white mobile:text-md laptop:text-lg font-light">∞ откликов на заказы в месяц</p>
                        </div>
                        <button onClick={() => openSubscriptionModal(6500, "Платина")} className="p-4 bg-white rounded-xl z-0 mt-6 hover:opacity-50">
                            <p className="text-[#438501] text-lg">Приобрести</p>
                        </button>
                    </div>
                </div>
            </div>
            { isOpenSubscription && <PaySubscription price={selectedPrice} onClose={() => setIsOpenSubscription(false)} subscription={selectedSubscription}/> }
        </div>
    )
};

export default Subscription;