import { useState, useEffect } from "react";
import Navbar from "../ux/Navbar";
import { IoIosArrowForward } from "react-icons/io";
import ListOne from "../ux/ListOne";
import ListTwo from "../ux/ListTwo";
import { useNavigate } from "react-router-dom";
import WarningModal from "../ux/WarningModal";
import ReactLoading from 'react-loading';

function AddProduct() {
    const [orderType, setOrderType] = useState('Товары');
    const [isOpenListOne, setIsOpenListOne] = useState(false);
    const [isOpenListTwo, setIsOpenListTwo] = useState(false);
    const [data, setData] = useState({
        title: '',
        price: '',
        count: '',
        addtionalInfo: [],
        previewImage: '',
        comment: '',
    });
    const [userData, setUserData] = useState({});
    const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
    const navigation = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchUserInfo();
    }, []);

    const fetchUserInfo = async () => {
        const authToken = document.cookie.replace(/(?:(?:^|.*;\s*)auth_token\s*=\s*([^;]*).*$)|^.*$/, "$1");

        try {
            const response = await fetch(`https://bigsnab.kz/api-getUserInfo`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}` // Устанавливаем токен в заголовке Authorization
                }
            });

            const data = await response.json();

            if (data.success) {
                setUserData(data.user);
                console.log("data.user.providerId", data.user.providerId);
                fetchProviderInfo(data.user.providerId);
            } else {
                // Обработка ошибки, если не удалось получить информацию о пользователе
                console.error("Failed to fetch user data:", data.message);
            }
        } catch (error) {
            // Обработка ошибки запроса
            console.error("Error fetching user data:", error);
        } finally {
            setIsLoading(false)
        }
    };

    const fetchProviderInfo = async (providerId) => {
        try {
            const response = await fetch(`https://bigsnab.kz/api-getProviderInfo/${providerId}`);

            const responseData = await response.json();

            if (responseData.success) {
                setOrderType(responseData.providerInfo[0].occupation);
                console.log("responseData.providerInfo[0].occupation: ", responseData.providerInfo[0].occupation)
            }
        } catch (error) {
            console.log("fetch provider info error: ", error);
        }
    }

    const handleInputChange = (name, value) => {
        if (name === 'addtionalInfo') {
            // Если категория уже есть в списке, удаляем ее
            if (data.addtionalInfo.includes(value)) {
                setData(prevData => ({
                    ...prevData,
                    addtionalInfo: prevData.addtionalInfo.filter(item => item !== value)
                }));
            } else {
                // Иначе добавляем категорию в список
                setData(prevData => ({
                    ...prevData,
                    addtionalInfo: [...prevData.addtionalInfo, value]
                }));
            }
        } else {
            // Если это не категория товара, обрабатываем как обычное поле
            setData(prevData => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const addProduct = async () => {
        try {
            const response = await fetch('https://bigsnab.kz/api-createAssortment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    title: data.title,
                    additionalInfo1: data.addtionalInfo[0] || null,
                    additionalInfo2: data.addtionalInfo[1] || null,
                    additionalInfo3: data.addtionalInfo[2] || null,
                    additionalInfo4: data.addtionalInfo[3] || null,
                    additionalInfo5: data.addtionalInfo[4] || null,
                    userId: userData?.id,
                    clientName: `${userData?.fullname} ${userData?.surname}`,
                    previewImage: data.previewImage,
                    providerId: userData.providerId
                })
            });

            const responseData = await response.json();

            if (responseData.success) {
                navigation("/range");
                window.location.reload();
            } else {
                if (responseData.message === 'Limit reached') {
                    setIsOpenWarningModal(true);
                } 
            }

        } catch {

        }
    };

    return (
        <>
            { isLoading && (
                <div className="w-screen h-screen flex justify-center items-center bg-[#438501]">
                    <ReactLoading type="spin" color="#FFF" height={25} width={25} />
                </div>
            ) }
            { !isLoading && (
                <div>
                    <Navbar/>
                    <div className="w-screen h-screen flex flex-col justify-center items-center">
                        <p className="text-lg text-[#292B2F] font-semibold mt-16">Добавление заказа</p>
                        <div className="overflow-x-auto max-h-[700px] px-2">
                            <div className="border-b-[1px] border-b-[#91949E] mobile:w-64 tablet:w-96 py-4 px-2">
                                <div className="flex flex-row items-center justify-between">
                                    <p className="text-lg text-[#292B2F]">{ data.title ? data.title :'Выберите название' }</p>
                                    <button onClick={() => setIsOpenListOne(true)} className="hover:opacity-50 w-6 h-6 rounded-full justify-center items-center flex bg-[#91949E]">
                                        <IoIosArrowForward className="text-md text-white"/>
                                    </button>
                                </div>
                            </div>
                            <div className="border-b-[1px] border-b-[#91949E] mobile:w-64 tablet:w-96 py-4 px-2">
                                <div className="flex flex-row items-center justify-between">
                                    <div className="flex-1 flex flex-row flex-wrap">
                                        { data.addtionalInfo.length > 0 ? (
                                            <>
                                                { data.addtionalInfo.map((item, index) => (
                                                    <div key={index} className="bg-[#5BB600] px-3.5 py-2 m-1 rounded-full">
                                                        <p className="text-md text-white">{item}</p>
                                                    </div>
                                                )) }
                                            </>
                                        ) : (
                                            <p className="text-lg text-[#292B2F] opacity-50">Дополнительные харак.{ orderType === 'Товары' ? 'товара' : 'услуги' }</p>
                                        ) }
                                    </div>
                                    <button onClick={() => setIsOpenListTwo(true)} className="hover:opacity-50 w-6 h-6 rounded-full justify-center items-center flex bg-[#91949E]">
                                        <IoIosArrowForward className="text-md text-white"/>
                                    </button>
                                </div>
                            </div>
                            <button onClick={addProduct} className="p-4 mobile:w-64 tablet:w-96 bg-[#438501] rounded-xl mt-6 hover:opacity-50 mb-12">
                                <p className="text-lg text-white">Сохранить</p>
                            </button>
                        </div>
                    </div>
                    { isOpenListOne && <ListOne orderType={orderType} setCategory={handleInputChange} onClose={() => setIsOpenListOne(false)}/> }
                    { isOpenListTwo && <ListTwo addtionalInfo={data.addtionalInfo} orderType={orderType} filter={data.title} onClose={() => setIsOpenListTwo(false)} setCategory={handleInputChange}/> }
                    { isOpenWarningModal && <WarningModal onClose={() => setIsOpenWarningModal(false)} title="Вы достигли лимита заказов в ассортименте" description="В этом месяце вы достигли лимита заказов в ассортименте. Вы можете приобрести более лучшую версию подписки и снять ограничения"/> }
                </div>
            ) }
        </>
    )
};

export default AddProduct;