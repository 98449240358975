import { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { IoMdCheckmark, IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useNavigate } from "react-router-dom";
function RegClientPage() {
    const [step, setStep] = useState(1);
    const [data, setData] = useState({
        fullname: "",
        surname: "",
        login: "",
        email: "",
        city: "",
        password: "",
        confirmPassword: ""
    });
    const [message, setMessage] = useState();
    const [isHiddenPassword, setIsHiddenPassword] = useState(true);
    const [isHiddenConfirmPassword, setIsHiddenConfirmPassword] = useState(true);
    const [isCheckbox, setIsCheckbox] = useState(false);
    const [isOpenCity, setIsOpenCity] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
    };    

    const checkAccount = async () => {
        const isValidEmail = (email) => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const allowedDomains = [
                "gmail.com", "yahoo.com", "outlook.com", "hotmail.com", "mail.ru", "yandex.ru", "icloud.com",
                "aol.com", "mail.com", "rambler.ru", "web.de", "gmx.de", "qq.com", "163.com", "126.com",
                "sina.com", "sohu.com", "uol.com.br", "bol.com.br"
                // Добавьте другие допустимые домены сюда
            ];
    
            if (!emailRegex.test(email)) {
                return false;
            }
    
            const emailDomain = email.split('@')[1];
            return allowedDomains.includes(emailDomain);
        };
    
        if (!isValidEmail(data.email)) {
            setMessage("Некорректный email адрес или запрещенный домен.");
            return;
        }
    
        try {
            const response = await fetch("https://bigsnab.kz/api-checkAccount", {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ email: data.email })
            });
    
            const responseJson = await response.json();
    
            if (responseJson.success) {
                setStep(2);
                setMessage("");
            } else {
                setMessage(responseJson.message);
            }
        } catch (error) {
            setMessage("Ошибка при проверке аккаунта.");
            console.error("Error checking account:", error);
        }
    };
    

    const regClient = async () => {
        if (data.confirmPassword === data.password) {
            if (data.confirmPassword.length > 8) {
                setMessage();
                try {
                    const response = await fetch('https://bigsnab.kz/api-regClientSite', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ fullname: data.fullname, surname: data.surname, email: data.email, city: data.city, login: data.login, password: data.password })
                    });
    
                    if (response.ok) {
                        setMessage();
                        const responseData = await response.json();
                        document.cookie = `auth_token=${responseData.auth_token}; path=/`;
                        window.location.reload();
                        
                    } else {
                        console.error('Registration failed:', response.statusText);
                    }
                } catch (error) {
                    console.error('Error registering client:', error);
                }
            } else {
                setMessage("Пароль должен быть выше 8 символов");
            }
        } else {
            setMessage("Пароли не совпадают");
        }
    };

    const selectCity = (city) => {
        setData({
            ...data,
            city: city
        });
        setIsOpenCity(false);
    };

    return (
        <div className="bg-white">
            <div className="h-screen w-screen justify-center items-center flex flex-col">
                <p className="text-2xl font-semibold text-[#5BB600] mobile:mb-6 desktop:mb-12">BigSnab</p>
                <div className="bg-white mobile:w-11/12 tablet:w-8/12 laptop:w-5/12 desktop:w-4/12 desktop2:w-96 shadow-xl rounded-tl-[50px] rounded-br-[50px]">
                    <div className="bg-[#438501] rounded-tl-[50px] rounded-br-[50px] py-6 mobile:px-6 tablet:px-8">
                        <p className="text-lg text-white text-center font-semibold">Создание аккаунт заказчика</p>
                        { step === 1 && (
                            <div className="mt-6">
                                <div className="w-full bg-white p-3 rounded-xl mt-4">
                                    <input name="fullname" value={data.fullname} onChange={handleChange} className="w-full bg-transparent outline-none text-md" placeholder="Имя"/>
                                </div>
                                <div className="w-full bg-white p-3 rounded-xl mt-4">
                                    <input name="surname" value={data.surname} onChange={handleChange} className="w-full bg-transparent outline-none text-md" placeholder="Фамилия"/>
                                </div>
                                <div className="w-full bg-white p-3 rounded-xl mt-4">
                                    <input name="login" value={data.login} onChange={handleChange} className="w-full bg-transparent outline-none text-md" placeholder="Логин"/>
                                </div>
                                <div className="w-full bg-white p-3 rounded-xl mt-4">
                                    <input name="email" value={data.email} onChange={handleChange}  className="w-full bg-transparent outline-none text-md" placeholder="Email"/>
                                </div>
                                <div className="mt-4 relative">
                                    <div className={ isOpenCity ? "w-full bg-white p-4 rounded-t-xl rounded-b-none flex flex-row justify-between items-center" : "w-full bg-white p-3 rounded-xl flex flex-row justify-between items-center" }>
                                        <p className="text-md opacity-40">{ data.city ? data.city : "Выберите город" }</p>
                                        <button onClick={() => setIsOpenCity(!isOpenCity)} className="hover:opacity-50">
                                            { isOpenCity ? <IoIosArrowUp className="text-lg text-black/40"/> : <IoIosArrowDown className="text-lg text-black/40"/> }
                                        </button>
                                    </div>
                                    { isOpenCity && (
                                        <div className="bg-white rounded-b-xl p-4 absolute z-10 w-full h-32 overflow-y-auto shadow-2xl flex flex-col items-start">
                                            <button onClick={() => selectCity("Алматы")} className="hover:opacity-50 py-1">
                                                <p className="text-md">Алматы</p>
                                            </button>
                                            <button onClick={() => selectCity("Астана")} className="hover:opacity-50 py-1">
                                                <p className="text-md">Астана</p>
                                            </button>
                                            <button onClick={() => selectCity("Шымкент")} className="hover:opacity-50 py-1">
                                                <p className="text-md">Шымкент</p>
                                            </button>
                                            <button onClick={() => selectCity("Актобе")} className="hover:opacity-50 py-1">
                                                <p className="text-md">Актобе</p>
                                            </button>
                                            <button onClick={() => selectCity("Караганда")} className="hover:opacity-50 py-1">
                                                <p className="text-md">Караганда</p>
                                            </button>
                                            <button onClick={() => selectCity("Тараз")} className="hover:opacity-50 py-1">
                                                <p className="text-md">Тараз</p>
                                            </button>
                                            <button onClick={() => selectCity("Павлодар")} className="hover:opacity-50 py-1">
                                                <p className="text-md">Павлодар</p>
                                            </button>
                                            <button onClick={() => selectCity("Семей")} className="hover:opacity-50 py-1">
                                                <p className="text-md">Семей</p>
                                            </button>
                                            <button onClick={() => selectCity("Усть-Каменогорск")} className="hover:opacity-50 py-1">
                                                <p className="text-md">Усть-Каменогорск</p>
                                            </button>
                                            <button onClick={() => selectCity("Талдыкорган")} className="hover:opacity-50 py-1">
                                                <p className="text-md">Талдыкорган</p>
                                            </button>
                                            <button onClick={() => selectCity("Кызылорда")} className="hover:opacity-50 py-1">
                                                <p className="text-md">Кызылорда</p>
                                            </button>
                                            <button onClick={() => selectCity("Атырау")} className="hover:opacity-50 py-1">
                                                <p className="text-md">Атырау</p>
                                            </button>
                                            <button onClick={() => selectCity("Петропавловск")} className="hover:opacity-50 py-1">
                                                <p className="text-md">Петропавловск</p>
                                            </button>
                                            <button onClick={() => selectCity("Туркестан")} className="hover:opacity-50 py-1">
                                                <p className="text-md">Туркестан</p>
                                            </button>
                                            <button onClick={() => selectCity("Темиртау")} className="hover:opacity-50 py-1">
                                                <p className="text-md">Темиртау</p>
                                            </button>
                                        </div>
                                    ) }
                                </div>
                            </div>
                        ) }
                        { step === 2 && (
                            <div className="mt-6 space-y-4"> 
                                <div className="w-full bg-white p-3 rounded-xl flex flex-row justify-between items-center">
                                    <input type={ isHiddenPassword && "password" } value={data.password} name="password" onChange={handleChange} className="w-full bg-transparent outline-none text-md" placeholder="Пароль"/>
                                    <button onClick={() => setIsHiddenPassword(!isHiddenPassword)} className="hover:opacity-50">
                                        { isHiddenPassword ? <FiEye className="text-lg text-[#91949E]"/> : <FiEyeOff className="text-lg text-[#91949E]"/> }
                                    </button>
                                </div>
                                <div className="w-full bg-white p-3 rounded-xl flex flex-row justify-between items-center">
                                    <input type={ isHiddenConfirmPassword && "password" } value={data.confirmPassword} name="confirmPassword" onChange={handleChange} className="w-full bg-transparent outline-none text-md" placeholder="Подтвердите пароль"/>
                                    <button onClick={() => setIsHiddenConfirmPassword(!isHiddenConfirmPassword)} className="hover:opacity-50">
                                        { isHiddenConfirmPassword ? <FiEye className="text-lg text-[#91949E]"/> : <FiEyeOff className="text-lg text-[#91949E]"/> }
                                    </button>
                                </div>
                            </div>
                        ) }
                    </div>
                    <div className="px-8 py-2">
                        { step === 1 && (
                            <>
                                <div className="flex flex-row items-center space-x-2 py-4">
                                    <button 
                                        onClick={() => setIsCheckbox(!isCheckbox)} 
                                        className={`w-5 h-5 ${isCheckbox ? "bg-[#438501] justify-center items-center" : "border-2 border-[#91949E]"} hover:opacity-50 rounded-md flex flex-shrink-0 flex-grow-0`}>
                                        { isCheckbox && <IoMdCheckmark className="text-xs text-white"/> }
                                    </button>
                                    <p className="text-[#91949E]">Я согласен на обработку персональных данных</p>
                                </div>
                                <button disabled={!data.fullname || !data.surname || !data.login || !data.email || !data.city || !isCheckbox} onClick={() => checkAccount()} className={ !data.fullname || !data.surname || !data.login || !data.email || !data.city || !isCheckbox ? "bg-[#438501] opacity-50 p-4 w-full rounded-xl" : "bg-[#438501] hover:opacity-50 p-4 w-full rounded-xl" }>
                                    <p className="text-lg text-white">Далее</p>
                                </button>
                            </>
                        )}
                        { message && <p className="text-center text-md text-red-400 mt-2">{message}</p> }
                        { step === 2 && (
                            <button disabled={!data.password || !data.confirmPassword} onClick={regClient} className={ !data.password || !data.confirmPassword ? "bg-[#438501] opacity-50 p-4 w-full rounded-xl mt-4" : "bg-[#438501] hover:opacity-50 p-4 w-full rounded-xl mt-4" }>
                                <p className="text-lg text-white">Зарегистрироваться</p>
                            </button>
                        ) }
                        <p className="text-md text-center py-2 text-[#91949E]">Есть аккаунт? <button onClick={() => navigate('/auth')} className="hover:opacity-50"><p className="text-[#5BB600]">Войдите</p></button></p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default RegClientPage;