import Navbar from "../ux/Navbar";
import Accordion from "../ux/Accordion";

function AboutUsPage() {
    return (
        <div>
            <Navbar/>
            <div className='mt-24 mobile:hidden laptop:block mobile:px-12 lg:px-28 relative mb-12'>
                <img className="desktop2:rounded-tl-[200px] mobile:rounded-tl-[64px] mobile:rounded-br-[64px] tablet:rounded-tl-[120px] tablet:rounded-br-[120px] laptop:rounded-tl-[150px] desktop2:rounded-br-[200px] laptop:rounded-br-[150px] w-full h-[85vh] object-cover" src={require('../../images/BackgrounImage.png')} alt="Background"/>
                <div className="absolute justify-center items-center flex flex-col top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center z-50">
                    <p className="mobile:text-lg text-center tablet:t-2xl laptop:text-3xl font-medium text-[#5BB600]">BigSnab - Строительная CRM</p>
                    <p className="mobile:text-md tablet:text-md laptop:text-lg font-medium text-[#FFF] my-4">BigSnab - это CRM-система для строительных компаний. Она помогает управлять проектами, заказами и клиентами, упрощая учет материалов и задач. Платформа предоставляет аналитические отчеты и объединяет все аспекты строительного бизнеса в одном удобном интерфейсе.</p>
                    <button className="p-3 px-12 flex justify-center items-center border-2 border-[#5BB600] rounded-xl hover:bg-[#5BB600] hover:text-white transition-all duration-300">
                        <p className="mobile:text-md tablet:text-md laptop:text-x text-[#FFF]">Узнать подробнее</p>
                    </button>
                </div>
            </div>
            <div className='mobile:mt-24 laptop:mt-12 mobile:px-12 lg:px-28 mb-12 relative items-center flex flex-row flex-wrap w-full justify-center'>
                <div className="flex tablet:flex-row mobile:flex-col mobile:space-y-2 laptop:space-y-0 mobile:space-x-0 tablet:space-x-4 flex-row m-6">
                    <div className="bg-[#79E300]/20 w-20 h-20 justify-center items-center flex rounded-full">
                        <img className="w-8 h-8" src={require("../../images/icons/icon1.png")}/>
                    </div>
                    <div className="flex flex-col w-72 space-y-0.5">
                        <p className="text-lg font-medium">Эффективное управление проектами</p>
                        <p className="text-md">Оптимизация рабочих процессов и контроль всех этапов строительных проектов.</p>
                    </div>
                </div>
                <div className="flex tablet:flex-row mobile:flex-col mobile:space-y-2 laptop:space-y-0 mobile:space-x-0 tablet:space-x-4 flex-row m-6">
                    <div className="bg-[#79E300]/20 w-20 h-20 justify-center items-center flex rounded-full">
                        <img className="w-8 h-8" src={require("../../images/icons/icon2.png")}/>
                    </div>
                    <div className="flex flex-col w-72 space-y-0.5">
                        <p className="text-lg font-medium">Улучшенное взаимодействие с клиентами</p>
                        <p className="text-md">Централизованное хранение данных клиентов и истории взаимодействий.</p>
                    </div>
                </div>
                <div className="flex tablet:flex-row mobile:flex-col mobile:space-y-2 laptop:space-y-0 mobile:space-x-0 tablet:space-x-4 flex-row m-6">
                    <div className="bg-[#79E300]/20 w-20 h-20 justify-center items-center flex rounded-full">
                        <img className="w-8 h-8" src={require("../../images/icons/icon3.png")}/>
                    </div>
                    <div className="flex flex-col w-72 space-y-0.5">
                        <p className="text-lg font-medium">Точное управление задачами и графиками</p>
                        <p className="text-md">Организация задач и учет временных рамок для точного планирования.</p>
                    </div>
                </div>
                <div className="flex tablet:flex-row mobile:flex-col mobile:space-y-2 laptop:space-y-0 mobile:space-x-0 tablet:space-x-4 flex-row m-6">
                    <div className="bg-[#79E300]/20 w-20 h-20 justify-center items-center flex rounded-full">
                        <img className="w-8 h-8" src={require("../../images/icons/icon4.png")}/>
                    </div>
                    <div className="flex flex-col w-72 space-y-0.5">
                        <p className="text-lg font-medium">Повышение производительности</p>
                        <p className="text-md">Автоматизация рутинных задач и оптимизация использования ресурсов.</p>
                    </div>
                </div>
                <div className="flex tablet:flex-row mobile:flex-col mobile:space-y-2 laptop:space-y-0 mobile:space-x-0 tablet:space-x-4 flex-row m-6">
                    <div className="bg-[#79E300]/20 w-20 h-20 justify-center items-center flex rounded-full">
                        <img className="w-8 h-8" src={require("../../images/icons/icon5.png")}/>
                    </div>
                    <div className="flex flex-col w-72 space-y-0.5">
                        <p className="text-lg font-medium">Интуитивно понятный интерфейс</p>
                        <p className="text-md">Удобство использования благодаря простому и понятному пользовательскому интерфейсу.</p>
                    </div>
                </div>
                <div className="flex tablet:flex-row mobile:flex-col mobile:space-y-2 laptop:space-y-0 mobile:space-x-0 tablet:space-x-4 flex-row m-6">
                    <div className="bg-[#79E300]/20 w-20 h-20 justify-center items-center flex rounded-full">
                        <img className="w-8 h-8" src={require("../../images/icons/icon6.png")}/>
                    </div>
                    <div className="flex flex-col w-72 space-y-0.5">
                        <p className="text-lg font-medium">Безопасное хранение данных</p>
                        <p className="text-md">Организация задач и учет временных рамок для точного планирования.</p>
                    </div>
                </div>
            </div>
            <div className='mobile:px-12 lg:px-28 relative mb-12 flex laptop:flex-row justify-center items-center laptop:space-x-12 desktop:space-x-0'>
                <div className="laptop:flex mobile:hidden flex-row justify-center space-x-4 flex-1">
                    <img className="rounded-3xl laptop:w-64 desktop:w-80 h-[60vh] object-fit" src={require("../../images/almaty 1.png")}/>
                    <div className="flex flex-col space-y-4">
                        <img className="rounded-3xl laptop:w-64 desktop:w-80 h-[30vh] object-fit" src={require("../../images/almaty 2.png")}/>
                        <img className="rounded-3xl laptop:w-64 desktop:w-80 h-[50vh] object-fit" src={require("../../images/almaty 3.png")}/>
                    </div>
                </div>
                <div className="flex flex-col space-y-2 flex-1">
                    <p className="text-4xl font-semibold text-[#5BB600]">О нас</p>
                    <p className="mobile:text-md laptop:text-md desktop:text-lg font-light mobile:w-full desktop:w-8/12">BigSnab - ваш надежный партнер в управлении строительными проектами. Мы предлагаем современную CRM-платформу, которая обеспечивает эффективное управление проектами, оптимизацию рабочих процессов и улучшенное взаимодействие с клиентами. Наше решение помогает строительным компаниям повысить производительность, сократить временные затраты и принимать обоснованные стратегические решения на основе аналитических данных. Интуитивно понятный интерфейс и высокий уровень безопасности делают BigSnab идеальным выбором для успешного развития вашего строительного бизнеса.</p>
                </div>
            </div>
            <div className='mobile:px-12 lg:px-28 relative mb-12 flex flex-col justify-center items-center'>
                <p className="text-3xl text-[#5BB600] font-medium text-center">Часто задаваемые вопросы</p>
                <Accordion question='Что такое строительная CRM и зачем она нужна?' answer="Строительная CRM (Customer Relationship Management) – это специализированная платформа для управления клиентскими отношениями в строительной отрасли. Она помогает строительным компаниям эффективно управлять проектами, взаимодействовать с клиентами, автоматизировать бизнес-процессы и улучшать коммуникацию внутри команды."/>
                <Accordion question='Какие преимущества применения строительной CRM для бизнеса?' answer="Применение строительной CRM позволяет повысить эффективность управления проектами, улучшить взаимодействие с клиентами, оптимизировать рабочие процессы, повысить прозрачность и контроль над проектами, а также улучшить управленческую отчетность."/>
                <Accordion question='Какие модули обычно включены в строительную CRM?' answer="Среди основных модулей строительной CRM можно выделить управление проектами, календарное планирование, учет клиентов и контактов, автоматизацию маркетинга и продаж, управление документацией, бюджетирование и финансовый учет, а также аналитику и отчетность."/>
                <Accordion question='Какая информация может храниться в строительной CRM?' answer="В строительной CRM может храниться различная информация, включая контактные данные клиентов, историю взаимодействий, данные о проектах (включая стадии выполнения, сроки, бюджеты), документацию проекта (чертежи, спецификации), информацию о поставщиках и подрядчиках, а также отчеты и аналитику по выполненным проектам."/>
            </div>
        </div>
    )
};

export default AboutUsPage;
