import React from 'react';
import Navbar from "../ux/Navbar";
import { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import InviteUser from '../ux/InviteUser'
import { HiOutlineDotsVertical } from "react-icons/hi";
import { IoCloseOutline } from "react-icons/io5";
import WarningModal from '../ux/WarningModal';

function UserPage() {
    const [userData, setUserData] = useState({});
    const [teams, setTeams] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenInviteUser, setIsOpenInviteUser] = useState(false);
    const [selectedId, setSelectedId] = useState();
    const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);

    useEffect(() => {
        fetchUserInfo();
    }, []);

    const fetchUserInfo = async () => {
        const authToken = document.cookie.replace(/(?:(?:^|.*;\s*)auth_token\s*=\s*([^;]*).*$)|^.*$/, "$1");

        try {
            const response = await fetch(`https://bigsnab.kz/api-getUserInfo`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}` // Устанавливаем токен в заголовке Authorization
                }
            });

            const data = await response.json();

            if (data.success) {
                setUserData(data.user);
                console.log(data.user.teamId);
                if (data.user.teamId) {
                    await fetchTeamMembers(data.user.teamId);
                }
            } else {
                // Обработка ошибки, если не удалось получить информацию о пользователе
                console.error("Failed to fetch user data:", data.message);
            }
        } catch (error) {
            // Обработка ошибки запроса
            console.error("Error fetching user data:", error);
        } finally {
            setIsLoading(false); // Устанавливаем isLoading в false после завершения загрузки
        }
    };

    const fetchTeamMembers = async (id) => {
        const response = await fetch(`https://bigsnab.kz/api-selectAllMembers`, {
            method: "POST",
            headers: {
                "Content-Type": 'application/json'
            }, 
            body: JSON.stringify({ teamMemberId: id })
        });

        const responseJson = await response.json();

        if(responseJson.success) {
            console.log("users: ", responseJson.users);
            setTeams(responseJson.users);
        }
    }
    
    const createTeam = async () => {
        const teamId = Math.floor(Math.random() * 1000000000000).toString();
        try {
            const response = await fetch("https://bigsnab.kz/api-createTeam", {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ teamMemberId: teamId, userId: userData.id })
            });

            const responseJson = await response.json();
            if (responseJson.success) {
                window.location.reload();
            }
        } catch {
            
        }
    }

    const deleteTeamate = async (userId) => {
        try {
            const response = await fetch("https://bigsnab.kz/api-deleteTeamate", {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }, 
                body: JSON.stringify({ teamId: userData.teamId, userId: userId })
            });

            const responseJson = await response.json();

            if (responseJson.success) {
                window.location.reload();
            }
        } catch {
            
        }
    }

    return (
        <>
            { isLoading && (
                <div className="w-screen h-screen flex justify-center items-center bg-[#438501]">
                    <ReactLoading type="spin" color="#FFF" height={25} width={25} />
                </div>
            ) }
            { !isLoading && (
                <div className="min-h-screen flex flex-col items-center">
                    <Navbar />
                    { userData?.teamId && (
                        <div className="flex flex-col items-center mobile:w-80 laptop:w-96">
                            <p className="mt-20 text-lg font-semibold text-[#292B2F]">Пользователи</p>
                            <div className='flex flex-col border-b-[1px] border-b-[#91949E] w-full py-3'>
                                <p className='text-md text-[#292B2F]'>Администратор</p>
                                <div className='flex flex-row space-x-2 items-center mt-1'>
                                    <img src={`https://bigsnab.kz/api-userImage/${userData?.photo}`} className='w-12 h-12 rounded-xl'/>
                                    <div className='h-fit'>
                                        <p className='text-md text-[#292B2F]'>{userData?.fullname} {userData?.surname}</p>
                                        <p className='text-md text-[#698BF9] -mt-1'>{userData?.email}</p>
                                    </div>
                                </div>
                            </div>
                            { teams ? (
                                <div className='w-full h-[60vh] pr-2 overflow-x-auto overflow-y-auto flex flex-col'>
                                    { teams.map((item, index) => (
                                        <div key={index} className='flex flex-row w-full items-center justify-between mt-1 py-2'>
                                            <div className='flex flex-row items-center space-x-2'>
                                                <img src={`https://bigsnab.kz/api-userImage/${item?.photo}`} className='w-12 h-12 rounded-xl'/>
                                                <div className='h-fit'>
                                                    <p className='text-md text-[#292B2F]'>{item?.fullname} {item?.surname}</p>
                                                    <p className='text-md text-[#698BF9] -mt-1'>{item?.email}</p>
                                                </div>
                                            </div>
                                            <div className='relative items-start'>
                                                { selectedId === item.id && (
                                                    <div className='absolute z-10 p-2 right-6 bg-white rounded-md shadow-lg'>
                                                        <button onClick={() => deleteTeamate(item.id)} className='hover:opacity-50'>
                                                            <p className='text-[#F35A5A]'>Удалить</p>
                                                        </button>
                                                    </div>
                                                ) }
                                                { selectedId === item.id ? (
                                                    <button onClick={() => setSelectedId()} className='hover:opacity-50'>
                                                        <IoCloseOutline className='text-lg'/>
                                                    </button>
                                                ) : (
                                                    <button onClick={() => setSelectedId(item.id)} className='hover:opacity-50'>
                                                        <HiOutlineDotsVertical className='text-lg'/>
                                                    </button>
                                                ) }
                                            </div>
                                        </div>
                                    )) }
                                </div>
                            ) : (
                                <div className='w-full h-[60vh] overflow-x-auto overflow-y-auto flex flex-col justify-center items-center'>
                                    <p className='text-center text-lg font-semibold'>У вас пока не доблавено пользователей</p>
                                    <p className='text-center text-md font-light'>Здесь будут отображаться все пользователи в вашей команде</p>
                                </div>
                            ) }
                            <button onClick={() => setIsOpenInviteUser(true)} className='bg-[#5BB600] p-3 rounded-lg w-full hover:opacity-50'>
                                <p className='text-center text-lg text-white'>Пригласить пользователя</p>
                            </button>
                        </div>
                    ) }
                    { !userData.teamId && (
                        <div className="flex flex-col items-center h-screen justify-center space-y-2">
                            <p className="mt-20 text-lg font-semibold text-[#292B2F]">Пользователи</p>
                            <p className='mobile:text-md laptop:text-md desktop:text-lg font-semibold'>Добро пожаловать в вашу команду!</p>
                            <p className='mobile:text-base laptop:text-base desktop:text-md text-center mobile:w-10/12 laptop:w-7/12 desktop:w-4/12'>Здесь начнется ваше совместное путешествие к успеху. Пригласите коллег, назначайте задачи и сотрудничайте в режиме реального времени. Ваша команда — ваш путь к достижению большего. Начните, добавив первых участников и создавая задачи!</p>
                            <button onClick={() => createTeam()} className='w-80 hover:opacity-50 bg-[#5BB600] rounded-xl p-3'>
                                <p className='text-white text-center text-lg'>Создать команду</p>
                            </button>
                        </div>
                    ) }
                </div>
            ) }
            { isOpenInviteUser && <InviteUser openWarningModal={() => setIsOpenWarningModal(true)}  onClose={() => setIsOpenInviteUser(false)} providerId={userData?.providerId} teamId={userData?.teamId}/> }
            { isOpenWarningModal && <WarningModal onClose={() => setIsOpenWarningModal(false)} title="Вы достигли лимита пользователей" description="В этом месяце вы достигли лимита пользователей. Вы можете приобрести более лучшую версию подписки и снять ограничения"/> }
        </>
    );
}

export default UserPage;
