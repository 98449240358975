import { useState, useEffect } from "react";
import { IoIosArrowDown, IoIosArrowUp, IoIosArrowBack } from "react-icons/io";

function PaySubscription({ price, onClose, subscription }) {
    const [step, setStep] = useState(1);
    const [isOpenTariff, setIsOpenTariff] = useState(false); 
    const [tariffName, setTariffName] = useState();
    const [calculatedPrice, setCalculatedPrice] = useState(price);
    const [data, setData] = useState({
        email: "",
        tariff: "",
    });

    useEffect(() => {
        // Calculate the price based on the selected tariff
        let newPrice = price;
        if (data.tariff === 6) {
            newPrice *= 2; // Double the price for 6 months
        } else if (data.tariff === 12) {
            newPrice *= 2.5; // Increase price by 2.5 times for 12 months
        }
        setCalculatedPrice(newPrice);
    }, [data.tariff, price]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
    }; 

    const setTariff = (tariff, tariffName) => {
        setData({
            ...data,
            tariff: tariff
        });
        setTariffName(tariffName);
        setIsOpenTariff(false)
    };

    const sendApplication = async () => {
        console.log("test")
        try {
            console.log("test")
            const response = await fetch('https://bigsnab.kz/api-sendApplication', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email: data.email, subscription: subscription, validityRu: tariffName, validityDate: data.tariff, cost: calculatedPrice })
            });

            const responseJson = await response.json();

            if (responseJson.success) {
                alert("Заявка успешно отправлена");
                onClose();
            }
        } catch {

        }
    }

    return (
        <div className="fixed inset-0 z-20 flex justify-center items-center border-2">
            <div className="fixed inset-0 bg-black backdrop-blur-xl bg-opacity-50" onClick={onClose}></div>
            <div className="mobile:w-10/12 tablet:w-4/12 desktop:w-1/4 absolute z-30 bg-white mobile:p-4 laptop:p-6 mobile:px-6 laptop:px-12 rounded-tl-[50px] rounded-br-[50px]">
                { step === 1 && (
                    <>
                        <p className='text-lg font-semibold text-left'>Покупка подписки</p>
                        <p className='text-lg text-left mt-1'>Оформление заказа на покупку подписки</p>
                        <div className="bg-white shadow-xl rounded-xl p-4 mt-4">
                            <input value={data.email} name="email" onChange={(text) => handleChange(text)} className="text-lg text-black outline-none bg-transparent w-full" placeholder="Введите ваш email"/>
                        </div>
                        <div className="bg-white shadow-xl rounded-xl p-4 mt-2 flex flex-col">
                            <div className="w-full flex flex-row justify-between items-center">
                                <p className="text-lg text-black/40 outline-none bg-transparent w-full">{ tariffName ? tariffName : 'Выберите тариф' }</p>
                                <button onClick={() => setIsOpenTariff(!isOpenTariff)} className="hover:opacity-50">
                                    { isOpenTariff ? <IoIosArrowUp className="text-lg text-black/40"/> : <IoIosArrowDown className="text-lg text-black/40"/> }
                                </button>
                            </div>
                            { isOpenTariff && (
                                <div className="flex flex-col items-start justify-start space-y-2 mt-2">
                                    <button onClick={() => setTariff(1, "1 месяц")} className="hover:opacity-50">
                                        <p className="text-lg text-black">1 месяц</p>
                                    </button>
                                    <button onClick={() => setTariff(6, "Пол года")} className="hover:opacity-50">
                                        <p className="text-lg text-black">Пол года</p>
                                    </button>
                                    <button onClick={() => setTariff(12, "1 год")} className="hover:opacity-50">
                                        <p className="text-lg text-black">1 год</p>
                                    </button>
                                </div>
                            ) }
                        </div>
                        <button disabled={!data.email || !data.tariff} onClick={() => setStep(2)} className={ !data.email || !data.tariff ? "opacity-50 mt-6 w-full p-4 rounded-xl bg-[#5BB600]" : "hover:opacity-50 mt-6 w-full p-4 rounded-xl bg-[#5BB600]" }>
                            <p className="text-lg text-white">Далее</p>
                        </button>
                        <button onClick={onClose} className="hover:opacity-50 mt-4 w-full p-4 rounded-xl bg-[#91949E]">
                            <p className="text-lg text-white">Отмена</p>
                        </button>
                    </>
                ) }
                { step === 2 && (
                    <>
                        <div className="flex flex-row space-x-2 items-center">
                            <button onClick={() => setStep(1)} className="hover:opacity-50 bg-[#dfdfdf] flex justify-center items-center rounded-full w-8 h-8">
                                <IoIosArrowBack className="text-lg text-[#5BB600]"/>
                            </button>
                            <p className='mobile:text-lg laptop:text-lg font-semibold text-left'>Оплата подписки</p>
                        </div>
                        <p className='mobile:text-md laptop:text-lg text-left mt-1'>Совершите оплату через мобильное приложение Kaspi, отсканировав QR-код</p>
                        <p className="text-3xl text-center font-semibold text-[#5BB600]">{calculatedPrice} тг</p>
                        <div className="flex  flex-col justify-center items-center">
                            <img className="laptop:w-64 mobile:w-32 laptop:h-64 mobile:h-32" src={require("../../images/qr.png")}/>
                            <p className="mobile:text-xs laptop:text-md text-center mt-4 font-light">GROUP ИП BISSIKEN GROUP (г. Актобе, Проспект Абулхаира хана)</p>
                        </div>
                        <button disabled={!data.email || !data.tariff} onClick={() => sendApplication()} className={ !data.email || !data.tariff ? "opacity-50 mt-6 w-full mobile:p-2 laptop:p-4 rounded-xl bg-[#5BB600]" : "hover:opacity-50 mt-6 w-full mobile:p-2 laptop:p-4 rounded-xl bg-[#5BB600]" }>
                            <p className="mobile:text-md laptop:text-lg text-white">Отправить</p>
                        </button>
                        <button onClick={onClose} className="hover:opacity-50 mt-4 w-full mobile:p-2 laptop:p-4 rounded-xl bg-[#91949E]">
                            <p className="mobile:text-md laptop:text-lg text-white">Отмена</p>
                        </button>
                        <p className="mobile:text-xs laptop:text-md text-center mt-4 font-light">Оплата прошла, но подписка не появилась?<br></br>Напишите нам: <span className="text-[#5BB600]">suppywised@mail.ru</span></p>
                    </>
                ) }
            </div>
        </div>
    )
};

export default PaySubscription;