import { useState } from "react";
import Navbar from "../ux/Navbar";
import Applications from "../ux/Admin/Application";
import Users from "../ux/Admin/Users";

function AdminPage() {
    const [tab, setTab] = useState("users");

    return (
        <div>
            <Navbar/>
            <div className="w-full h-screen justify-center items-center flex flex-col">
                <p className="mt-20 text-lg font-semibold text-[#292B2F]">Пользователи</p>
                <div className="flex flex-row space-x-6">
                    <button onClick={() => setTab('users')} className={ tab === 'users' ? "py-1 border-b-2 border-b-[#79E300]" : "opacity-50 py-1" }>
                        <p className="text-lg text-black font-light">Пользователи</p>
                    </button>
                    <button onClick={() => setTab('applications')} className={ tab === 'applications' ? "py-1 border-b-2 border-b-[#79E300]" : "opacity-50 py-1" }>
                        <p className="text-lg text-black font-light">Заявки</p>
                    </button>
                </div>
                { tab === 'applications' && <Applications/> }
                { tab === 'users' && <Users/> }
            </div>
        </div>
    )
};

export default AdminPage;