import { useState } from "react";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { useNavigate } from "react-router-dom";

// Utility function for formatting prices
function formatPrice(price) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function Card({ data }) {
    const [isOpen, setIsOpen] = useState(false);
    const navigatate = useNavigate();

    const additionalInfo = [
        data.additionalInfo1,
        data.additionalInfo2,
        data.additionalInfo3,
        data.additionalInfo4,
        data.additionalInfo5,
    ].filter(info => info !== null);

    return (
        <div className="m-3 border-[1px] rounded-2xl mobile:w-full laptop:w-[47%] border-[#91949E] p-4 flex flex-col h-fit">
            <div className="flex flex-row w-full">
                <img src={`https://bigsnab.kz/api-previewImage/${data.previewImage}`} className="h-28 w-28 object-cover rounded-2xl bg-[#BDBDBD]" alt={data.previewImage}/>
                <div className="ml-4 flex-1 space-y-0.5">
                    <div className="flex flex-row justify-between flex-1 items-center">
                        <p className="text-md text-[#292B2F]">{data.title}</p>
                        <p className="text-xs text-[#91949E]">{data.date}</p>
                    </div>
                    <div className="flex flex-row justify-between flex-1 items-center h-auto">
                        <div className="flex-1">
                            <p className="text-[#292B2F]">{formatPrice(data.price)} тг</p>
                            { data.count && <p className="text-[#292B2F]">{data.count} {data?.unit}</p> }
                            <div className={`bg-[#5BB600] p-1 px-2.5 rounded-full w-fit ${data.count ? "mt-0" : "mt-6"}`}>
                                <p className="text-white">{data.status}</p>
                            </div>
                        </div>
                        <button onClick={() => setIsOpen(!isOpen)} className="hover:opacity-50">
                            { isOpen ? <IoIosArrowDown className="text-lg text-[#292B2F]"/> : <IoIosArrowForward className="text-lg text-[#292B2F]"/> }
                        </button>
                    </div>
                </div>
            </div>
            <div className={`transition-all duration-300 overflow-hidden ${isOpen ? "max-h-screen" : "max-h-0"}`}>
                { isOpen && (
                    <>
                        <div>
                            <p className="text-md text-[#91949E]">Ценовой диапазон</p>
                            <div className="flex flex-row items-center justify-center space-x-6">
                                <div className="border-b-[1px] border-b-[#91949E] py-2 w-full px-2">
                                    <p className="text-md text-[#5BB600]">Минимальный</p>
                                    <p className="text-lg">{formatPrice(data.minPrice)} тг</p>
                                </div>
                                <div className="border-b-[1px] border-b-[#91949E] py-2 w-full px-2">
                                    <p className="text-md text-[#5BB600]">Максимальный</p>
                                    <p className="text-lg">{formatPrice(data.maxPrice)} тг</p>
                                </div>
                            </div>
                        </div>
                        <div className="border-b-[1px] border-b-[#91949E] w-full py-2 px-2">
                            <p className="text-md text-[#91949E]">Город</p>
                            <p className="text-lg">{data.city}</p>
                        </div>
                        <div className="border-b-[1px] border-b-[#91949E] w-full py-2 px-2">
                            <p className="text-md text-[#91949E]">Дата исполнения</p>
                            <p className="text-lg">{data.executionDate}</p>
                        </div>
                        <div>
                            <p className="text-md text-[#91949E] py-2">Дополнительные характеристики</p>
                            <div className="flex flex-row items-center flex-wrap w-full">
                                { additionalInfo.map((item, index) => (
                                    <div key={index} className="m-1 bg-[#5BB600] rounded-full w-fit px-3.5 py-1">
                                        <p className="text-md text-white">{item}</p>
                                    </div>
                                )) }
                            </div>
                        </div>
                        <div className="border-b-[1px] border-b-[#91949E] w-full py-2 px-2">
                            <p className="text-md text-[#91949E]">Доп.условия</p>
                            <p className="text-lg">{data.comment}</p>
                        </div>
                        <button onClick={() => navigatate('/feedbacks', { state: data })} className="w-full mt-2">
                            <p className="text-[#5BB600] text-lg text-center hover:opacity-50">Посмотреть отклики</p>
                        </button>
                    </>
                )}
            </div>
        </div>
    )
};

export default Card;
