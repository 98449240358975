import { useEffect, useState } from 'react';
import { IoIosStar } from "react-icons/io";

function WriteReview({ onClose, providerId }) {
    const [stars, setStars] = useState(0);
    const [comment, onChangeComment] = useState();
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        fetchUserInfo();
    }, []);

    const fetchUserInfo = async () => {
        const authToken = document.cookie.replace(/(?:(?:^|.*;\s*)auth_token\s*=\s*([^;]*).*$)|^.*$/, "$1");

        try {
            const response = await fetch(`https://bigsnab.kz/api-getUserInfo`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}` // Устанавливаем токен в заголовке Authorization
                }
            });

            const data = await response.json();

            if (data.success) {
                setUserData(data.user);
            } else {
                // Обработка ошибки, если не удалось получить информацию о пользователе
                console.error("Failed to fetch user data:", data.message);
            }
        } catch (error) {
            // Обработка ошибки запроса
            console.error("Error fetching user data:", error);
        }
    };

    const sendReview = async () => {
        try {
            const response = await fetch('https://bigsnab.kz/api-sendReview', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }, 
                body: JSON.stringify({ 
                    providerId: providerId, 
                    fullname: `${userData.fullname} ${userData.surname}`, 
                    photo: userData.photo, 
                    comment: comment, 
                    stars: stars, 
                    userId: userData.id,
                    date: `${new Date().toLocaleDateString()}`
                })
            });
            
            const responseJson = await response.json();

            if (responseJson.success) {
                onClose();
                alert("Отзыв успешно отправлен");
            }
        } catch {

        }
    }

    const handleStarPress = (rating) => {
        setStars(rating);
    };

    return (
        <div className="fixed inset-0 z-20 flex justify-center items-center">
            <div className="fixed inset-0 bg-black bg-opacity-50" onClick={onClose}></div>
            <div className="mobile:w-10/12 tablet:w-4/12 desktop:w-1/4 absolute z-30 bg-white p-6 px-12 rounded-tl-[50px] rounded-br-[50px]">
                <p className='text-lg font-semibold text-center'>Отзыв</p>
                <p className='text-lg text-center'>Здесь вы можете написать отзыв на поставщика и его увидят все пользователи</p>
                <div className='mt-2'>
                    <p className='text-lg font-semibold'>Рейтинг</p>
                    <div className='flex flex-row items-center space-x-2'>
                        {[1, 2, 3, 4, 5].map((star) => (
                            <button key={star} onClick={() => handleStarPress(star)}>
                                <IoIosStar className={ star <= stars ? "text-4xl text-[#f5da42]" : "hover:opacity-50 text-4xl text-[#91949E]" }/>
                            </button>
                        ))}
                    </div>
                </div>
                <div className='mt-2'>
                    <p className='text-lg font-semibold'>Комментарий</p>
                    <div className='bg-white p-6 shadow-xl rounded-xl'>
                        <textarea value={comment} onChange={(e) => onChangeComment(e.target.value)} className='text-md w-full h-24 outline-none bg-transparent' placeholder='Введите комментарий'/>
                    </div>
                </div>
                <button onClick={() => sendReview()} disabled={!comment} className={ !comment ? 'opacity-50 bg-[#5BB600] w-full mt-6 p-3 rounded-2xl' : 'hover:opacity-50 bg-[#5BB600] w-full mt-6 p-3 rounded-2xl' }>
                    <p className='text-lg text-white text-center'>Отправить</p>
                </button>
            </div>
        </div>
    )
};

export default WriteReview;