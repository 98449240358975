import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ isLogged, children }) => {
    if (!isLogged) {
        return <Navigate to="/auth" />;
    }
    return children;
};

export default ProtectedRoute;
