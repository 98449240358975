import { useEffect, useState } from "react";

function Users() {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        fetchAllUsers();
    });

    const fetchAllUsers = async () => {
        try {
            const response = await fetch("https://bigsnab.kz/api-getAllUsers");

            const responseJson = await response.json();

            if(responseJson.success) {
                setUsers(responseJson.users)
            }
        } catch {

        } finally {

        }
    };

    const blockUser = async (id) => {
        try {
            const response = await fetch('https://bigsnab.kz/api-blockUser', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ userId: id })
            });

            const responseJson = await response.json();
            
            if (responseJson.success) {
                window.location.reload();
            }
        } catch {
            
        }
    };

    return (
        <div className="w-full h-full items-center flex flex-col min-h-96 overflow-y-auto">
            <div className="desktop:w-6/12 p-2 flex flex-row flex-wrap justify-between">
                { users.map((item, index) => (
                    <div key={index} className="m-3 border-[1px] rounded-2xl mobile:w-full laptop:w-[47%] border-[#91949E] p-4 flex flex-col h-fit">
                        <div className="flex flex-row w-full space-x-2 items-center">
                            <img src={`https://bigsnab.kz/api-userImage/${item?.photo}`} className="mobile:w-16 mobile:h-16 laptop:w-24 laptop:h-24 rounded-xl"/>
                            <div className="flex flex-col">
                                <p className="mobile:text-md laptop:text-md">{item.surname} {item.fullname}</p>
                                <p className="mobile:text-md laptop:text-md font-semibold">Email: <span className="font-normal">{item.email}</span></p>
                                <p className="mobile:text-md laptop:text-md font-semibold text-wrap">Тип аккаунта: <span className="font-normal">{item.typeOfAccount === 'Client' ? 'Заказчик' : 'Поставщик'}</span></p>
                            </div>
                        </div>
                        <button onClick={() => blockUser(item.id)} className="border-2 border-red-500 rounded-xl group hover:bg-red-500 p-2 mt-4">
                            <p className="text-lg text-red-500 group-hover:text-white">Заблокировать</p>
                        </button>
                    </div>
                )) }
            </div>
        </div>
    )
};

export default Users;