import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"

function FeedbackCard({ item, openWriteReview, selectProviderId, userId }) {
    const navigatate = useNavigate();
    const [isCommentAdded, setIsCommentAdded] = useState(null);

    useEffect(() => {
        fetchIsCommentAdded();
    }, [isCommentAdded]);

    const fetchIsCommentAdded = async () => {
        try {
            const response = await fetch(`https://bigsnab.kz/api-isCommentAdded/${userId}/${item.providerId}`);

            const responseJson = await response.json();

            if (responseJson.success) {
                setIsCommentAdded(responseJson.isCommentAdded);
            }
        } catch {
            
        }
    }

    const acceptProvider = async (providerId, orderId) => {
        try {
            const response = await fetch('https://bigsnab.kz/api-acceptOrder', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ providerId: providerId, orderId: orderId })
            });

            const responseJson = await response.json();

            if (responseJson.success) {
                navigatate('/');
            }

        } catch {

        }
    };
    
    const writeReview = (providerId) => {
        selectProviderId(providerId);
        openWriteReview();
    }

    return (
        <div className="w-96 border-b-[1px] border-b-[#91949E] py-2">
            <p className="text-lg">Дата и время отклика: <span className="font-semibold">{item.date}</span></p>
            <p className="text-lg">Компания: <span className="font-semibold">{item.company}</span></p>
            <p className="text-lg">Форма организации: <span className="font-semibold">{item.entity}</span></p>
            <p className="text-lg">{ item.entity === 'Юр.лицо' ? 'БИН' : 'ИИН' }: <span className="font-semibold">{item.bin}</span></p>
            { item.site && <p className="text-lg">Сайт: <span className="font-semibold">{item.site}</span></p> }
            { item.comment && <p className="text-lg">Условия поставщика: <span className="font-semibold">{item.comment}</span></p> }
            { !item.isAccept ? (
                <div className="w-full flex flex-row justify-between items-center">
                    <button onClick={() => acceptProvider(item.providerId, item.orderId)} className="mt-2">
                        <p className="text-lg text-[#5BB600] text-center hover:opacity-50">Принять</p>
                    </button>
                    <button onClick={() => navigatate('/providerReviews', { state: { company: item.company, providerId: item.providerId } })} className="mt-2">
                        <p className="text-lg text-[#91949E] text-center hover:opacity-50">Посмотреть отзывы</p>
                    </button>
                </div>
            ) : (
                <div className="w-full flex flex-row justify-between items-center">
                    { !isCommentAdded && (
                        <button onClick={() => writeReview(item.providerId)} className="mt-2">
                            <p className="text-lg text-[#5BB600] text-center hover:opacity-50">Оставить отзыв</p>
                        </button>
                    ) }
                    <button onClick={() => navigatate('/providerReviews', { state: { company: item.company, providerId: item.providerId } })} className="mt-2">
                        <p className="text-lg text-[#91949E] text-center hover:opacity-50">Посмотреть отзывы</p>
                    </button>
                </div>
            ) }
        </div>
    )
};

export default FeedbackCard;