import { useNavigate } from "react-router-dom";

function WarningModal({ title, description, onClose }) {
    const navigate = useNavigate();
    return (
        <div className="fixed inset-0 z-20 flex justify-center items-center border-2">
            <div className="fixed inset-0 bg-black backdrop-blur-xl bg-opacity-50"></div>
            <div className="mobile:w-10/12 tablet:w-4/12 desktop:w-1/4 absolute z-30 bg-white p-6 px-12 rounded-tl-[50px] rounded-br-[50px]">
                <p className='text-lg font-semibold text-left'>{title}</p>
                <p className='text-lg text-left mt-1'>{description}</p>
                <button onClick={() => navigate('/subscriptions')} className="hover:opacity-50 w-full p-4 mt-4 rounded-xl bg-[#5BB600]">
                    <p className="text-lg text-white">Перейти к подпискам</p>
                </button>
                <button onClick={onClose} className="hover:opacity-50 w-full p-4 mt-4 rounded-xl bg-[#c3c3c3]">
                    <p className="text-lg text-white">Неинтересно</p>
                </button>
            </div>
        </div>
    )
};

export default WarningModal;