import { useState } from 'react';
import { IoMdCheckmark } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";

function Agreement({ openWarningModal, onClose, providerInfo, userData, clientId, orderId }) {
    const [isCheckbox, setIsCheckbox] = useState(false);
    const [step, setStep] = useState(1);
    const [comment, onChangeComment] = useState();
    const navigation = useNavigate();

    const sendFeedback = async () => {
        try {
            const now = new Date();
            // Форматируем дату в "ДД.ММ.ГГГГ"
            const day = String(now.getDate()).padStart(2, '0');
            const month = String(now.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
            const year = now.getFullYear();

            // Форматируем время в "ЧЧ:ММ"
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');

            // Объединяем дату и время в нужном формате
            const formattedDate = `${day}.${month}.${year}, ${hours}:${minutes}`;

            const response = await fetch('https://bigsnab.kz/api-sendFeedback', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 
                    company: providerInfo.company, 
                    bin: providerInfo.bin, 
                    site: providerInfo.site, 
                    orderId: orderId, 
                    userId: clientId, 
                    providerId: userData?.providerId, 
                    comment: comment, 
                    date: formattedDate,
                    entity: providerInfo.entity
                })
            });

            const responseJson = await response.json();

            if (responseJson.success) {
                onClose();
                window.location.reload();
                alert("Отклик отправлен. Ожидайте обратной связи");
            } else {
                if (responseJson.message === 'Limit reached') {
                    onClose();
                    openWarningModal();
                }
            }
        } catch {
            
        }
    };

    return (
        <div className="fixed inset-0 z-20 flex justify-center items-center">
            <div className="fixed inset-0 bg-black bg-opacity-50" onClick={onClose}></div>
            <div className="mobile:w-10/12 tablet:w-4/12 desktop:w-1/4 absolute z-30 bg-white p-6 px-12 rounded-tl-[50px] rounded-br-[50px]">
                { step === 1 && (
                    <>
                        <p className='text-lg font-semibold text-center'>Отклик заказчику</p>
                        <p className='text-lg text-center'>Вы можете отправить ваши встречные условия в ответ на запрос клиента</p>
                        <div className='rounded-xl w-full p-4 shadow-lg bg-white mt-4'>
                            <textarea value={comment} onChange={(e) => onChangeComment(e.target.value)} className='outline-none bg-transparent text-md w-full' placeholder='Введите свои условия, которые увидит клиент'/>
                        </div>
                        <div className='flex flex-row space-x-2 mt-4 items-center'>
                            <button
                                    onClick={() => setIsCheckbox(!isCheckbox)}
                                    className={`${
                                        isCheckbox
                                        ? 'bg-[#5BB600] flex justify-center items-center rounded-md hover:opacity-50'
                                        : 'border-2 border-[#91949E] rounded-md hover:opacity-50'
                                    } h-6 w-6 min-w-[1.5rem] min-h-[1.5rem]`}
                                >
                                {isCheckbox && <IoMdCheckmark className="text-lg text-white" />}
                            </button>
                            <p className='text-[#91949E]'>Я прочитал <span onClick={() => setStep(2)} className='text-[#5BB600] cursor-pointer hover:opacity-50'>"Соглашение"</span> и согласен со всеми пунтктами</p>
                        </div>
                        <button onClick={() => sendFeedback()} disabled={!isCheckbox} className={ !isCheckbox ? 'w-full opacity-50 bg-[#5BB600] rounded-xl p-3 mt-4' : 'w-full hover:opacity-50 bg-[#5BB600] rounded-xl p-3 mt-4' }>
                            <p className='text-white text-center text-lg'>Отправить</p>
                        </button>
                    </>
                ) }
                { step === 2 && (
                    <>
                        <div className='flex flex-row space-x-2 items-center w-full justify-center'>
                            <button onClick={() => setStep(1)} className='hover:opacity-50'>
                                <IoIosArrowBack className='text-lg'/>
                            </button>
                            <p className='text-lg font-semibold text-center'>Соглашение</p>
                        </div>
                        <div className='overflow-y-auto overflow-x-auto h-[30vh] space-y-2'>
                            <div className='space-y-1'>
                                <p className='text-lg text-[#5BB600] font-semibold'>1. Общие условия</p>
                                <p className='text-md text-[#91949E] font-light'>1.1. Согласие с условиями предоставления услуг/товаров.</p>
                                <p className='text-md text-[#91949E] font-light'>1.2. Согласие с политикой конфиденциальности.</p>
                                <p className='text-md text-[#91949E] font-light'>1.3. Принятие всех условий данного соглашения.</p>
                            </div>
                            <div className='space-y-1'>
                                <p className='text-lg text-[#5BB600] font-semibold'>2. Обязанности поставщика</p>
                                <p className='text-md text-[#91949E] font-light'>2.1. Своевременная и качественная доставка товаров/оказание услуг.</p>
                                <p className='text-md text-[#91949E] font-light'>2.2. Обязательство соблюдать все применимые законы и нормы.</p>
                                <p className='text-md text-[#91949E] font-light'>2.3. Соответствие товаров/услуг описанию и спецификациям.</p>
                                <p className='text-md text-[#91949E] font-light'>2.4. Обеспечение необходимой документации и сертификатов на товары/услуги.</p>
                            </div>
                            <div className='space-y-1'>
                                <p className='text-lg text-[#5BB600] font-semibold'>3. Сроки выполнения</p>
                                <p className='text-md text-[#91949E] font-light'>3.1. Соблюдение оговоренных сроков доставки/оказания услуг.</p>
                                <p className='text-md text-[#91949E] font-light'>3.2. Уведомление заказчика о возможных задержках.</p>
                            </div>
                            <div className='space-y-1'>
                                <p className='text-lg text-[#5BB600] font-semibold'>4. Качество товаров/услуг</p>
                                <p className='text-md text-[#91949E] font-light'>4.1. Гарантия качества и соответствия стандартам.</p>
                                <p className='text-md text-[#91949E] font-light'>4.2. Обязательство на замену или возврат средств в случае несоответствия товара/услуги требованиям.</p>
                            </div>
                            <div className='space-y-1'>
                                <p className='text-lg text-[#5BB600] font-semibold'>5. Цена и оплата</p>
                                <p className='text-md text-[#91949E] font-light'>5.1. Принятие установленных цен и условий оплаты.</p>
                                <p className='text-md text-[#91949E] font-light'>5.2. Согласие с условиями авансовых платежей или постоплаты.</p>
                            </div>
                        </div>
                    </>
                ) }
                
            </div>
        </div>
    )
};

export default Agreement;