import productsData from '../../json/products.json'; 
import servicesData from '../../json/services.json'
import { useState } from 'react';
import { FiEye, FiEyeOff } from "react-icons/fi";
import { IoMdAdd, IoMdCheckmark, IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const getSubcategoryList = (orderType, filter) => {
    let subcategoryList = [];

    // Проверяем тип заказа и возвращаем соответствующий список подкатегорий
    if (orderType === 'Товары') {
        subcategoryList = productsData.products.find(item => item.category === filter)?.subcategory || [];
    } else if (orderType === 'Услуги') {
        subcategoryList = servicesData.services.find(item => item.category === filter)?.subcategory || [];
    }

    return subcategoryList;
};

function ListTwo({ addtionalInfo, onClose, setCategory, orderType, filter }) {
    const subcategories = getSubcategoryList(orderType, filter);

    const handleCategory = (category) => {
        setCategory('addtionalInfo', category);
    }
    return (
        <div className="fixed inset-0 z-20 flex justify-center items-center">
            <div className="fixed inset-0 bg-black bg-opacity-50" onClick={onClose}></div>
            <div className="mobile:w-10/12 tablet:w-4/12 desktop:w-1/4 absolute z-30 bg-white p-6 px-12 rounded-tl-[50px] rounded-br-[50px]">
                <p className='text-lg font-semibold text-center'>Выбор подкатегории { orderType === 'Товары' ? 'товара' : 'услуги' }</p>
                <div className='max-h-96 overflow-y-auto'>
                    { subcategories.map((item, index) => (
                        <button onClick={() => handleCategory(item)} key={index} className='border-b-[1px] border-b-black/20 w-full py-2 hover:opacity-50 flex flex-row'>
                            <p className='text-lg text-left flex-1'>{item}</p>
                            { addtionalInfo.includes(item) ? (
                                <IoMdCheckmark className="text-lg text-[#5BB600]"/>
                            ) : (
                                <IoMdAdd className="text-lg text-black/40"/>
                            ) }
                        </button>
                    )) }
                </div>
            </div>
        </div>
    )
};

export default ListTwo;