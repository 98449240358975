import { useState, useEffect } from "react";

function Applications() {
    const [application, setApplications] = useState([]);

    useEffect(() => {
        fetchAllApplications();
    });

    const fetchAllApplications = async () => {
        try {
            const response = await fetch("https://bigsnab.kz/api-getAllApplications");

            const responseJson = await response.json();

            if(responseJson.success) {
                setApplications(responseJson.applications)
            }
        } catch {

        } finally {

        }
    };

    const acceptApplication = async (id, email, subscription, validaty) => {
        console.log(id, email, subscription, validaty);
        try {
            const response = await fetch('https://bigsnab.kz/api-acceptApplication', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ applicationId: id, email: email, subscription: subscription, validaty: validaty })
            });
            console.log("test 2");
            const responseJson = await response.json();
            
            if (responseJson.success) {
                window.location.reload();
            }
        } catch {
            
        }
    };

    return (
        <div className="w-full h-full items-center flex flex-col min-h-96 overflow-y-auto">
            <div className="desktop:w-6/12 p-2 flex flex-row flex-wrap justify-between">
                { application.length > 0 ? (
                    <>
                        { application.map((item, index) => (
                            <div key={index} className="m-3 border-[1px] rounded-2xl mobile:w-full laptop:w-[47%] border-[#91949E] p-4 flex flex-col h-fit">
                                <p className="text-lg font-semibold text-black">Email: <span className="font-normal">{item.email}</span></p>
                                <p className="text-lg font-semibold text-black">Подписка: <span className="font-normal">{item.subscription}</span></p>
                                <p className="text-lg font-semibold text-black">Срок: <span className="font-normal">{item.validityRu}</span></p>
                                <p className="text-lg font-semibold text-black">Сумма: <span className="font-normal">{item.cost} тенге</span></p>
                                <button onClick={() => acceptApplication(item.id, item.email, item.subscription, item.validityDate )} className="p-4 hover:opacity-50 mt-2 rounded-xl bg-[#5BB600]">
                                    <p className="text-lg text-white">Подтвердить</p>
                                </button>
                            </div>
                        )) }
                    </>
                ) : (
                    <div className="w-full h-[50vh] flex flex-col justify-center items-center">
                        <p className="text-lg font-semibold text-center">Пока заявок на подписку нет</p>
                        <p className="text-lg text-center">В данный момент заявок нет. Ожидайте, пока кто-нибудь заинтересуется в подписке</p>
                    </div>
                ) }
            </div>
        </div>
    )
};

export default Applications;