import { useState, useEffect } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

function InviteUser({ openWarningModal, onClose, providerId, teamId }) {
    const [email, onChangeEmail] = useState();
    const [role, setRole] = useState();
    const [isOpenRole, setIsOpenRole] = useState(false);
    const [providerInfo, setProviderInfo] = useState(null);

    useEffect(() => {
        fetchProviderData();
    }, []);

    const fetchProviderData = async () => {
        try {
            const response = await fetch(`https://bigsnab.kz/api-getProviderInfo/${providerId}`);

            const responseData = await response.json();

            if (responseData.success) {
                console.log("providerInfo", providerInfo);
                setProviderInfo(responseData.providerInfo[0]);
            }
        } catch {

        }
    }

    const sendInvite = async () => {
        try {
            const response = await fetch('https://bigsnab.kz/api-sendInvite', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ teamName: providerInfo?.company, teamId: teamId, role: role, email: email, providerId: providerId })
            });

            const responseJson = await response.json();

            if(responseJson.success) {
                onClose();
                alert("Запрос успешно отправлен");
            } else {
                if (responseJson.message === 'Limit reached') {
                    onClose();
                    openWarningModal();
                }
            }
        } catch {

        }
    }
    
    const selectRole = (role) => {
        setRole(role);
        setIsOpenRole(false);
    }

    return (
        <div className="fixed inset-0 z-20 flex justify-center items-center">
            <div className="fixed inset-0 bg-black bg-opacity-50" onClick={onClose}></div>
            <div className="mobile:w-10/12 tablet:w-4/12 desktop:w-1/4 absolute z-30 bg-white p-6 px-12 rounded-tl-[50px] rounded-br-[50px]">
                <p className='text-lg font-semibold text-center'>Добавление пользователя</p>
                <p className='text-lg text-center'>Отправьте запрос пользователю на его Email, дабы он смог присоединиться к команде</p>
                <div className='space-y-2 mt-4'>
                    <div className="w-full bg-white p-4 rounded-xl shadow-lg">
                        <input value={email} onChange={(e) => onChangeEmail(e.target.value)} className="bg-transparent outline-none w-full text-lg" placeholder="Email пользователя"/>
                    </div>
                    <div className='relative'>
                        <div className={`w-full bg-white p-4 ${ isOpenRole ? 'rounded-t-xl' : 'rounded-xl' } shadow-lg flex flex-row justify-between items-center`}>
                            <p className='text-lg opacity-40'>{ role ? role : 'Выберите роль' }</p>
                            <button onClick={() => setIsOpenRole(!isOpenRole)} className='hover:opacity-50'>
                                <IoIosArrowDown className='text-lg opacity-40'/>
                            </button>
                        </div>
                        { isOpenRole && (
                            <div className='w-full bg-white p-4 rounded-b-xl shadow-lg flex flex-col items-start'>
                                <button onClick={() => selectRole("Поставщик")} className='hover:opacity-50 py-1'>
                                    <p className='text-lg opacity-40'>Поставщик</p>
                                </button>
                                <button onClick={() => selectRole("Модератор")} className='hover:opacity-50 py-1'>
                                    <p className='text-lg opacity-40'>Модератор</p>
                                </button>
                            </div>
                        ) }
                    </div>
                </div>
                <button onClick={sendInvite} disabled={!role || !email} className={ !role || !email ? 'w-full opacity-50 bg-[#5BB600] rounded-xl p-4 mt-4' : 'w-full hover:opacity-50 bg-[#5BB600] rounded-xl p-4 mt-4' }>
                    <p className='text-white text-center text-lg'>Отправить</p>
                </button>
            </div>
        </div>
    )
};

export default InviteUser;