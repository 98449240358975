import { useState } from "react";
import { LuMenu } from "react-icons/lu";
import LeftMenu from "./LeftMenu";

function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className="w-full absolute top-0 z-10 flex flex-row items-center justify-between p-6">
            <button onClick={() => setIsOpen(true)} className="hover:opacity-50">
                <LuMenu className="text-4xl"/>
            </button>
            <p className="absolute left-1/2 transform -translate-x-1/2 text-lg font-semibold text-[#5BB600]">BigSnab</p>
            { isOpen && <LeftMenu onClose={() => setIsOpen(false)}/> }
        </div>
    )
};

export default Navbar;