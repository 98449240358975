import { FaUser, FaUserTie, FaTelegram, FaCartFlatbed, FaCircleInfo } from "react-icons/fa6";
import { LuShoppingCart, LuDownload } from "react-icons/lu";
import { FaClipboardList } from "react-icons/fa";
import { BiSolidLike } from "react-icons/bi";
import { IoExitOutline } from "react-icons/io5";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { GoGraph, GoLaw } from "react-icons/go";
import { HiUsers } from "react-icons/hi2";
import { MdLocalOffer, MdAttachMoney } from "react-icons/md";

function LeftMenu({ onClose }) {
    const navigation = useNavigate();
    const [userData, setUserData] = useState({});
    const location = useLocation();

    useEffect(() => {
        fetchUserInfo();
        console.log("location", location.pathname);
    }, []);

    const fetchUserInfo = async () => {
        const authToken = document.cookie.replace(/(?:(?:^|.*;\s*)auth_token\s*=\s*([^;]*).*$)|^.*$/, "$1");

        try {
            const response = await fetch(`https://bigsnab.kz/api-getUserInfo`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}` // Устанавливаем токен в заголовке Authorization
                }
            });

            const data = await response.json();

            if (data.success) {
                setUserData(data.user);
                console.log(data.user);
            } else {
                // Обработка ошибки, если не удалось получить информацию о пользователе
                console.error("Failed to fetch user data:", data.message);
            }
        } catch (error) {
            // Обработка ошибки запроса
            console.error("Error fetching user data:", error);
        }
    };

    const logout = async () => {
        document.cookie = "auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        window.location.reload();
        navigation('/auth');
    }

    return (
        <div className="fixed inset-0 z-50 flex">
            <div className="fixed inset-0 bg-black bg-opacity-50" onClick={onClose}></div>
                <div className="mobile:w-10/12 tablet:w-4/12 desktop:w-1/4 h-full absolute z-60 bg-white">
                <div className="border-b-[1px] border-b-[#91949E] mt-10">
                    <Link to='/profile' onClick={onClose} className={ location.pathname === '/profile' ? "flex flex-row items-center space-x-2 py-4 bg-[#5BB600] mobile:p-2 tablet:p-4 laptop:p-3" : "flex flex-row items-center space-x-2 py-4 hover:opacity-50 mobile:p-2 tablet:p-4 laptop:p-3" }>
                        <FaUser className={ location.pathname === '/profile' ? "desktop:text-md laptop:text-md mobile:text-md text-[#FFF]" : "desktop:text-md laptop:text-md mobile:text-md text-[#292B2F]" }/>
                        <p className={ location.pathname === '/profile' ? "desktop:text-md laptop:text-md mobile:text-md text-[#FFF]" : "desktop:text-md laptop:text-md mobile:text-md text-[#292B2F]" }>Профиль</p>
                    </Link>
                    { userData?.typeOfAccount === 'Client' ? (
                        <>
                            <Link to='/createOrder' onClick={onClose} className={ location.pathname === '/createOrder' ? "flex flex-row items-center space-x-2 py-4 bg-[#5BB600] mobile:p-2 tablet:p-4 laptop:p-3" : "flex flex-row items-center space-x-2 py-4 hover:opacity-50 mobile:p-2 tablet:p-4 laptop:p-3" }>
                                <LuShoppingCart className={ location.pathname === '/createOrder' ? "desktop:text-md laptop:text-md mobile:text-md text-[#FFF]" : "desktop:text-md laptop:text-md mobile:text-md text-[#292B2F]" }/>
                                <p className={ location.pathname === '/createOrder' ? "desktop:text-md laptop:text-md mobile:text-md text-[#FFF]" : "desktop:text-md laptop:text-md mobile:text-md text-[#292B2F]" }>Создание заказа</p>
                            </Link>
                            <Link to='/' onClick={onClose} className={ location.pathname === '/' ? "flex flex-row items-center space-x-2 py-4 bg-[#5BB600] mobile:p-2 tablet:p-4 laptop:p-3" : "flex flex-row items-center space-x-2 py-4 hover:opacity-50 mobile:p-2 tablet:p-4 laptop:p-3" }>
                                <FaClipboardList className={ location.pathname === '/' ? "desktop:text-md laptop:text-md mobile:text-md text-[#FFF]" : "desktop:text-md laptop:text-md mobile:text-md text-[#292B2F]" }/>
                                <p className={ location.pathname === '/' ? "desktop:text-md laptop:text-md mobile:text-md text-[#FFF]" : "desktop:text-md laptop:text-md mobile:text-md text-[#292B2F]" }>История заказов</p>
                            </Link>
                            <Link to='/become-provider' onClick={onClose} className={ location.pathname === '/become-provider' ? "flex flex-row items-center space-x-2 py-4 bg-[#5BB600] mobile:p-2 tablet:p-4 laptop:p-3" : "flex flex-row items-center space-x-2 py-4 hover:opacity-50 mobile:p-2 tablet:p-4 laptop:p-3" }>
                                <FaUserTie className={ location.pathname === '/become-provider' ? "desktop:text-md laptop:text-md mobile:text-md text-[#FFF]" : "desktop:text-md laptop:text-md mobile:text-md text-[#292B2F]" }/>
                                <p className={ location.pathname === '/become-provider' ? "desktop:text-md laptop:text-md mobile:text-md text-[#FFF]" : "desktop:text-md laptop:text-md mobile:text-md text-[#292B2F]" }>Стать поставщиком</p>
                            </Link>
                        </>
                    ) : (
                        <>
                            <Link to='/range' onClick={onClose} className={ location.pathname === '/range' || location.pathname === '/addProduct' ? "flex flex-row items-center space-x-2 py-4 bg-[#5BB600] mobile:p-2 tablet:p-4 laptop:p-3" : "flex flex-row items-center space-x-2 py-4 hover:opacity-50 mobile:p-2 tablet:p-4 laptop:p-3" }>
                                <FaCartFlatbed className={ location.pathname === '/range' || location.pathname === '/addProduct' ? "desktop:text-md laptop:text-md mobile:text-md text-[#FFF]" : "desktop:text-md laptop:text-md mobile:text-md text-[#292B2F]" }/>
                                <p className={ location.pathname === '/range' || location.pathname === '/addProduct' ? "desktop:text-md laptop:text-md mobile:text-md text-[#FFF]" : "desktop:text-md laptop:text-md mobile:text-md text-[#292B2F]" }>Управление заказами</p>
                            </Link>
                            <Link to='/?tab=admin' onClick={onClose} className={ location.pathname === '/' ? "flex flex-row items-center space-x-2 py-4 bg-[#5BB600] mobile:p-2 tablet:p-4 laptop:p-3" : "flex flex-row items-center space-x-2 py-4 hover:opacity-50 mobile:p-2 tablet:p-4 laptop:p-3" }>
                                <GoGraph className={ location.pathname === '/' ? "desktop:text-md laptop:text-md mobile:text-md text-[#FFF]" : "desktop:text-md laptop:text-md mobile:text-md text-[#292B2F]" }/>
                                <p className={ location.pathname === '/' ? "desktop:text-md laptop:text-md mobile:text-md text-[#FFF]" : "desktop:text-md laptop:text-md mobile:text-md text-[#292B2F]" }>Бизнес</p>
                            </Link>
                            <Link to='/users' onClick={onClose} className={ location.pathname === '/users' ? "flex flex-row items-center space-x-2 py-4 bg-[#5BB600] mobile:p-2 tablet:p-4 laptop:p-3" : "flex flex-row items-center space-x-2 py-4 hover:opacity-50 mobile:p-2 tablet:p-4 laptop:p-3" }>
                                <HiUsers className={ location.pathname === '/users' ? "desktop:text-md laptop:text-md mobile:text-md text-[#FFF]" : "desktop:text-md laptop:text-md mobile:text-md text-[#292B2F]" }/>
                                <p className={ location.pathname === '/users' ? "desktop:text-md laptop:text-md mobile:text-md text-[#FFF]" : "desktop:text-md laptop:text-md mobile:text-md text-[#292B2F]" }>Пользователи</p>
                            </Link>
                        </>
                    ) }
                </div>
                <div className="border-b-[1px] border-b-[#91949E] py-4">
                    <Link to='/review' className={ location.pathname === '/review' ? "flex flex-row items-center space-x-2 py-4 bg-[#5BB600] mobile:p-2 tablet:p-4 laptop:p-3" : "flex flex-row items-center space-x-2 py-4 hover:opacity-50 mobile:p-2 tablet:p-4 laptop:p-3" }>
                        <BiSolidLike className={ location.pathname === '/review' ? "desktop:text-md laptop:text-md mobile:text-md text-[#FFF]" : "desktop:text-md laptop:text-md mobile:text-md text-[#292B2F]" }/>
                        <p className={ location.pathname === '/review' ? "desktop:text-md laptop:text-md mobile:text-md text-[#FFF]" : "desktop:text-md laptop:text-md mobile:text-md text-[#292B2F]" }>Добавить отзыв</p>
                    </Link>
                    <Link className="hover:opacity-50 flex flex-row items-center space-x-2 mobile:p-2 tablet:p-4 laptop:p-3 py-3">
                        <FaTelegram className="desktop:text-md laptop:text-md mobile:text-md text-[#292B2F]"/>
                        <p className="desktop:text-md laptop:text-md mobile:text-md text-[#292B2F]">Телеграм</p>
                    </Link>
                </div>
                <div className="border-b-[1px] border-b-[#91949E] py-4">
                    <Link to='/policy' className={ location.pathname === '/policy' ? "flex flex-row items-center space-x-2 py-4 bg-[#5BB600] mobile:p-2 tablet:p-4 laptop:p-3" : "flex flex-row items-center space-x-2 py-4 hover:opacity-50 mobile:p-2 tablet:p-4 laptop:p-3" }>
                        <GoLaw className={ location.pathname === '/policy' ? "desktop:text-md laptop:text-md mobile:text-md text-[#FFF]" : "desktop:text-md laptop:text-md mobile:text-md text-[#292B2F]" }/>
                        <p className={ location.pathname === '/policy' ? "desktop:text-md laptop:text-md mobile:text-md text-[#FFF]" : "desktop:text-md laptop:text-md mobile:text-md text-[#292B2F]" }>Политика конфиденциальности</p>
                    </Link>
                    <Link to='/public-offer' className={ location.pathname === '/public-offer' ? "flex flex-row items-center space-x-2 py-4 bg-[#5BB600] mobile:p-2 tablet:p-4 laptop:p-3" : "flex flex-row items-center space-x-2 py-4 hover:opacity-50 mobile:p-2 tablet:p-4 laptop:p-3" }>
                        <MdLocalOffer className={ location.pathname === '/public-offer' ? "desktop:text-md laptop:text-md mobile:text-md text-[#FFF]" : "desktop:text-md laptop:text-md mobile:text-md text-[#292B2F]" }/>
                        <p className={ location.pathname === '/public-offer' ? "desktop:text-md laptop:text-md mobile:text-md text-[#FFF]" : "desktop:text-md laptop:text-md mobile:text-md text-[#292B2F]" }>Публичная оферта</p>
                    </Link>
                    <Link to='/about-us' className={ location.pathname === '/about-us' ? "flex flex-row items-center space-x-2 py-4 bg-[#5BB600] mobile:p-2 tablet:p-4 laptop:p-3" : "flex flex-row items-center space-x-2 py-4 hover:opacity-50 mobile:p-2 tablet:p-4 laptop:p-3" }>
                        <FaCircleInfo className={ location.pathname === '/about-us' ? "desktop:text-md laptop:text-md mobile:text-md text-[#FFF]" : "desktop:text-md laptop:text-md mobile:text-md text-[#292B2F]" }/>
                        <p className={ location.pathname === '/about-us' ? "desktop:text-md laptop:text-md mobile:text-md text-[#FFF]" : "desktop:text-md laptop:text-md mobile:text-md text-[#292B2F]" }>О нас</p>
                    </Link>
                    { userData?.typeOfAccount === 'Provider' && (
                        <Link to='/subscriptions' className={ location.pathname === '/subscriptions' ? "flex flex-row items-center space-x-2 py-4 bg-[#5BB600] mobile:p-2 tablet:p-4 laptop:p-3" : "flex flex-row items-center space-x-2 py-4 hover:opacity-50 mobile:p-2 tablet:p-4 laptop:p-3" }>
                            <MdAttachMoney className={ location.pathname === '/subscriptions' ? "desktop:text-md laptop:text-md mobile:text-md text-[#FFF]" : "desktop:text-md laptop:text-md mobile:text-md text-[#292B2F]" }/>
                            <p className={ location.pathname === '/subscriptions' ? "desktop:text-md laptop:text-md mobile:text-md text-[#FFF]" : "desktop:text-md laptop:text-md mobile:text-md text-[#292B2F]" }>Подписки</p>
                        </Link>
                    ) }
                </div>
                <div className="border-b-[1px] border-b-[#91949E] py-4">
                    <Link to='/download' className={ location.pathname === '/download' ? "flex flex-row items-center space-x-2 py-4 bg-[#5BB600] mobile:p-2 tablet:p-4 laptop:p-3" : "flex flex-row items-center space-x-2 py-4 hover:opacity-50 mobile:p-2 tablet:p-4 laptop:p-3" }>
                        <LuDownload className={ location.pathname === '/download' ? "desktop:text-md laptop:text-md mobile:text-md text-[#FFF]" : "desktop:text-md laptop:text-md mobile:text-md text-[#292B2F]" }/>
                        <p className={ location.pathname === '/download' ? "desktop:text-md laptop:text-md mobile:text-md text-[#FFF]" : "desktop:text-md laptop:text-md mobile:text-md text-[#292B2F]" }>Скачать приложение</p>
                    </Link>
                </div>
                <button onClick={logout} className="hover:opacity-50 flex flex-row items-center space-x-2 mobile:p-2 tablet:p-4 laptop:p-3 py-3">
                    <IoExitOutline className="desktop:text-md laptop:text-md mobile:text-md text-[#F37A7A]"/>
                    <p className="desktop:text-md laptop:text-md mobile:text-md text-[#F37A7A]">Выйти</p>
                </button>
            </div>
        </div>
    )
};

export default LeftMenu;