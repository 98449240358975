import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import ProtectedRoute from './utils/ProtectedRoute';
import AuthPage from './components/pages/AuthPage';
import RegPage from './components/pages/RegPage';
import RegClientPage from './components/pages/RegClientPage';
import RegProviderPage from './components/pages/RegProviderPage';
import OrderHistoryPage from './components/pages/OrderHistoryPage';
import Profile from './components/pages/ProfilePage';
import CreateOrder from './components/pages/CreateOrderPage';
import ReviewPage from './components/pages/ReviewPage';
import BussinesPage from './components/pages/BussinesPage';
import RangePage from './components/pages/RangePage';
import AddProduct from './components/pages/AddProduct';
import PolicyPage from './components/pages/PolicyPage';
import PublicPage from './components/pages/PublicPage';
import UserPage from './components/pages/UsersPage';
import DownloadPage from './components/pages/DownloadPage';
import RecoveryPassword from './components/pages/RecoveryPass';
import Invite from './components/ux/Invite';
import queryString from 'query-string';
import FeedbacksPage from './components/pages/FeedbacksPage';
import ReviewsPage from './components/pages/ReviewsPage';
import AboutUsPage from './components/pages/AboutUsPage';
import BecomeProvider from './components/pages/BecomeProvider';
import SubscriptionPage from './components/pages/SubscriptionsPage';
import AdminAuth from './components/pages/AdminAuthPage';
import AdminPage from './components/pages/AdminPage';
import Subscription from './components/pages/Subscriptions';

const MainApp = ({ isLogged, userData }) => {
    const [isInviteModal, setIsInviteModal] = useState(false);
    const [inviteData, setInviteData] = useState({ teamId: null, role: null });
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const navigation = useNavigate();

    useEffect(() => {
        const teamId = queryParams.team_id;
        console.log(teamId);
        const role = queryParams.role;
        console.log(role);
        console.log("role", role);
        console.log("teamId: ", teamId);

        // Проверка, что пользователь находится на /invite
        if (teamId && role) {
            setInviteData({ teamId, role });
            setIsInviteModal(true);
        }
        console.log("userData.typeOfAccount", userData.typeOfAccount);
    }, [location]);

return (
    <>
        <Routes>
            <Route path='/auth' element={isLogged ? <Navigate to="/" /> : <AuthPage/>} />
            <Route path='/reg' element={isLogged ? <Navigate to="/" /> : <RegPage />} />
            <Route path='/regClient' element={isLogged ? <Navigate to="/" /> : <RegClientPage />} />
            <Route path='/regProvider' element={isLogged ? <Navigate to="/" /> : <RegProviderPage />} />
            <Route path='/recoveryPassword' element={isLogged ? <Navigate to="/" /> : <RecoveryPassword />} />
            <Route path='/admin/auth' element={<AdminAuth/>}/>
            <Route path='/admin/users' element={<AdminPage/>}/>
            <Route path='/selectSubscription' element={<Subscription/>}/>
            <Route path='/' element={
                <ProtectedRoute isLogged={isLogged}>
                    {userData.typeOfAccount === 'Client' ? <OrderHistoryPage /> : <BussinesPage />}
                </ProtectedRoute>
                } />
                <Route path='/profile' element={
                    <ProtectedRoute isLogged={isLogged}>
                        <Profile />
                    </ProtectedRoute>
                } />
                <Route path='/feedbacks' element={
                    <ProtectedRoute isLogged={isLogged}>
                        <FeedbacksPage />
                    </ProtectedRoute>
                } />
                <Route path='/createOrder' element={
                <ProtectedRoute isLogged={isLogged}>
                    <CreateOrder />
                </ProtectedRoute>
                } />
                <Route path='/review' element={
                <ProtectedRoute isLogged={isLogged}>
                    <ReviewPage />
                </ProtectedRoute>
                } />
                <Route path='/range' element={
                <ProtectedRoute isLogged={isLogged}>
                    <RangePage />
                </ProtectedRoute>
                } />
                <Route path='/addProduct' element={
                <ProtectedRoute isLogged={isLogged}>
                    <AddProduct />
                </ProtectedRoute>
                } />
                <Route path='/policy' element={
                <ProtectedRoute isLogged={isLogged}>
                    <PolicyPage />
                </ProtectedRoute>
                } />
                <Route path='/public-offer' element={
                <ProtectedRoute isLogged={isLogged}>
                    <PublicPage />
                </ProtectedRoute>
                } />
                <Route path='/users' element={
                <ProtectedRoute isLogged={isLogged}>
                    <UserPage />
                </ProtectedRoute>
                } />
                <Route path='/download' element={
                <ProtectedRoute isLogged={isLogged}>
                    <DownloadPage />
                </ProtectedRoute>
                } />
                <Route path='/providerReviews' element={
                    <ProtectedRoute isLogged={isLogged}>
                        <ReviewsPage />
                    </ProtectedRoute>
                } />
                <Route path='/about-us' element={
                    <ProtectedRoute isLogged={isLogged}>
                        <AboutUsPage />
                    </ProtectedRoute>
                } />
                <Route path='/become-provider' element={
                    <ProtectedRoute isLogged={isLogged}>
                        <BecomeProvider />
                    </ProtectedRoute>
                } />
                <Route path='/subscriptions' element={
                    <ProtectedRoute isLogged={isLogged}>
                        <SubscriptionPage />
                    </ProtectedRoute>
                } />
            </Routes>
            {isInviteModal && <Invite isLogged={isLogged} userId={userData.id} inviteData={inviteData} onClose={() => setIsInviteModal(false)} />}
        </>
    );
};

export default MainApp;
