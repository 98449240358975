import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoMdCheckmark } from "react-icons/io";

function EndTrialModal({ userId, onClose }) {
    const navigate = useNavigate();
    return (
        <div className="fixed inset-0 z-20 flex justify-center items-center border-2">
            <div className="fixed inset-0 bg-black backdrop-blur-xl bg-opacity-50"></div>
            <div className="mobile:w-10/12 tablet:w-4/12 desktop:w-1/4 absolute z-30 bg-white p-6 px-12 rounded-tl-[50px] rounded-br-[50px]">
                <p className='text-lg font-semibold text-left'>У вас закончилась пробная подписка</p>
                <p className='text-lg text-left mt-1'>С моментра регистрации на сервисе <span className="text-[#5BB600]">BigSnab</span> прошло 7 дней.</p>
                <p className='text-lg text-left mt-1'>Ваша пробная подписка истекла, дабы продолжить пользоваться функционалом сервиса вам нужно купить подписку</p>
                <button onClick={() => (navigate('/selectSubscription', onClose()))} className="hover:opacity-50 w-full p-4 mt-4 rounded-xl bg-[#5BB600]">
                    <p className="text-lg text-white">Перейти к подписке</p>
                </button>
            </div>
        </div>
    )
};

export default EndTrialModal;