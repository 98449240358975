import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoMdCheckmark } from "react-icons/io";

function CongratulationsModal({ userId, onClose }) {
    const navigate = useNavigate();
    const [isCheckbox, setIsCheckbox] = useState();

    const goToSubscription = async () => {
        if (!isCheckbox) {
            onClose();
            navigate("/subscriptions");
        } else {
            const disabledNotifications = await offNotification();

            if (disabledNotifications) {
                onClose();
                navigate("/subscriptions");
            }
        }
    };

    const notIntrestings = async () => {
        if (!isCheckbox) {
            onClose();
        } else {
            const disabledNotifications = await offNotification();

            if (disabledNotifications) {
                onClose();
            }
        }
    }

    const offNotification = async () => {
        try {
            const response = await fetch(`https://bigsnab.kz/api-offNotifications/${userId}`);

            const responseJson = await response.json();
            
            return responseJson.success
        } catch (error) {
            console.log(error);
            return false
        }
    };


    return (
        <div className="fixed inset-0 z-20 flex justify-center items-center border-2">
            <div className="fixed inset-0 bg-black backdrop-blur-xl bg-opacity-50" onClick={onClose}></div>
            <div className="mobile:w-10/12 tablet:w-4/12 desktop:w-1/4 absolute z-30 bg-white p-6 px-12 rounded-tl-[50px] rounded-br-[50px]">
                <p className='text-lg font-semibold text-left'>У вас не активирована подписка</p>
                <p className='text-lg text-left mt-1'>На сервисе <span className="text-[#5BB600]">BigSnab</span> действует система подписок и тарифов</p>
                <p className='text-lg text-left mt-1'>Всем новым пользователям мы дарим подписку на<span className="text-[#5BB600]">7 дней</span> в качестве пробного периода</p>
                <div className="flex flex-row py-4 items-center space-x-2">
                    <button onClick={() => setIsCheckbox(!isCheckbox)} className={ isCheckbox ? "w-6 h-6 bg-[#5BB600] rounded-md justify-center items-center flex hover:opacity-50" : " hover:opacity-50 w-6 h-6 border-2 border-[#5BB600] rounded-md" }>
                        <IoMdCheckmark className="text-lg text-white"/>
                    </button>
                    <p className="text-md">Больше не показывать это уведомление</p>
                </div>
                <button onClick={() => goToSubscription()} className="hover:opacity-50 w-full p-4 rounded-xl bg-[#5BB600]">
                    <p className="text-lg text-white">Ознакомиться</p>
                </button>
                <button onClick={() => notIntrestings()} className="hover:opacity-50 mt-2 w-full p-4 rounded-xl bg-[#acacac]">
                    <p className="text-lg text-white">Неинтересно</p>
                </button>
            </div>
        </div>
    )
};

export default CongratulationsModal;