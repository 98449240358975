import { useNavigate } from "react-router-dom";

function RegPage() {
    const navigate = useNavigate();

    
    const changeTypeOfAccount = (type) => {

        // Навигация в зависимости от типа аккаунта
        if (type === "Заказчик") {
            navigate("/regClient");
        } else if (type === "Поставщик") {
            navigate("/regProvider");
        }
    }
    return (
        <div className="bg-white">
            <div className="h-screen w-screen justify-center items-center flex flex-col">
                <p className="text-2xl font-semibold text-[#5BB600] mobile:mb-6 desktop:mb-6">BigSnab</p>
                <div className="bg-white mobile:w-11/12 tablet:w-8/12 laptop:w-5/12 desktop:w-4/12 desktop2:w-96 shadow-xl rounded-tl-[50px] rounded-br-[50px]">
                    <div className="bg-[#438501] rounded-tl-[50px] rounded-br-[50px] mobile:px-6 talbet:px-8 py-6">
                        <p className="text-lg text-white text-center font-semibold">Добро пожаловать!</p>
                        <p className="text-md mt-1 text-white text-center">Создайте свой аккаунт</p>
                        <div className="mt-6 space-y-4">
                            <button onClick={() => changeTypeOfAccount("Заказчик")} className="hover:opacity-50 w-full bg-white p-3 rounded-xl">
                                <p className="text-md">Создать аккаунт заказчика</p>
                            </button>
                            <button onClick={() => changeTypeOfAccount("Поставщик")} className="hover:opacity-50 w-full bg-white p-3 rounded-xl">
                                <p className="text-md">Создать аккаунт поставщика</p>
                            </button>
                        </div>
                    </div>
                    <div className="px-12 py-6">
                        <p className="text-md text-center py-2 text-[#91949E]">Есть аккаунт? <button onClick={() => navigate('/auth')} className="hover:opacity-50"><p className="text-[#5BB600]">Войдите</p></button></p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default RegPage;