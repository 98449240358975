import { useState } from "react";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";

function RangeCard({ data }) {
    const [isOpen, setIsOpen] = useState(false);

    const additionalInfo = [
        data.additionalInfo1,
        data.additionalInfo2,
        data.additionalInfo3,
        data.additionalInfo4,
        data.additionalInfo5,
    ].filter(info => info !== null);

    return (
        <div className="m-3 border-[1px] rounded-2xl mobile:w-full laptop:w-[47%] border-[#91949E] p-4 flex flex-col h-fit">
            <div className="flex flex-row w-full">
                <img src={`https://bigsnab.kz/api-previewImage/${data.previewImage}`} className="laptop:h-28 mobile:w-16 laptop:w-28 mobile:h-16 object-cover rounded-2xl bg-[#BDBDBD]"/>
                <div className="ml-4 flex-1 space-y-0.5">
                    <div className="flex flex-row justify-between flex-1 items-center">
                        <p className="mobile:text-base tablet:text-md text-[#292B2F]">{data.title}</p>
                        <p className="text-xs text-[#91949E]">{data.date}</p>
                    </div>
                    <div className="flex flex-row justify-between flex-1 items-center h-auto">
                        <div className="flex flex-row items-center flex-wrap w-full">
                            { additionalInfo.map((item, index) => (
                                <div key={index} className="m-1 bg-[#5BB600] rounded-full w-fit mobile:px-2.5 laptop:px-3.5 py-1">
                                    <p className="mobile:text-xs laptop:text-md text-white">{item}</p>
                                </div>
                            )) }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default RangeCard;
