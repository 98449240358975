import productsData from '../../json/products.json'; 
import servicesData from '../../json/services.json'
import { useState } from 'react';

const getCategoryList = (orderType) => {
    // Проверяем тип заказа и возвращаем соответствующий список категорий
    if (orderType === 'Товары') {
        return productsData.products.map(item => item.category);
    } else if (orderType === 'Услуги') {
        return servicesData.services.map(item => item.category);
    } else {
        return []; // Возвращаем пустой массив в случае некорректного значения orderType
    }
};

function ListOne({ onClose, setCategory, orderType }) {
    const categories = getCategoryList(orderType);
    
    const handleCategory = (category) => {
        let data;
        if (orderType === 'Товары') {
            data = productsData.products;
        } else if (orderType === 'Услуги') {
            data = servicesData.services;
        }
        const item = data.find(item => item.category === category);
        console.log("item.imagePreview", item.imagePreview);
        if (item) {
            setCategory('title', category);
            setCategory('previewImage', item.imagePreview);
        } else {
            setCategory('title', category);
            setCategory('previewImage', ''); // Если изображение не найдено, можно передать пустую строку
        }
        onClose();
    }
    
    return (
        <div className="fixed inset-0 z-20 flex justify-center items-center">
            <div className="fixed inset-0 bg-black bg-opacity-50" onClick={onClose}></div>
            <div className="mobile:w-10/12 tablet:w-4/12 desktop:w-1/4 absolute z-30 bg-white p-6 px-12 rounded-tl-[50px] rounded-br-[50px]">
                <p className='text-lg font-semibold text-center'>Выбор категории { orderType === 'Товары' ? 'товара' : 'услуги' }</p>
                <div className='overflow-y-auto max-h-96'>
                    { categories.map((item, index) => (
                        <button onClick={() => handleCategory(item)} key={index} className='border-b-[1px] border-b-black/20 w-full py-2 hover:opacity-50'>
                            <p className='text-lg'>{item}</p>
                        </button>
                    )) }
                </div>
            </div>
        </div>
    )
};

export default ListOne;