import { useEffect, useState } from "react";
import Navbar from "../ux/Navbar";
import { IoIosArrowForward } from "react-icons/io";
import Card from "../ux/Card";

function OrderHistoryPage() {
    const [userData, setUserData] = useState({});
    const [orders, setOrders] = useState([]);
    const [message, setMessage] = useState();

    useEffect(() => {
        fetchUserInfo();
    }, []);

    const fetchUserInfo = async () => {
        const authToken = document.cookie.replace(/(?:(?:^|.*;\s*)auth_token\s*=\s*([^;]*).*$)|^.*$/, "$1");

        try {
            const response = await fetch(`https://bigsnab.kz/api-getUserInfo`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}` // Устанавливаем токен в заголовке Authorization
                }
            });

            const data = await response.json();

            if (data.success) {
                setUserData(data.user);
                fetchOrder(data.user.id);
            } else {
                // Обработка ошибки, если не удалось получить информацию о пользователе
                console.error("Failed to fetch user data:", data.message);
            }
        } catch (error) {
            // Обработка ошибки запроса
            console.error("Error fetching user data:", error);
        }
    };

    const fetchOrder = async (id) => {
        try {
            const response = await fetch(`https://bigsnab.kz/api-getOrders/${id}`);

            const responseData = await response.json();

            if (responseData.success) {
                setOrders(responseData.orders);
                setMessage();
            } else {
                setMessage(responseData.message);
            }
        } catch {

        }
    }

    return (
        <div>
            <Navbar/>
            <div className="w-full h-full justify-center items-center flex flex-col">
                <p className="mt-20 text-lg font-semibold text-[#292B2F]">История заказов</p>
                <div className="desktop:w-6/12 p-2 flex flex-row flex-wrap justify-between">
                    { orders.length > 0 ? (
                        <>  
                            { orders.map((item, index) => (
                                <Card key={index} data={item}/>
                            )) }
                        </>
                    ) : (
                        <div className="w-full h-[80vh] flex flex-col justify-center items-center space-y-2">
                            <p className="text-lg text-black font-semibold text-center">У вас пока нет добавленных заказов</p>
                            <p className="text-lg font-light text-center">Здесь будут отображться вся история Ваших заказов, а так же подробная информация о них</p>
                        </div>
                    ) }
                </div>
            </div>
        </div>
    )
};

export default OrderHistoryPage;