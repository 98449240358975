import { useState } from "react";

function AdminAuth() {
    const [data, setData] = useState({
        login: "",
        password: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
    };  

    return (
        <div className="bg-white">
            <div className="h-screen w-screen justify-center items-center flex flex-col">
                <p className="text-2xl font-semibold text-[#5BB600] mobile:mb-6 desktop:mb-12">BigSnab</p>
                <div className="bg-white mobile:w-10/12 tablet:w-8/12 laptop:w-5/12 desktop:w-4/12 desktop2:w-3/12 shadow-xl rounded-tl-[50px] rounded-br-[50px]">
                    <div className="bg-[#438501] rounded-tl-[50px] rounded-br-[50px] py-6 px-12">
                        <p className="text-lg text-white text-center font-semibold">Добро пожаловать!</p>
                        <p className="text-md mt-1 text-white text-center">Войдите в админ.панель</p>
                        <div className="mt-6">
                            <div className="w-full bg-white p-4 rounded-xl mt-4">
                                <input name="login" value={data.login} onChange={handleChange} className="bg-transparent outline-none text-lg w-full" placeholder="Логин"/>
                            </div>
                            <div className="w-full bg-white p-4 rounded-xl mt-4">
                                <input name="password" value={data.password} onChange={handleChange} type="password" className="bg-transparent outline-none text-lg w-full" placeholder="Пароль"/>
                            </div>
                            <button disabled={!data.password || !data.login} className={ !data.password || !data.login ? "bg-white w-full p-4 rounded-xl mt-4 opacity-50" : "bg-white w-full p-4 rounded-xl mt-4 hover:opacity-50" }>
                                <p className="text-lg text-[#5BB600]">Войти</p>
                            </button>
                        </div>
                    </div>
                </div>      
            </div>
        </div>
    )
};

export default AdminAuth;