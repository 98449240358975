import { Link, useLocation } from "react-router-dom";
import Navbar from "../ux/Navbar";
import { IoIosArrowBack } from "react-icons/io";
import { useState, useEffect } from "react";
import ReactLoading from 'react-loading';
import { IoIosStar } from "react-icons/io";
import { LuEye } from "react-icons/lu";

function ReviewsPage() {
    const location = useLocation();
    console.log("reviews page: ", location);
    const [isLoading, setIsLoading] = useState(false);
    const [totalRate, setTotalRate] = useState(0);
    const [totalComments, setTotalComments] = useState(0);
    const [reviews, setReviews] = useState([]);

    useEffect(() => {
        fetchReviews();
    }, []);

    const fetchReviews = async () => {
        try {
            const response = await fetch(`https://bigsnab.kz/api-getAllReviews/${location.state.providerId}`);
            
            const responseJson = await response.json();

            if (responseJson.success) {
                setTotalRate(responseJson.totalRate);
                setTotalComments(responseJson.totalComments);
                setReviews(responseJson.reviews);
            }
        } catch {

        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            { isLoading && (
                <div className="w-screen h-screen flex justify-center items-center bg-[#438501]">
                    <ReactLoading type="spin" color="#FFF" height={25} width={25} />
                </div>
            ) }
            { !isLoading && (
                <div className="min-h-screen flex flex-col items-center">
                    <Navbar />
                    <div className="w-full flex flex-col items-center">
                        <div className="flex flex-row items-center space-x-2 mt-20">
                            <Link to='/' className="w-7 h-7 flex justify-center items-center rounded-full bg-[#91949E] hover:opacity-50">
                                <IoIosArrowBack className="text-lg text-white"/>
                            </Link>
                            <p className="text-lg font-semibold text-[#292B2F] text-center">Отзывы на "{location.state.company}"</p>
                        </div>
                        { reviews ? (
                            <>
                                <div className="flex flex-row space-x-3 items-center w-96">
                                    <div className="flex flex-row items-center space-x-2">
                                        <IoIosStar className="text-lg text-[#91949E]"/>
                                        <p className="text-lg text-[#91949E]">{totalRate}</p>
                                    </div>
                                    <div className="flex flex-row items-center space-x-2">
                                        <LuEye className="text-lg text-[#91949E]"/>
                                        <p className="text-lg text-[#91949E]">{totalComments}</p>
                                    </div>
                                </div>
                                <div className="flex flex-col p-2 overflow-x-auto overflow-y-auto h-[80vh]">
                                    { reviews.map((item, index) => (
                                        <div className="flex flex-row w-96 space-x-2 mb-6">
                                            <img src={`https://bigsnab.kz/api-userImage/${item.photo}`} className="w-16 h-16 rounded-xl"/>
                                            <div className="flex-1">
                                                <div className="flex flex-row flex-1 justify-between w-full">
                                                    <div>
                                                        <p className="text-xs">{item.date}</p>
                                                        <p className="text-md">{item.fullname}</p>
                                                    </div>
                                                    <div className="flex flex-row space-x-1 items-center">
                                                        { [1, 2, 3, 4, 5].map((star, index) => (
                                                            <IoIosStar key={index} className={ star <= item.stars ? "text-lg text-[#f5da42]" : "text-lg text-[#91949E]" }/>
                                                        )) }
                                                    </div>
                                                </div>
                                                <p>{item.comment}</p>
                                            </div>
                                        </div>
                                    )) }
                                </div>
                            </>
                        ) : (
                            <div className="h-[80vh] w-96 flex flex-col justify-center items-center">
                                <p className="text-center text-lg font-semibold">У данного поставщика нет отзывов</p>
                                <p className="text-center text-md">Вы сможете написать отзыв только после того, как примите от него отклик</p>
                            </div>
                        ) }
                    </div>
                </div>
            ) }
        </>
    )
};

export default ReviewsPage