import Navbar from "../ux/Navbar";

function PublicPage() {
    return (
        <div>
            <Navbar/>
            <div className='mt-24 mobile:px-12 lg:px-28 space-y-4 mb-32'>
                <p className='text-lg font-medium text-[#5BB600]'>ПУБЛИЧНЫЙ ДОГОВОР-ОФЕРТА</p>
                <p className='text-lg font-medium text-[#5BB600]'>1. ОБЩИЕ ПОЛОЖЕНИЯ</p>
                <p className='text-lg font-medium text-black/25'>
                    1.1. ИП BISSIKEN GROUP публикует настоящий договор купли-продажи, являющийся публичным договором-офертой (предложением) в адрес физических и юридических лиц в соответствии со ст. 447 Гражданского Кодекса Республики Казахстан (далее – ГК РК).<br/><br/>

                    1.2. Настоящая публичная оферта (именуемая в дальнейшем «Оферта») определяет все существенные условия договора между ИП BISSIKEN GROUP и лицом, акцептовавшим Оферту.<br/><br/>

                    1.3. Настоящий договор заключается между Покупателем и BigSnab в момент оформления аренды.<br/><br/>

                    1.4. Оферта может быть принята любым физическим или юридическим лицом на территории Республики Казахстан, имеющим намерение приобрести товар и/или услуги, реализуемые/предоставляемые BigSnabом расположенные на сайте BigSnab bigsnab.kz.<br/><br/>

                    1.5. Покупатель безоговорочно принимает все условия, содержащиеся в оферте в целом (т.е. в полном объеме и без исключений).<br/><br/>

                    1.6. В случае принятия условий настоящего договора (т.е. публичной оферты BigSnab), физическое или юридическое лицо, производящее акцепт оферты, становится Покупателем.<br/><br/>

                    1.7. Акцептом является получение BigSnab сообщения о намерении физического или юридического лица приобрести товар на условиях, предложенных BigSnabом<br/><br/>

                    1.8. Оферта и все приложения к ней, а также вся дополнительная информация о товарах/услугах, опубликованы на сайте BigSnab bigsnab.kz.<br/><br/>

                    1.9. Покупатель принимает и согласен, что лицом, фактически производящим реализацию товаров через BigSnab (Поставщик) может являться третье лицо, имеющее соответствующие договорные отношения с BigSnabом и осуществляющее соответствующий лицензируемый вид деятельности.<br/><br/>
                </p>
                <p className='text-lg font-medium text-[#5BB600]'>2. СТАТУС CЕРВИСА</p>
                <p className='text-lg font-medium text-black/25'>
                    2.1. Сервис является собственностью ИП BISSIKEN GROUP и предназначен для организации дистанционного способа продажи товаров через сеть интернет.<br/><br/>

                    2.2. Сделки BigSnab, регулируются договором купли-продажи (см.ниже) на условиях публичной оферты, размещенным по адресу BigSnab bigsnab.kz. Произведя акцепт оферты (т.е. оплату оформленного в BigSnabе заказа), Покупатель получает в собственность товар на условиях Договора купли-продажи.<br/><br/>

                    2.3. BigSnab не несет ответственности за содержание и достоверность информации, предоставленной Покупателем при оформлении заказа.<br/><br/>
                </p>
                <p className='text-lg font-medium text-[#5BB600]'>3. СТАТУС ПОКУПАТЕЛЯ</p>
                <p className='text-lg font-medium text-black/25'>
                    3.1. Покупатель несет ответственность за достоверность предоставленной при оформлении заказа информации, и ее чистоту от претензий третьих лиц.<br/><br/>

                    3.2. Покупатель подтверждает свое согласие с условиями, установленными настоящим Договором, путем проставления отметки в графе «Условия Договора мною прочитаны полностью, все условия Договора мне понятны, со всеми условиями Договора я согласен» при оформлении заказа.<br/><br/>

                    3.3. Информация, предоставленная Покупателем, является конфиденциальной. Покупатель, предоставляя свои персональные данные, зарегистрировавшись на сайте или заполнении заявки дает своими действиями согласие на обработку его персональных данных в целях исполнения пользовательского соглашения. BigSnab использует информацию о Покупателе исключительно в целях указанных в настоящем Договоре-оферте.<br/><br/>

                    3.4. Товар приобретается Покупателем исключительно для личных, семейных, домашних нужд, не связанных с осуществлением предпринимательской деятельности. Использование ресурса BigSnabа для просмотра и выбора товара, а также для оформления заказа является для Покупателя безвозмездным.<br/><br/>
                </p>
                <p className='text-lg font-medium text-[#5BB600]'>4. ПРЕДМЕТ ОФЕРТЫ</p>
                <p className='text-lg font-medium text-black/25'>
                    4.1. Предметом настоящего Договора оферты является предоставление возможности Покупателю приобретать для личных, семейных, домашних и иных нужд, не связанных с осуществлением предпринимательской деятельности Товары, размещенные на сайте BigSnab bigsnab.kz. BigSnab сервис на основании заказов Покупателя, продаёт Покупателю товар в соответствии с условиями и по ценам, установленным Продавцом в оферте и приложениях к ней.<br/><br/>

                    4.2. Договор распространяется на все виды Товаров и услуг, представленных на сайте BigSnab bigsnab.kz, пока такие предложения с описанием присутствуют в каталоге на сайте BigSnab bigsnab.kz.<br/><br/>

                    4.3. Доставка товаров, заказанных и оплаченных Покупателем, осуществляется Арендодатель или Перевозчиком<br/><br/>

                    4.3. К отношениям между Покупателем и Арендодателем применяются положения ГК РК, Закон Республики Казахстан от 04.05.2010 N 274-IV "О защите прав потребителей", а также иные нормативные правовые акты, принятые в соответствии с ними.<br/><br/>

                    4.4. Физическое или юридическое лицо считается принявшим все условия оферты (акцепт оферты) и приложений к ней в полном объеме и без исключений с момента получения Продавцом сообщения о намерении Покупателя приобрести товар на условиях, предложенных Продавцом. В случае акцепта оферты физическое или юридическое лицо считается заключившим с Продавцом договор купли-продажи заказанных товаров и приобретает статус Покупателя.<br/><br/>
                </p>
                <p className='text-lg font-medium text-[#5BB600]'>5. ПОРЯДОК ЗАКЛЮЧЕНИЯ ДОГОВОРА КУПЛИ-ПРОДАЖИ</p>
                <p className='text-lg font-medium text-black/25'>
                    5.1. Покупатель может оформить заказ самостоятельно на сайте BigSnab (интернет-сервис).<br/><br/>

                    5.2. При оформлении заказа на BigSnab сервис, Покупатель обязан предоставить информацию о себе:<br/><br/>

                    Ф.И.О. (для физических лиц)<br/>
                    Контактный телефон и электронную почту Покупателя Товара. (при регистрации аккаунта)<br/><br/>
                    
                    5.3. Волеизъявление Покупателя осуществляется посредством внесения последним соответствующих данных в форму заказа в сервисе либо подачей заявки через менеджера сервиса или по электронной почте номеру телефона.<br/><br/>

                    5.4. BigSnab не редактирует информацию о Покупателе.<br/><br/>

                    5.5. Для получения бумажного экземпляра Договора купли-продажи, Покупатель отправляет заявку по электронной почте или телефонам, указанным на сайте.<br/><br/>
                </p>
                <p className='text-lg font-medium text-[#5BB600]'>6. ИНФОРМАЦИЯ О ТОВАРЕ</p>
                <p className='text-lg font-medium text-black/25'>
                    6.1. Товар представлен на сайте через графические изображения-образцы, являющиеся собственностью Арендодателя.<br/><br/>

                    6.2. Каждое графическое изображение-образец сопровождается текстовой информацией: наименованием, ценой и описанием товара.<br/><br/>
                </p>
                <p className='text-lg font-medium text-[#5BB600]'>7. ПОРЯДОК ПРИОБРЕТЕНИЯ ТОВАРА</p>
                <p className='text-lg font-medium text-black/25'>
                    7.1. Покупатель вправе оформить заказ на любой товар, представленный на сайте BigSnab bigsnab.kz.<br/><br/>

                    7.2. Заказ может быть оформлен Покупателем по телефонам, указанным на сайте, или оформлен самостоятельно на сайте BigSnab bigsnab.kz в переписке с Арендодалем в онайлн-мессенджере BigSnab<br/><br/>
                </p>
                <p className='text-lg font-medium text-[#5BB600]'>8. ЦЕНА ТОВАРА</p>
                <p className='text-lg font-medium text-black/25'>
                    8.1. Цена товара на BigSnab (онлайн-сервис) указана в тенге Республики Казахстан за единицу товара.<br/><br/>
                </p>
                <p className='text-lg font-medium text-[#5BB600]'>9. ОПЛАТА ТОВАРА</p>
                <p className='text-lg font-medium text-black/25'>
                    9.1. Способы и порядок оплаты товара указаны на сайте в разделе «Оплата» сайта BigSnab bigsnab.kz.<br/><br/>

                    9.3. Оплата безналичным расчетом производится при помощи платежной организации или банка. При безналичной форме оплаты обязанность Покупателя по уплате цены товара считается исполненной с момента зачисления соответствующих денежных средств на расчетный счет Арендодателя.<br/><br/>

                    9.4. Покупатель оплачивает заказ любым способом, выбранным им самим<br/><br/>

                    9.5. Расчеты Сторон при оплате заказа осуществляются в тенге Республики Казахстан.<br/><br/>
                </p>
                <p className='text-lg font-medium text-[#5BB600]'>10. ОТВЕТСТВЕННОСТЬ СТОРОН</p>
                <p className='text-lg font-medium text-black/25'>
                    10.1. Стороны несут ответственность за исполнение обязательств по настоящему Договору оферты в соответствии с Законом Республики Казахстан «О Защите прав потребителей», Гражданским кодексом Республики Казахстан другими нормативно-правовыми актами Республики Казахстан.<br/><br/>

                    10.2. BigSnab не несет ответственности за ущерб, причиненный Покупателю вследствие ненадлежащего использования им товаров, заказанных в BigSnab.<br/><br/>

                    10.3. Покупатель несет ответственность за достоверность и достаточность предоставленной при оформлении Заказа информации.<br/><br/>

                    10.4. BigSnab не несет ответственности в случае неправильного выбора Покупателем характеристик Товара.<br/><br/>

                    10.5. Стороны освобождаются от ответственности за полное или частичное неисполнение своих обязательств, если неисполнение является следствием таких непреодолимых обстоятельств как: война или военные действия, землетрясение, наводнение, пожар и другие стихийные бедствия, акты или действия органов государственной власти, изменение таможенных правил, ограничения импорта и экспорта, возникших независимо от воли Сторон после заключения настоящего Договора. Сторона, которая не может исполнить свои обязательства, незамедлительно извещает об этом другую Сторону и предоставляет документы, подтверждающие наличие таких обстоятельств, выданные уполномоченными на то органами<br/><br/>
                </p>
                <p className='text-lg font-medium text-[#5BB600]'>11. ПРОЧИЕ УСЛОВИЯ</p>
                <p className='text-lg font-medium text-black/25'>
                    11.1. К отношениям между Покупателем и BigSnab применяется законодательство Республики Казахстан.<br/><br/>

                    11.2. При необходимости Стороны вправе в любое время оформить договор купли-продажи товара в форме письменного двухстороннего соглашения, не противоречащего положениям настоящей оферты.<br/><br/>

                    11.3. В случае возникновения вопросов и претензий со стороны Покупателя, он должен обратиться в отдел продаж ТОО «Rocket Pharm» попо телефону: +7 777 777 7777 или написав на почтовый адрес supplywise@gmail.kz.<br/><br/>

                    11.4. Настоящий договор вступает в силу с даты акцепта Покупателем настоящей оферты и действует до полного исполнения обязательств Сторонами.<br/><br/>

                    11.5. Все споры и разногласия, возникающие при исполнении Сторонами обязательств по настоящему договору, решаются путем переговоров. В случае невозможности их устранения, Стороны имеют право обратиться за судебной защитой своих интересов.<br/><br/>

                    11.6. BigSnab оставляет за собой право расширять и сокращать товарное предложение на сайте, регулировать доступ к покупке любых товаров, а также приостанавливать или прекращать продажу любых товаров по своему собственному усмотрению.<br/><br/>
                </p>
                <p className='text-lg font-medium text-[#5BB600]'>12. АДРЕС И РЕКВИЗИТЫ BigSnab:</p>
                <p className='text-lg font-medium text-black/25'>
                    ИП BISSIKEN GROUP<br/>
                    БИН 860115451410<br/>
                    Казахстан, Актобе, ПРОСПЕКТ АБИЛКАЙЫР ХАНА, дом 78, кв/офис 111<br/>
                    KZ43722S000034305543 в АО "Kaspi Bank", БИК CASPKZKA<br/>
                    supplywise@gmail.kz, +7 777 777 7777<br/>
                </p>
            </div>
        </div>
    )
};

export default PublicPage;