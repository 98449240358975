import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Navbar from "../ux/Navbar";
import OrderHistory from '../ux/Bussines/OrderHistory';
import AdminPanel from '../ux/Bussines/AdminPanel';
import ReactLoading from 'react-loading';
import CongratulationsModal from '../ux/CongratulationsModal';

function BussinesPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const activeTab = queryParams.get('tab') || 'history';  // Default to 'history' tab
    const [userData, setUserData] = useState({});
    const [providerInfo, setProviderInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [feedbacks, setFeedbacks] = useState([]);
    const [isOpenCongratulationsModal, setIsCongratulationsModal] = useState(false);

    useEffect(() => {
        fetchUserInfo();
    }, []);

    const fetchUserInfo = async () => {
        const authToken = document.cookie.replace(/(?:(?:^|.*;\s*)auth_token\s*=\s*([^;]*).*$)|^.*$/, "$1");

        try {
            const response = await fetch(`https://bigsnab.kz/api-getUserInfo`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}` // Устанавливаем токен в заголовке Authorization
                }
            });

            const data = await response.json();

            if (data.success) {
                setUserData(data.user);
                fetchProviderData(data.user.providerId);
                fetchFeedbacks(data.user.providerId);
                fetchIsNotifications(data.user.id);
            } else {
                // Обработка ошибки, если не удалось получить информацию о пользователе
                console.error("Failed to fetch user data:", data.message);
            }
        } catch (error) {
            // Обработка ошибки запроса
            console.error("Error fetching user data:", error);
        } finally {
            setIsLoading(false); // Устанавливаем isLoading в false после завершения загрузки
        }
    };

    const fetchIsNotifications = async (id) => {
        try {
            const response = await fetch(`https://bigsnab.kz/api-getIsWelcomeNotification/${id}`);

            const responseJson = await response.json();

            if (responseJson.success) {
                console.log("responseJson.isNotification: ", responseJson.isNotification);
                setIsCongratulationsModal(responseJson.isNotification === 1 ? true : false);
            }
        } catch (error) {
            console.log("fetch is notification error: ", error);
        }
    };

    const fetchProviderData = async (providerId) => {
        try {
            const response = await fetch(`https://bigsnab.kz/api-getProviderInfo/${providerId}`);

            const responseData = await response.json();

            if (responseData.success) {
                setProviderInfo(responseData.providerInfo[0]);
            }
        } catch (error) {
            console.log("fetch provider info error: ", error);
        }
    }

    const fetchFeedbacks = async (id) => {
        try {
            const response = await fetch(`https://bigsnab.kz/api-getAllFeedbacks/${id}`);
            const responseData = await response.json();

            if (responseData.success) {
                setFeedbacks(responseData.feedbacks);
            }
        } catch (error) {
            console.error('Error fetching feedbacks:', error);
        }
    };

    const handleTabChange = (tab) => {
        navigate (`?tab=${tab}`);
    };

    return (
        <>
            { isLoading && (
                <div className="w-screen h-screen flex justify-center items-center bg-[#438501]">
                    <ReactLoading type="spin" color="#FFF" height={25} width={25} />
                </div>
            ) }
            { !isLoading && (
                <div className="min-h-screen flex flex-col items-center">
                    <Navbar />
                    <div className="w-full flex flex-col items-center">
                        <p className="mt-20 text-lg font-semibold text-[#292B2F]">Бизнес</p>
                        <div className="flex flex-row items-center space-x-6 mt-4">
                            <button 
                                className={`py-1 ${activeTab === 'history' ? 'border-b-2 border-[#5BB600]' : ''}`} 
                                onClick={() => handleTabChange('history')}
                            >
                                <p className={`${activeTab === 'history' ? '' : 'opacity-50 hover:opacity-100'}`}>История заказов</p>
                            </button>
                            <button 
                                className={`py-1 ${activeTab === 'admin' ? 'border-b-2 border-[#5BB600]' : ''}`} 
                                onClick={() => handleTabChange('admin')}
                            >
                                <p className={`${activeTab === 'admin' ? '' : 'opacity-50 hover:opacity-100'}`}>Админ.панель</p>
                            </button>
                        </div>
                        <div className="mt-8 w-full flex flex-col items-center">
                            {activeTab === 'history' && <OrderHistory userData={userData} providerInfo={providerInfo}/> }
                            {activeTab === 'admin' && <AdminPanel feedbacks={feedbacks} userData={userData} providerInfo={providerInfo}/> }
                        </div>
                    </div>
                </div>
            ) }
            { isOpenCongratulationsModal && <CongratulationsModal userId={userData.id} onClose={() => setIsCongratulationsModal(false)}/> }
        </>
    );
}

export default BussinesPage;
