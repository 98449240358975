import { useState, useEffect } from "react";
import HistoryCard from "../HistoryCard";

function OrderHistory({ userData, providerInfo }) {
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        fetchHistoryOrders();
        console.log("userData.providerId", userData.providerId);
    }, []);

    const fetchHistoryOrders = async () => {
        try {
            const response = await fetch(`https://bigsnab.kz/api-getAllProviderHistory/${userData.providerId}`);

            const responseJson = await response.json();

            if (responseJson.success) {
                setOrders(responseJson.orders);
            }
        } catch {

        }
    };

    return (
        <div className="w-full h-full justify-center items-center flex flex-col -mt-6">
            <div className="desktop:w-5/12 p-2 flex flex-row flex-wrap justify-between">
                { orders.length > 0 ? (
                    <>  
                        { orders.map((item, index) => (
                            <HistoryCard key={index} data={item}/>
                        )) }
                    </>
                ) : (
                    <div className="w-full h-[80vh] flex flex-col justify-center items-center space-y-2">
                        <p className="text-lg text-black font-semibold text-center">Ваша история заказов пуста</p>
                        <p className="text-lg font-light text-center">Здесь будут отображться вся история Ваших заказов, а так же подробная информация о них</p>
                    </div>
                ) }
            </div>
        </div>
    )
};

export default OrderHistory;