import { useState } from "react";
import { LuPlus } from "react-icons/lu";

function Accordion({ question, answer }) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={ isOpen ? "tablet:w-10/12 mobile:w-full laptop:w-8/12 border-b-[1px] py-4 border-b-[#5BB600]" : "tablet:w-10/12 mobile:w-full laptop:w-8/12 border-b-[1px] py-4 border-b-black/50" }>
            <div className="flex flex-row w-full items-center justify-between">
                <p className="mobile:text-md tablet:text-lg laptop:text-lg font-semibold text-black/50">{question}</p>
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="hover:opacity-50 transition-transform duration-300 ease-in-out"
                >
                    <LuPlus
                        className={`text-lg text-black/50 transform ${isOpen ? 'rotate-45' : 'rotate-0'}`}
                    />
                </button>
            </div>
            <div className={`overflow-hidden transition-max-height duration-500 ease-in-out ${isOpen ? 'max-h-[1000px]' : 'max-h-0'}`}>
                <p className="mobile:text-md tablet:text-md laptop:text-lg font-light">{answer}</p>
            </div>
        </div>
    );
}

export default Accordion;
