import Navbar from "../ux/Navbar"

function ReviewPage() {
    return (
        <div>
            <Navbar/>
            <div className="w-screen h-screen flex flex-col justify-center items-center">
                <p className="text-lg text-[#292B2F] font-semibold">Добавить отзыв</p>
                <div className="mt-6 justify-center items-center flex flex-col mobile:w-10/12 tablet:w-6/12 desktop:w-4/12 p-12 bg-[#438501] rounded-tl-[50px] rounded-br-[50px]">
                    <p className="text-lg text-white font-semibold text-center">Поделитесь вашим мнением!</p>
                    <p className="mobile:text-md tablet:text-md text-white text-center mt-1">Пожалуйста, возьмите себе мгновение, чтобы оценить наше приложение и поделиться вашими мыслями. Ваш отзыв поможет нам сделать его еще лучше для вас. Спасибо!"</p>
                    <div className="flex flex-row items-center mobile:space-x-6 laptop:space-x-16 m-6">
                        <div className="bg-white rounded-xl mobile:w-24 mobile:h-24 tablet:w-32 tablet:h-32 justify-center items-center flex hover:opacity-50">
                            <img className="mobile:w-12 mobile:h-12 tablet:w-20 tablet:h-20 object-contain" src={require('../../images/icons/googlePlay.png')}/>
                        </div>
                        <div className="bg-white rounded-xl mobile:w-24 mobile:h-24 tablet:w-32 tablet:h-32 justify-center items-center flex hover:opacity-50">
                            <img className="mobile:w-12 mobile:h-12 tablet:w-20 tablet:h-20 object-contain" src={require('../../images/icons/appStore.png')}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ReviewPage;