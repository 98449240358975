import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function AuthPage() {
    const navigation = useNavigate();
    const [data, setData] = useState({
        login: "",
        password: ""
    });
    const [message, setMessage] = useState();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
    };   
    
    const auth = async () => {
        try {
            const response = await fetch('https://bigsnab.kz/api-authenticateSite', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ login: data.login, password: data.password })
            });
            const responseJson = await response.json();
            if (responseJson.success) {
                setMessage();
                document.cookie = `auth_token=${responseJson.token}; path=/`;
                window.location.reload();
            } else {
                // Регистрация не удалась, вы можете обработать ошибку здесь
                setMessage(responseJson.message)
            }
        } catch (error) {
            console.error('Ошибка при отправке запроса:', error);
        }
    }

    return (
        <div className="bg-white">
            <div className="h-screen w-screen justify-center items-center flex flex-col">
                <p className="text-2xl font-semibold text-[#5BB600] mobile:mb-6 desktop:mb-6">BigSnab</p>
                <div className="bg-white mobile:w-11/12 border-2 tablet:w-8/12 laptop:w-5/12 desktop:w-4/12 desktop2:w-96 shadow-xl rounded-tl-[50px] rounded-br-[50px]">
                    <div className="bg-[#438501] rounded-tl-[50px] rounded-br-[50px] mobile:px-4 tablet:px-8 py-6">
                        <p className="text-lg text-white text-center font-semibold">Добро пожаловать!</p>
                        <p className="text-md mt-1 text-white text-center">Войдите в свой аккаунт</p>
                        <div className="mt-6 space-y-4">
                            <div className="w-full bg-white p-3 rounded-xl">
                                <input name="login" value={data.login} onChange={handleChange} className="bg-transparent outline-none text-lg w-full" placeholder="Логин"/>
                            </div>
                            <div className="w-full bg-white p-3 rounded-xl flex flex-row justify-between items-center">
                                <input name="password" value={data.password} onChange={handleChange}  className="bg-transparent outline-none text-lg w-full" placeholder="Пароль" type="password"/>
                            </div>
                        </div>
                        <div className="w-full items-end justify-end flex">
                            <Link to="/recoveryPassword" className="hover:opacity-50">
                                <p className="text-sm text-white py-2">Забыли пароль?</p>
                            </Link>
                        </div>
                    </div>
                    <div className="px-12 py-6">
                        { message && <p className="text-red-500 text-center text-md mb-6">{message}</p> }
                        <button onClick={auth} disabled={!data.login || !data.password} className={ !data.login || !data.password ? "bg-[#438501] opacity-50 p-2 w-full rounded-xl" : "bg-[#438501] hover:opacity-50 p-2 w-full rounded-xl" }>
                            <p className="text-lg text-white">Войти</p>
                        </button>
                        <p className="text-md text-center py-2 text-[#91949E]">Нет аккаунта? <button onClick={() => navigation("/reg")} className="hover:opacity-50"><p className="text-[#5BB600]">Создайте</p></button></p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AuthPage;