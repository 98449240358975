import { useState, useEffect } from "react";
import Navbar from "../ux/Navbar";
import RangeCard from "../ux/RangeCard";
import { Link } from "react-router-dom";

function RangePage() {
    const [userData, setUserData] = useState({});
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        fetchUserInfo();
    }, []);

    const fetchUserInfo = async () => {
        const authToken = document.cookie.replace(/(?:(?:^|.*;\s*)auth_token\s*=\s*([^;]*).*$)|^.*$/, "$1");

        try {
            const response = await fetch(`https://bigsnab.kz/api-getUserInfo`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}` // Устанавливаем токен в заголовке Authorization
                }
            });

            const data = await response.json();

            if (data.success) {
                setUserData(data.user);
                fetchOrders(data.user.id);
            } else {
                // Обработка ошибки, если не удалось получить информацию о пользователе
                console.error("Failed to fetch user data:", data.message);
            }
        } catch (error) {
            // Обработка ошибки запроса
            console.error("Error fetching user data:", error);
        }
    };

    const fetchOrders = async (id) => {
        try {
            const response = await fetch(`https://bigsnab.kz/api-getAssortment/${id}`);

            const responseData = await response.json();

            if (responseData.success) {
                setOrders(responseData.orders);
            }
        } catch {

        }
    }

    return (
        <div>
            <Navbar/>
            <div className="w-full h-full justify-center items-center flex flex-col">
                <p className="mt-20 mobile:text-lg laptop:text-lg font-semibold text-[#292B2F]">Управление заказами</p>
                <div className="desktop:w-6/12 p-2 flex flex-row flex-wrap justify-between">
                    { orders.length > 0 ? (
                        <>  
                            { orders.map((item, index) => (
                                <RangeCard key={index} data={item}/>
                            )) }
                        </>
                    ) : (
                        <div className="w-full h-[70vh] flex flex-col justify-center items-center space-y-6">
                            <div className="space-y-1">
                                <p className="mobile:text-lg laptop:text-lg text-black font-semibold text-center">У вас пока нет добавленного заказ</p>
                                <p className="mobile:text-md laptop:text-lg font-light text-center">Здесь будет отображаться все ваши личные заказ</p>
                            </div>
                        </div>
                    ) }
                </div>
                <Link to='/addProduct' className="bg-[#5BB600] hover:opacity-50 p-4 rounded-xl w-64 mb-6">
                    <p className="text-lg text-white text-center">Добавить заказ</p>
                </Link>
            </div>
        </div>
    )
};

export default RangePage;