import { useState, useEffect } from "react";
import Navbar from "../ux/Navbar";
import { FiPlus } from "react-icons/fi";
import { IoIosArrowForward } from "react-icons/io";
import ListOne from "../ux/ListOne";
import ListTwo from "../ux/ListTwo";
import { useNavigate } from "react-router-dom";
import Cities from "../ux/Cities";
import UnitsList from "../ux/Units";
import { NumericFormat } from "react-number-format";

function CreateOrder() {
    const [orderType, setOrderType] = useState('');
    const [isOpenListOne, setIsOpenListOne] = useState(false);
    const [isOpenListTwo, setIsOpenListTwo] = useState(false);
    const [step, setStep] = useState(1);
    const [data, setData] = useState({
        title: '',
        count: '',
        minPrice: '',
        maxPrice: '',
        fromDate: new Date(),
        toDate: new Date(),
        addtionalInfo: [],
        previewImage: '',
        comment: '',
        city: '',
        unit: ''
    });
    const [unit, setUnit] = useState();
    const [userData, setUserData] = useState({});
    const [messagers, setMessagers] = useState({});
    const [isEmptyMessangers, setIsEmptyMessangers] = useState(false);
    const [isOpenCities, setIsOpenCities] = useState(false);
    const navigation = useNavigate();
    const [isOpenUnit, setIsOpenUnit] = useState(false);

    useEffect(() => {
        fetchUserInfo();
    }, []);

    const fetchUserInfo = async () => {
        const authToken = document.cookie.replace(/(?:(?:^|.*;\s*)auth_token\s*=\s*([^;]*).*$)|^.*$/, "$1");

        try {
            const response = await fetch(`https://bigsnab.kz/api-getUserInfo`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}` // Устанавливаем токен в заголовке Authorization
                }
            });

            const data = await response.json();

            if (data.success) {
                setUserData(data.user);
                console.log(data.user);
            } else {
                // Обработка ошибки, если не удалось получить информацию о пользователе
                console.error("Failed to fetch user data:", data.message);
            }
        } catch (error) {
            // Обработка ошибки запроса
            console.error("Error fetching user data:", error);
        }
    };

    const selectOrderType = (type) => {
        setOrderType(type);
        setStep(2);
    };

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('ru-RU', options);
    };

    const order = async () => {
        try {
            const formattedDate = formatDate(data.toDate);

            const response = await fetch('https://bigsnab.kz/api-createOrder', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    title: data.title,
                    count: data.count,
                    minPrice: data.minPrice,
                    maxPrice: data.maxPrice,
                    additionalInfo1: data.addtionalInfo[0] || null,
                    additionalInfo2: data.addtionalInfo[1] || null,
                    additionalInfo3: data.addtionalInfo[2] || null,
                    additionalInfo4: data.addtionalInfo[3] || null,
                    additionalInfo5: data.addtionalInfo[4] || null,
                    userId: userData?.id,
                    comment: data.comment,
                    type: orderType,
                    telegram: userData.telegram || null,
                    whatsapp: userData.whatsapp || null,
                    viber: userData.whatsapp || null,
                    executionDate: formattedDate,
                    clientName: `${userData?.fullname} ${userData?.surname}`,
                    previewImage: data.previewImage,
                    city: data.city,
                    unit: data.unit
                })
            });

            const responseData = await response.json();

            if (responseData.success) {
                navigation("/")
            }

        } catch {

        }
    };

    const handleInputChange = (name, value) => {
        if (name === 'addtionalInfo') {
            // Если категория уже есть в списке, удаляем ее
            if (data.addtionalInfo.includes(value)) {
                setData(prevData => ({
                    ...prevData,
                    addtionalInfo: prevData.addtionalInfo.filter(item => item !== value)
                }));
            } else {
                // Иначе добавляем категорию в список
                setData(prevData => ({
                    ...prevData,
                    addtionalInfo: [...prevData.addtionalInfo, value]
                }));
            }
        } else {
            // Если это не категория товара, обрабатываем как обычное поле
            setData(prevData => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    return (
        <div>
            <Navbar/>
            <div className="w-screen h-screen flex flex-col justify-center items-center">
                <p className="text-md text-[#292B2F] font-semibold mt-16">Создание заказа</p>
                <div className="overflow-x-auto max-h-[700px] px-2">
                    { step === 1 && (
                        <>  
                            <button onClick={() => selectOrderType("Товары")} className="mt-6 hover:opacity-50 justify-center items-center flex flex-col mobile:w-64 tablet:w-80 p-12 bg-[#438501] rounded-tl-[50px] rounded-br-[50px]">
                                <div className="flex justify-center items-center bg-white rounded-full w-20 h-20">
                                    <FiPlus className="text-4xl text-[#5BB600]"/>
                                </div>
                                <p className="text-md text-white">Заказать</p>
                                <p className="text-md text-white">Товары</p>
                            </button>
                            <button onClick={() => selectOrderType("Услуги")}className="mt-6 hover:opacity-50 justify-center items-center flex flex-col mobile:w-64 tablet:w-80 p-12 bg-[#438501] rounded-tl-[50px] rounded-br-[50px]">
                                <div className="flex justify-center items-center bg-white rounded-full w-20 h-20">
                                    <FiPlus className="text-4xl text-[#5BB600]"/>
                                </div>
                                <p className="text-md text-white">Заказать</p>
                                <p className="text-md text-white">Услуги</p>
                            </button>
                        </>
                    ) }
                    { step === 2 && (
                        <>
                            <div className="border-b-[1px] border-b-[#91949E] mobile:w-64 tablet:w-80 py-2 px-2">
                                <div className="flex flex-row space-x-2 items-center">
                                    <p className="text-md text-[#91949E]">Название товара</p>
                                </div>
                                <div className="flex flex-row items-center justify-between">
                                    <p className="text-md text-[#292B2F]">{ data.title ? data.title :'Выберите название' }</p>
                                    <button onClick={() => setIsOpenListOne(true)} className="hover:opacity-50 w-6 h-6 rounded-full justify-center items-center flex bg-[#91949E]">
                                        <IoIosArrowForward className="text-md text-white"/>
                                    </button>
                                </div>
                            </div>
                            { orderType === 'Товары' && (
                                <>
                                    <div className="border-b-[1px] border-b-[#91949E] mobile:w-64 tablet:w-80 py-2 px-2">
                                        <div className="flex flex-row space-x-2 items-center">
                                            <p className="text-md text-[#91949E]">Количество</p>
                                        </div>
                                        <NumericFormat thousandSeparator=" " value={data.count} onChange={(e) => handleInputChange("count", e.target.value)} className="text-md text-[#292B2F] bg-transparent outline-none" placeholder="Введите количество"/>
                                    </div>
                                    <div className="border-b-[1px] border-b-[#91949E] mobile:w-64 tablet:w-80 py-2 px-2">
                                        <div className="flex flex-row space-x-2 items-center">
                                            <p className="text-md text-[#91949E]">Единица измерения</p>
                                        </div>
                                        <div className="flex flex-row items-center justify-between">
                                            <p className="text-md text-[#292B2F]">{ unit ? unit :'Выберите единицу измерения' }</p>
                                            <button onClick={() => setIsOpenUnit(true)} className="hover:opacity-50 w-6 h-6 rounded-full justify-center items-center flex bg-[#91949E]">
                                                <IoIosArrowForward className="text-md text-white"/>
                                            </button>
                                        </div>
                                    </div>
                                </>
                            ) }
                            <div className="border-b-[1px] border-b-[#91949E] mobile:w-64 tablet:w-80 py-2 px-2">
                                <div className="flex flex-row space-x-2 items-center">
                                    <p className="text-md text-[#91949E]">Дата исполнения</p>
                                </div>
                                <div className="flex flex-row items-center justify-between">
                                    <input value={data.toDate} onChange={(e) => handleInputChange("toDate", (e.target.value))} type="date" className="text-md w-full outline-none bg-transparent"/>
                                </div>
                            </div>
                            <div className="border-b-[1px] border-b-[#91949E] mobile:w-64 tablet:w-80 py-2 px-2">
                                <div className="flex flex-row space-x-2 items-center">
                                    <p className="text-md text-[#91949E]">Город</p>
                                </div>
                                <div className="flex flex-row items-center justify-between">
                                    <p className="text-md text-[#292B2F]">{ data.city ? data.city :'Выберите город' }</p>
                                    <button onClick={() => setIsOpenCities(true)} className="hover:opacity-50 w-6 h-6 rounded-full justify-center items-center flex bg-[#91949E]">
                                        <IoIosArrowForward className="text-md text-white"/>
                                    </button>
                                </div>
                            </div>
                            <div className="mobile:w-64 tablet:w-80 py-2 px-2 flex-1 w-full">
                                <div className="flex flex-row space-x-2 items-center">
                                    <p className="text-md text-[#91949E]">Ценовой диапозон</p>
                                </div>
                                <div className="flex flex-row justify-between space-x-6">
                                    <div className="flex-1">
                                        <p className="text-md text-[#5BB600]">Минимальный</p>
                                        <NumericFormat
                                            value={data.minPrice}
                                            displayType="input"
                                            thousandSeparator=" "
                                            className="text-md w-full text-[#292B2F] bg-transparent outline-none border-b-[1px] border-b-[#91949E] pb-2"
                                            placeholder="0 тг"
                                            onValueChange={(values) => handleInputChange("minPrice", values.value)}
                                        />
                                    </div>
                                    <div className="flex-1">
                                        <p className="text-md text-[#5BB600]">Максимальный</p>
                                        <NumericFormat
                                            value={data.maxPrice}
                                            displayType="input"
                                            thousandSeparator=" "
                                            className="text-md w-full text-[#292B2F] bg-transparent outline-none border-b-[1px] border-b-[#91949E] pb-2"
                                            placeholder="0 тг"
                                            onValueChange={(values) => handleInputChange("maxPrice", values.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="border-b-[1px] border-b-[#91949E] mobile:w-64 tablet:w-80 py-2 px-2">
                                <div className="flex flex-row space-x-2 items-center">
                                    <p className="text-md text-[#91949E]">Дополнительные харак.товара</p>
                                </div>
                                <div className="flex flex-row items-center justify-between">
                                    <div className="flex-1 flex flex-row flex-wrap">
                                        { data.addtionalInfo.map((item, index) => (
                                            <div key={index} className="bg-[#5BB600] px-3.5 py-2 m-1 rounded-full">
                                                <p className="text-md text-white">{item}</p>
                                            </div>
                                        )) }
                                    </div>
                                    <button onClick={() => setIsOpenListTwo(true)} className="hover:opacity-50 w-6 h-6 rounded-full justify-center items-center flex bg-[#91949E]">
                                        <IoIosArrowForward className="text-md text-white"/>
                                    </button>
                                </div>
                            </div>
                            <div className="border-b-[1px] border-b-[#91949E] mobile:w-64 tablet:w-80 py-2 px-2">
                                <div className="flex flex-row space-x-2 items-center">
                                    <p className="text-md text-[#91949E]">Доп.условия</p>
                                </div>
                                <textarea value={data.comment} onChange={(e) => handleInputChange("comment", e.target.value)} className="text-md text-[#292B2F] bg-transparent outline-none h-24 w-full" placeholder="Введите доп.условия для поставщика"/>
                            </div>
                            <button onClick={order} className="p-3 mobile:w-64 tablet:w-80 bg-[#438501] rounded-xl mt-6 hover:opacity-50 mb-12">
                                <p className="text-md text-white">Заказать</p>
                            </button>
                        </>
                    ) }
                </div>
            </div>
            { isOpenListOne && <ListOne orderType={orderType} setCategory={handleInputChange} onClose={() => setIsOpenListOne(false)}/> }
            { isOpenListTwo && <ListTwo addtionalInfo={data.addtionalInfo} orderType={orderType} filter={data.title} onClose={() => setIsOpenListTwo(false)} setCategory={handleInputChange}/> }
            { isOpenCities && <Cities onClose={() => setIsOpenCities(false)} setCity={handleInputChange}/> }
            { isOpenUnit && <UnitsList setUnit={handleInputChange} setUnitName={setUnit} onClose={() => setIsOpenUnit(false)}/> }
        </div>
    )
};

export default CreateOrder;