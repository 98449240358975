import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Invite({ isLogged, userId, onClose, inviteData }) {
    const [admin, setAdmin] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (isLogged) {
            fetchAdmin();
        } else {
            onClose();
            navigate("/auth");
        }
    }, [isLogged]);

    const fetchAdmin = async () => {
        try {
            const response = await fetch("https://bigsnab.kz/api-getAdmin", {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ teamId: inviteData.teamId })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const responseJson = await response.json();

            if (responseJson.success) {
                setAdmin(responseJson.adminResult[0]);
                console.log("Admin fetched successfully:", responseJson.adminResult[0]);
            } else {
                console.error('Failed to fetch admin:', responseJson.message);
            }
        } catch (error) {
            console.error('Error fetching admin:', error);
        }
    }

    const accept = async () => {
        if (!admin) {
            console.error("Admin data is not available");
            return;
        }

        try {
            const response = await fetch('https://bigsnab.kz/api-acceptTeam', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ role: inviteData.role, teamMemberId: inviteData.teamId, userId: userId, providerId: admin.providerId })
            });

            const responseJson = await response.json();

            if (responseJson.success) {
                alert("Приглашение принято");
                navigate("/");
                onClose();
            } else {
                console.error('Failed to accept invite:', responseJson.message);
            }
        } catch (error) {
            console.error('Error accepting invite:', error);
        }
    }

    if (!isLogged) {
        return null;
    }

    return (
        <div className="fixed inset-0 z-20 flex justify-center items-center">
            <div className="fixed inset-0 bg-black bg-opacity-50" onClick={onClose}></div>
            <div className="mobile:w-10/12 tablet:w-4/12 desktop:w-1/4 absolute z-30 bg-white p-6 px-12 rounded-tl-[50px] rounded-br-[50px] space-y-4">
                <p className='text-lg font-semibold text-center'>Приглашения в команду</p>
                {admin ? (
                    <div className="flex flex-row space-x-2 w-full justify-center items-center">
                        <img src={`https://bigsnab.kz/api-userImage/${admin.photo}`} className="rounded-xl w-16 h-16" alt="Admin" />
                        <div className="flex flex-col">
                            <p className="text-lg text-[#292B2F]">{admin.fullname} {admin.surname}</p>
                            <p className="text-lg text-[#698BF9]">{admin.email}</p>
                        </div>
                    </div>
                ) : (
                    <p className='text-center'>Загрузка информации об администраторе...</p>
                )}
                <button onClick={accept} className='bg-[#5BB600] p-3 rounded-lg w-full hover:opacity-50'>
                    <p className='text-center text-lg text-white'>Принять</p>
                </button>
                <button onClick={() => { navigate("/"); onClose(); }} className='bg-[#91949E] p-3 rounded-lg w-full hover:opacity-50'>
                    <p className='text-center text-lg text-white'>Отклонить</p>
                </button>
            </div>
        </div>
    );
};

export default Invite;
