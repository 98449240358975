import { useEffect, useState, useRef } from "react";
import Navbar from "../ux/Navbar";
import { FaTelegram, FaWhatsapp, FaUser, FaViber, FaCity, FaSitemap, FaStamp, FaGlobe } from "react-icons/fa6";
import { IoMdMail, IoMdCheckmark } from "react-icons/io";
import ReactLoading from 'react-loading';
import { FiEdit } from "react-icons/fi";
import { IoCloseOutline, IoTimeOutline } from "react-icons/io5";
import { FaRegSave, FaTools, FaBoxes } from "react-icons/fa";
import { ImUserTie } from "react-icons/im";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { MdOutlineAddPhotoAlternate } from "react-icons/md";


function Profile() {
    const [userData, setUserData] = useState({});
    const [providerInfo, setProviderInfo] = useState(null);

    const [isLoading, setIsLoading] = useState(true);
    const [isAddMessagers, setIsAddMessagers] = useState(false);
    const [messagers, setMessagers] = useState({
        telegram: "",
        whatsapp: "",
        viber: ""
    });
    const [isEditTelegram, setIsEditTelegram] = useState(false);
    const [newTelegram, onChangeTelegram] = useState();

    const [isEditWhatsApp, setIsEditWhatsApp] = useState(false);
    const [newWhatsApp, onChangeWhatsApp] = useState();

    const [isEditViber, setIsEditViber] = useState(false);
    const [newViber, onChangeViber] = useState();

    const [isEditLogin, setIsEditLogin] = useState(false);
    const [newLogin, onChangeLogin] = useState();

    const [isEditEmail, setIsEditEmail] = useState(false);
    const [newEmail, onChangeEmail] = useState();

    const [isEditSite, setIsEditSite] = useState(false);
    const [newSite, onChangeSite] = useState();

    const [newImage, setNewImage] = useState(null);

    const fileInput = useRef(null);

    const handleChangeMessagers = (e) => {
        const { name, value } = e.target;
        setMessagers({
            ...messagers,
            [name]: value
        });
    };    

    const handleSubmitMessagers = async () => {
        try {
            const response = await fetch('https://bigsnab.kz/api-updateMessagers', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId: userData.id,
                    telegram: messagers.telegram,
                    whatsapp: messagers.whatsapp,
                    viber: messagers.viber
                })
            });

            if (response.ok) {
                window.location.reload();
            } else {
                console.error('Failed to update messagers');
            }
        } catch (error) {
            console.error('Error updating messagers:', error);
        }
    };

    useEffect(() => {
        fetchUserInfo();
    }, []);

    const fetchUserInfo = async () => {
        const authToken = document.cookie.replace(/(?:(?:^|.*;\s*)auth_token\s*=\s*([^;]*).*$)|^.*$/, "$1");

        try {
            const response = await fetch(`https://bigsnab.kz/api-getUserInfo`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}` // Устанавливаем токен в заголовке Authorization
                }
            });

            const data = await response.json();

            if (data.success) {
                setUserData(data.user);
                if (data.user.typeOfAccount === 'Provider') {
                    fetchProviderData(data.user.providerId);
                }
            } else {
                // Обработка ошибки, если не удалось получить информацию о пользователе
                console.error("Failed to fetch user data:", data.message);
            }
        } catch (error) {
            // Обработка ошибки запроса
            console.error("Error fetching user data:", error);
        } finally {
            setIsLoading(false); // Устанавливаем isLoading в false после завершения загрузки
        }
    };

    const fetchProviderData = async (providerId) => {
        try {
            const response = await fetch(`https://bigsnab.kz/api-getProviderInfo/${providerId}`);

            const responseData = await response.json();

            if (responseData.success) {
                setProviderInfo(responseData.providerInfo[0]);
            }
        } catch {

        }
    }

    const saveTelegram = async () => {
        try {
            const response = await fetch("https://bigsnab.kz/api-changeUserTelegram", {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ userId: userData.id, telegram: newTelegram })
            });

            const responseJson = await response.json();

            if (responseJson.success) {
                onChangeTelegram();
                setIsEditTelegram(false);
                window.location.reload();
            }
        } catch {

        }
    }
    
    const saveWhatsApp = async () => {
        try {
            const response = await fetch("https://bigsnab.kz/api-changeUserWhatsapp", {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ userId: userData.id, whatsapp: newWhatsApp })
            });

            const responseJson = await response.json();

            if (responseJson.success) {
                onChangeWhatsApp();
                setIsEditWhatsApp(false);
                window.location.reload();
            }
        } catch {

        }
    }

    const saveViber = async () => {
        try {
            const response = await fetch("https://bigsnab.kz/api-changeUserViber", {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ userId: userData.id, viber: newViber })
            });

            const responseJson = await response.json();

            if (responseJson.success) {
                onChangeViber();
                setIsEditViber(false);
                window.location.reload();
            }
        } catch {

        }
    }

    const saveLogin = async () => {
        try {
            const response = await fetch("https://bigsnab.kz/api-changeUserLogin", {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ userId: userData.id, login: newLogin })
            });

            const responseJson = await response.json();

            if (responseJson.success) {
                onChangeLogin();
                setIsEditLogin(false);
                window.location.reload();
            }
        } catch {

        }
    }

    const saveEmail = async () => {
        try {
            const response = await fetch("https://bigsnab.kz/api-changeUserEmail", {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ userId: userData.id, email: newEmail })
            });

            const responseJson = await response.json();

            if (responseJson.success) {
                onChangeEmail();
                setIsEditEmail(false);
                window.location.reload();
            }
        } catch {

        }
    }

    const saveSite = async () => {
        try {
            const response = await fetch('https://bigsnab.kz/api-saveSite', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ providerId: userData.providerId, newSite: newSite })
            })

            const responseJson = await response.json();
            
            if(responseJson.success) {
                onChangeSite();
                setIsEditSite(false);
                window.location.reload();
            }

        } catch (error) {
            console.error(error);
        }
    };

    const additionalInfo = [
        providerInfo?.productType1,
        providerInfo?.productType2,
        providerInfo?.productType3,
        providerInfo?.productType4,
        providerInfo?.productType5,
    ].filter(info => info !== null);

    const truncateText = (text, limit) => {
        if (text.length <= limit) {
            return text;
        }
        return text.substring(0, limit) + '...';
    };

    const saveNewPhoto = async () => {    
        const formData = new FormData();
        formData.append('userId', userData.id);
        formData.append('newImage', newImage);
        
        for (let [key, value] of formData.entries()) {
            console.log(`${key}: ${value}`);
        }

        try {
            const response = await fetch('https://bigsnab.kz/api-updatePhoto', {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();

            if (data.success) {
                alert("Фотография успешно обновлена");
                window.location.reload();
            }
        } catch {

        }
    }

    return (
        <>
            { isLoading && (
                <div className="w-screen h-screen flex justify-center items-center bg-[#438501]">
                    <ReactLoading type="spin" color="#FFF" height={25} width={25} />
                </div>
            ) }
            { !isLoading && (
                <div>
                    <Navbar/>
                    <div className="w-full h-full justify-center flex flex-col items-center mt-24">
                        <p className="mobile:text-md laptop:text-md font-semibold text-[#292B2F]">Профиль</p>
                        <div className="flex flex-col space-y-0.5 mt-2 justify-center items-center">
                            <div className="group w-24 h-24 relative flex justify-center items-center">
                                { newImage ? (
                                    <>
                                        <img src={URL.createObjectURL(newImage)} alt="" className="object-cover w-full h-full rounded-xl"/>
                                        <div className="absolute z-10 flex flex-row items-center justify-between w-full bottom-0">
                                            <button onClick={() => setNewImage(null)} className="-left-2 -bottom-2 absolute hover:opacity-50 rounded-full h-8 w-8 border-2 border-white flex justify-center items-center bg-[#438501]">
                                                <IoCloseOutline className="text-md text-white"/>
                                            </button>
                                            <button onClick={() => saveNewPhoto()} className="-right-2 -bottom-2 absolute hover:opacity-50 rounded-full h-8 w-8 border-2 border-white flex justify-center items-center bg-[#438501]">
                                                <IoMdCheckmark className="text-md text-white"/>
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <img src={`https://bigsnab.kz/api-userImage/${userData?.photo}`} alt="" className="object-cover w-full h-full rounded-xl"/>
                                        <button onClick={() => fileInput.current.click()} className="hover:opacity-50 absolute z-10 rounded-full h-8 w-8 border-2 border-white flex justify-center items-center bg-[#438501]">
                                            <MdOutlineAddPhotoAlternate className="text-md text-white"/>
                                        </button>
                                    </>
                                ) }
                                <input 
                                    type='file'
                                    id={`newImage`}
                                    name={`newImage`}
                                    accept="image/*" 
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        setNewImage(file);
                                    }} 
                                    style={{ display: "none" }} 
                                    ref={fileInput}
                                />
                            </div>
                            <p className="text-md text-[#292B2F]">{userData?.fullname} {userData?.surname}</p>
                            <p className="mobile:text-base laptop:text-md text-[#91949E]">{userData?.typeOfAccount === 'Client' ? 'Заказчик' : 'Поставщик'}</p>
                        </div>
                        { isAddMessagers ? (
                            <>
                                { !userData?.telegram && (
                                    <div className="border-b-[1px] border-b-[#91949E] mobile:w-64 tablet:w-80 py-2">
                                        <div className="flex flex-row space-x-2 items-center">
                                            <FaTelegram className="mobile:text-base laptop:text-md"/>
                                            <p className="mobile:text-base laptop:text-md text-[#91949E]">Telegram</p>
                                        </div>
                                        <input value={messagers.telegram} name="telegram" onChange={handleChangeMessagers} className="text-md text-[#292B2F] outline-none w-full bg-transparent" placeholder="Введите Telegram"/>
                                    </div>
                                ) }
                                { !userData?.whatsapp && (
                                    <div className="border-b-[1px] border-b-[#91949E] mobile:w-64 tablet:w-80 py-2">
                                        <div className="flex flex-row space-x-2 items-center">
                                            <FaWhatsapp className="mobile:text-base laptop:text-md"/>
                                            <p className="mobile:text-base laptop:text-md text-[#91949E]">WhatsApp</p>
                                        </div>
                                        <input value={messagers.whatsapp} name="whatsapp" onChange={handleChangeMessagers} className="text-md text-[#292B2F] outline-none w-full bg-transparent" placeholder="Введите WhatsApp"/>
                                    </div>
                                ) }
                                { !userData?.viber && (
                                    <div className="border-b-[1px] border-b-[#91949E] mobile:w-64 tablet:w-80 py-2">
                                        <div className="flex flex-row space-x-2 items-center">
                                            <FaViber className="mobile:text-base laptop:text-md"/>
                                            <p className="mobile:text-base laptop:text-md text-[#737680]">Viber</p>
                                        </div>
                                        <input value={messagers.viber} name="viber" onChange={handleChangeMessagers} className="text-md text-[#292B2F] outline-none w-full bg-transparent" placeholder="Введите Viber"/>
                                    </div>
                                ) }
                                <button onClick={handleSubmitMessagers} disabled={!messagers.telegram && !messagers.whatsapp && !messagers.viber} className={ !messagers.telegram && !messagers.whatsapp && !messagers.viber ? "p-3 mobile:w-64 tablet:w-80 bg-[#438501] rounded-xl mt-4 opacity-50" : "p-3 mobile:w-64 tablet:w-80 bg-[#438501] rounded-xl mt-4 hover:opacity-50" }>
                                    <p className="text-md text-white">Сохранить</p>
                                </button>
                            </>
                        ) : (
                            <>
                                { userData?.telegram && (
                                    <div className="border-b-[1px] border-b-[#91949E] mobile:w-64 tablet:w-80 py-2">
                                        <div className="flex flex-row space-x-2 items-center">
                                            <FaTelegram className="mobile:text-base laptop:text-md"/>
                                            <p className="mobile:text-base laptop:text-md text-[#91949E]">Telegram</p>
                                        </div>
                                        <div className="w-full flex flex-row justify-between items-center">
                                            { isEditTelegram ? (
                                                <input value={newTelegram} onChange={(e) => onChangeTelegram(e.target.value)} className="text-md outline-none bg-transparent" placeholder={userData?.telegram}/>
                                            ) : (
                                                <p className="text-md text-[#292B2F]">{userData?.telegram}</p>
                                            ) }
                                            { isEditTelegram ? (
                                                <>
                                                    { newTelegram && (
                                                        <div className="flex flex-row items-center space-x-2">
                                                            <button onClick={() => (onChangeTelegram(), setIsEditTelegram(false))} className="hover:opacity-50">
                                                                <IoCloseOutline className="mobile:text-md laptop:text-md"/>
                                                            </button>
                                                            <button onClick={() => saveTelegram()} className="hover:opacity-50">
                                                                <FaRegSave className="mobile:text-md laptop:text-md"/>
                                                            </button>
                                                        </div>
                                                    ) }
                                                </>
                                            ) : (
                                                <button onClick={() => setIsEditTelegram(true)} className="hover:opacity-50">
                                                    <FiEdit className="mobile:text-md laptop:text-md text-black/40"/>
                                                </button>
                                            ) }
                                        </div>
                                    </div>
                                ) }
                                { userData?.whatsapp && (
                                    <div className="border-b-[1px] border-b-[#91949E] mobile:w-64 tablet:w-80 py-2">
                                        <div className="flex flex-row space-x-2 items-center">
                                            <FaWhatsapp className="mobile:text-base laptop:text-md"/>
                                            <p className="mobile:text-base laptop:text-md text-[#91949E]">WhatsApp</p>
                                        </div>
                                        <div className="w-full flex flex-row justify-between items-center">
                                            { isEditWhatsApp ? (
                                                <input value={newWhatsApp} onChange={(e) => onChangeWhatsApp(e.target.value)} className="text-md outline-none bg-transparent" placeholder={userData?.whatsapp}/>
                                            ) : (
                                                <p className="text-md text-[#292B2F]">{userData?.whatsapp}</p>
                                            ) }
                                            { isEditWhatsApp ? (
                                                <>
                                                    { newWhatsApp && (
                                                        <div className="flex flex-row items-center space-x-2">
                                                            <button onClick={() => (onChangeWhatsApp(), setIsEditWhatsApp(false))} className="hover:opacity-50">
                                                                <IoCloseOutline className="mobile:text-md laptop:text-md"/>
                                                            </button>
                                                            <button onClick={() => saveWhatsApp()} className="hover:opacity-50">
                                                                <FaRegSave className="mobile:text-md laptop:text-md"/>
                                                            </button>
                                                        </div>
                                                    ) }
                                                </>
                                            ) : (
                                                <button onClick={() => setIsEditWhatsApp(true)} className="hover:opacity-50">
                                                    <FiEdit className="mobile:text-md laptop:text-md text-black/40"/>
                                                </button>
                                            ) }
                                        </div>
                                    </div>
                                ) }
                                { userData?.viber && (
                                    <div className="border-b-[1px] border-b-[#91949E] mobile:w-64 tablet:w-80 py-2">
                                        <div className="flex flex-row space-x-2 items-center">
                                            <FaViber className="mobile:text-base laptop:text-md"/>
                                            <p className="mobile:text-base laptop:text-md text-[#91949E]">Viber</p>
                                        </div>
                                        <div className="w-full flex flex-row justify-between items-center">
                                            { isEditViber ? (
                                                <input value={newViber} onChange={(e) => onChangeViber(e.target.value)} className="text-md outline-none bg-transparent" placeholder={userData?.viber}/>
                                            ) : (
                                                <p className="text-md text-[#292B2F]">{userData?.viber}</p>
                                            ) }
                                            { isEditViber ? (
                                                <>
                                                    { newViber && (
                                                        <div className="flex flex-row items-center space-x-2">
                                                            <button onClick={() => (onChangeViber(), setIsEditViber(false))} className="hover:opacity-50">
                                                                <IoCloseOutline className="mobile:text-md laptop:text-md"/>
                                                            </button>
                                                            <button onClick={() => saveViber()} className="hover:opacity-50">
                                                                <FaRegSave className="mobile:text-md laptop:text-md"/>
                                                            </button>
                                                        </div>
                                                    ) }
                                                </>
                                            ) : (
                                                <button onClick={() => setIsEditViber(true)} className="hover:opacity-50">
                                                    <FiEdit className="mobile:text-md laptop:text-md text-black/40"/>
                                                </button>
                                            ) }
                                        </div>
                                    </div>
                                ) }
                                { userData?.login && (
                                    <div className="border-b-[1px] border-b-[#91949E] mobile:w-64 tablet:w-80 py-2">
                                        <div className="flex flex-row space-x-2 items-center">
                                            <FaUser className="mobile:text-base laptop:text-md"/>
                                            <p className="mobile:text-base laptop:text-md text-[#91949E]">Логин</p>
                                        </div>
                                        <div className="w-full flex flex-row justify-between items-center">
                                            { isEditLogin ? (
                                                <input value={newLogin} onChange={(e) => onChangeLogin(e.target.value)} className="w-full mobile:text-base laptop:text-md outline-none bg-transparent" placeholder={userData?.login}/>
                                            ) : (
                                                <p className="mobile:text-base laptop:text-md text-[#292B2F]">{truncateText(userData?.login, 24)}</p>
                                            ) }
                                            { isEditLogin ? (
                                                <>
                                                    { newLogin && (
                                                        <div className="flex flex-row items-center space-x-2">
                                                            <button onClick={() => (onChangeLogin(), setIsEditLogin(false))} className="hover:opacity-50">
                                                                <IoCloseOutline className="mobile:text-md laptop:text-md"/>
                                                            </button>
                                                            <button onClick={() => saveLogin()} className="hover:opacity-50">
                                                                <FaRegSave className="mobile:text-md laptop:text-md"/>
                                                            </button>
                                                        </div>
                                                    ) }
                                                </>
                                            ) : (
                                                <button onClick={() => setIsEditLogin(true)} className="hover:opacity-50">
                                                    <FiEdit className="mobile:text-md laptop:text-md text-black/40"/>
                                                </button>
                                            ) }
                                        </div>
                                    </div>
                                ) }
                                { userData?.email && (
                                    <div className="border-b-[1px] border-b-[#91949E] mobile:w-64 tablet:w-80 py-2">
                                        <div className="flex flex-row space-x-2 items-center">
                                            <IoMdMail className="mobile:text-base laptop:text-md"/>
                                            <p className="mobile:text-base laptop:text-md text-[#91949E]">Email</p>
                                        </div>
                                        <div className="w-full flex flex-row justify-between items-center">
                                            { isEditEmail ? (
                                                <input value={newEmail} onChange={(e) => onChangeEmail(e.target.value)} className="mobile:text-base laptop:text-md outline-none bg-transparent w-full" placeholder={userData?.email}/>
                                            ) : (
                                                <p className="mo laptop:text-md text-[#292B2F]">{truncateText(userData?.email, 24)}</p>
                                            ) }
                                            { isEditEmail ? (
                                                <>
                                                    { newEmail && (
                                                        <div className="flex flex-row items-center space-x-2">
                                                            <button onClick={() => (onChangeEmail(), setIsEditEmail(false))} className="hover:opacity-50">
                                                                <IoCloseOutline className="mobile:text-md laptop:text-md"/>
                                                            </button>
                                                            <button onClick={() => saveEmail()} className="hover:opacity-50">
                                                                <FaRegSave className="mobile:text-md laptop:text-md"/>
                                                            </button>
                                                        </div>
                                                    ) }
                                                </>
                                            ) : (
                                                <button onClick={() => setIsEditEmail(true)} className="hover:opacity-50">
                                                    <FiEdit className="mobile:text-md laptop:text-md text-black/40"/>
                                                </button>
                                            ) }
                                        </div>
                                    </div>
                                ) }
                                <div className="border-b-[1px] border-b-[#91949E] mobile:w-64 tablet:w-80 py-2">
                                    <div className="flex flex-row space-x-2 items-center">
                                        <FaCity className="mobile:text-base laptop:text-md"/>
                                        <p className="mobile:text-base laptop:text-md text-[#91949E]">Город</p>
                                    </div>
                                    <p className="text-md text-[#292B2F]">{userData?.city}</p>
                                </div>
                                { userData?.typeOfAccount === 'Provider' && (
                                    <>
                                        <div className="border-b-[1px] border-b-[#91949E] mobile:w-64 tablet:w-80 py-2">
                                            <div className="flex flex-row space-x-2 items-center">
                                                { providerInfo?.occupation === 'Товары' ? (
                                                    <FaBoxes className="mobile:text-base laptop:text-md"/>
                                                ) : (
                                                    <FaTools className="mobile:text-base laptop:text-md"/>
                                                ) }
                                                <p className="mobile:text-base laptop:text-md text-[#91949E]">{providerInfo?.occupation}</p>
                                            </div>
                                            <div className="flex flex-row flex-wrap">
                                                { additionalInfo.map((item, index) => (
                                                    <div key={index} className="px-4 py-1.5 m-1 rounded-full bg-[#438501] w-fit">
                                                        <p className="mobile:text-xs laptop:text-md text-white ">{item}</p>
                                                    </div>
                                                )) }
                                            </div>
                                        </div>
                                        <div className="border-b-[1px] border-b-[#91949E] mobile:w-64 tablet:w-80 py-2">
                                            <div className="flex flex-row space-x-2 items-center">
                                                <FaSitemap className="mobile:text-base laptop:text-md"/>
                                                <p className="mobile:text-base laptop:text-md text-[#91949E]">Название организации</p>
                                            </div>
                                            <p className="mobile:text-base laptop:text-md text-[#292B2F]">{providerInfo?.company}</p>
                                        </div>
                                        <div className="border-b-[1px] border-b-[#91949E] mobile:w-64 tablet:w-80 py-2">
                                            <div className="flex flex-row space-x-2 items-center">
                                                <ImUserTie className="mobile:text-base laptop:text-md"/>
                                                <p className="mobile:text-base laptop:text-md text-[#91949E]">Юр.лицо</p>
                                            </div>
                                            <p className="mobile:text-base laptop:text-md text-[#292B2F]">{providerInfo?.entity}</p>
                                        </div>
                                        <div className="border-b-[1px] border-b-[#91949E] mobile:w-64 tablet:w-80 py-2">
                                            <div className="flex flex-row space-x-2 items-center">
                                                <AiOutlineDollarCircle className="mobile:text-base laptop:text-md"/>
                                                <p className="mobile:text-base laptop:text-md text-[#91949E]">Подписка</p>
                                            </div>
                                            <p className="mobile:text-base laptop:text-md text-[#292B2F]">{userData?.status === 'trial' ? 'Пробная подписка' : userData?.subscription}</p>
                                        </div>
                                        <div className="border-b-[1px] border-b-[#91949E] mobile:w-64 tablet:w-80 py-2">
                                            <div className="flex flex-row space-x-2 items-center">
                                                <IoTimeOutline className="mobile:text-base laptop:text-md"/>
                                                <p className="mobile:text-base laptop:text-md text-[#91949E]">Дата истечения подписки</p>
                                            </div>
                                            <p className="mobile:text-base laptop:text-md text-[#292B2F]">{userData?.status === 'trial' ? new Date(userData?.trialEndDate).toLocaleDateString() : new Date(userData?.subscriptionPeriod).toLocaleDateString()}</p>
                                        </div>
                                        <div className="border-b-[1px] border-b-[#91949E] mobile:w-64 tablet:w-80 py-2">
                                            <div className="flex flex-row space-x-2 items-center">
                                                <FaStamp className="mobile:text-base laptop:text-md"/>
                                                <p className="mobile:text-base laptop:text-md text-[#91949E]">БИН</p>
                                            </div>
                                            <p className="mobile:text-base laptop:text-md text-[#292B2F]">{providerInfo?.bin}</p>
                                        </div>
                                        <div className="border-b-[1px] border-b-[#91949E] mobile:w-64 tablet:w-80 py-2">
                                        <div className="flex flex-row space-x-2 items-center">
                                            <FaGlobe className="mobile:text-base laptop:text-md"/>
                                            <p className="mobile:text-base laptop:text-md text-[#91949E]">Сайт</p>
                                        </div>
                                        <div className="w-full flex flex-row justify-between items-center mb-12">
                                            { isEditSite ? (
                                                <input value={newSite} onChange={(e) => onChangeSite(e.target.value)} className="mobile:text-base laptop:text-md outline-none bg-transparent" placeholder={providerInfo?.site ? providerInfo?.site : 'Введите ваш сайт'}/>
                                            ) : (
                                                <p className="mobile:text-base laptop:text-md text-[#292B2F]">{providerInfo?.site ? providerInfo?.site : '(Добавить сайт)' }</p>
                                            ) }
                                            { isEditSite ? (
                                                <>
                                                    { newSite && (
                                                        <div className="flex flex-row items-center space-x-2">
                                                            <button onClick={() => (onChangeSite(), setIsEditSite(false))} className="hover:opacity-50">
                                                                <IoCloseOutline className="mobile:text-md laptop:text-md"/>
                                                            </button>
                                                            <button onClick={() => saveSite()} className="hover:opacity-50">
                                                                <FaRegSave className="mobile:text-md laptop:text-md"/>
                                                            </button>
                                                        </div>
                                                    ) }
                                                </>
                                            ) : (
                                                <button onClick={() => setIsEditSite(true)} className="hover:opacity-50">
                                                    <FiEdit className="mobile:text-md laptop:text-md text-black/40"/>
                                                </button>
                                            ) }
                                        </div>
                                        
                                    </div>
                                    </>
                                ) }
                                { userData?.typeOfAccount === 'Client' && (
                                    <>
                                        { !userData?.telegram && !userData?.whatsapp && !userData.viber && (
                                            <button onClick={() => setIsAddMessagers(true)} className="p-3 mobile:w-64 tablet:w-80 bg-[#91949E] rounded-xl mt-6 hover:opacity-50">
                                                <p className="text-md text-white">Добавить мессенджеры</p>
                                            </button>
                                        ) }
                                        <button className="p-3 mobile:w-64 tablet:w-80 bg-[#438501] rounded-xl mt-4 hover:opacity-50 mb-12">
                                            <p className="text-md text-white">Стать поставщиком</p>
                                        </button>
                                    </>
                                ) }
                            </>
                        ) }
                    </div>
                </div>
            )}
        </>
    )
};

export default Profile;