import { useEffect, useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { IoMdAdd, IoMdCheckmark, IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import productsData from '../../json/products.json';
import servicesData from '../../json/services.json';
import { useNavigate } from "react-router-dom";
import Navbar from "../ux/Navbar";

const getCategoryList = (orderType) => {
    // Проверяем тип заказа и возвращаем соответствующий список категорий
    if (orderType === 'Товары') {
        return productsData.products.map(item => item.category);
    } else if (orderType === 'Услуги') {
        return servicesData.services.map(item => item.category);
    } else {
        return []; // Возвращаем пустой массив в случае некорректного значения orderType
    }
};

const getSubcategoryList = (orderType, filter) => {
    let subcategoryList = [];

    // Проверяем тип заказа и возвращаем соответствующий список подкатегорий
    if (orderType === 'Товары') {
        subcategoryList = productsData.products.find(item => item.category === filter)?.subcategory || [];
    } else if (orderType === 'Услуги') {
        subcategoryList = servicesData.services.find(item => item.category === filter)?.subcategory || [];
    }

    return subcategoryList;
};

function BecomeProvider() {
    const [step, setStep] = useState(1);
    const [data, setData] = useState({
        company: "",
        entity: "",
        bin: "",
        occupation: "",
        city: "",
        category: "",
    });
    const [typesOfCargo, setTypesOfCargo] = useState([]);
    const [isOpenCategory, setIsOpenCategory] = useState(false);
    const [isOpenSubcategory, setIsOpenSubCategory] = useState(false);
    const categories = getCategoryList(data.occupation);
    const subcategories = getSubcategoryList(data.occupation, data.category);
    const [isOpenEntity, setIsOpenEntity] = useState(false);
    const [message, setMessage] = useState();
    const navigate = useNavigate();
    const [userData, setUserData] = useState({});

    useEffect(() => {
        fetchUserInfo();
    }, [userData]);

    const fetchUserInfo = async () => {
        const authToken = document.cookie.replace(/(?:(?:^|.*;\s*)auth_token\s*=\s*([^;]*).*$)|^.*$/, "$1");

        try {
            const response = await fetch(`https://bigsnab.kz/api-getUserInfo`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}` // Устанавливаем токен в заголовке Authorization
                }
            });

            const data = await response.json();

            if (data.success) {
                setUserData(data.user);
            } else {
                // Обработка ошибки, если не удалось получить информацию о пользователе
                console.error("Failed to fetch user data:", data.message);
            }
        } catch (error) {
            // Обработка ошибки запроса
            console.error("Error fetching user data:", error);
        }
    };

    const selectTypesOfCargo = (cargo) => {
        // Проверяем, есть ли тип груза в массиве
        const cargoIndex = typesOfCargo.indexOf(cargo);

        if (cargoIndex !== -1) {
            // Если тип груза уже присутствует, удаляем его
            const updatedTypes = [...typesOfCargo];
            updatedTypes.splice(cargoIndex, 1);
            setTypesOfCargo(updatedTypes);
        } else {
            // Если тип груза отсутствует, добавляем его
            setTypesOfCargo((prevTypes) => [...prevTypes, cargo]);
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
    }; 
    
    const selectCategory = (category) => {
        setData({
            ...data,
            category: category
        });
        setIsOpenCategory(false);
    }

    const selectEntity = (entity) => {
        setData({
            ...data,
            entity: entity
        });
        setIsOpenEntity(false);
    }

    const regProvider = async () => {
        if (data.confirmPassword === data.password) {
            setMessage();        
            try {
                const response = await fetch('https://bigsnab.kz/api-becomeProviderSite', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        userId: userData.id,
                        company: data.company,
                        productType1: typesOfCargo[0] || null,
                        productType2: typesOfCargo[1] || null,
                        productType3: typesOfCargo[2] || null,
                        productType4: typesOfCargo[3] || null,
                        productType5: typesOfCargo[4] || null,
                        entity: data.entity,
                        bin: data.bin,
                        occupation: data.occupation,
                        category: data.category,
                    })
                });
    
                if (response.ok) {
                    setMessage();
                    const responseJson = await response.json();

                    if (responseJson.success) {
                        navigate('/')
                        window.location.reload();
                    }

                } else {
                    console.error('Registration failed:', response.statusText);
                }
            } catch (error) {
                console.error('Ошибка при отправке запроса:', error);
            }
        } else {
            setMessage("Пароли не совпадают");
        }
    };

    return (
        <div className="bg-white">
            <Navbar/>
            <div className="h-screen w-screen justify-center items-center flex flex-col">
                <div className="bg-white mobile:w-10/12 tablet:w-8/12 laptop:w-5/12 desktop:w-4/12 desktop2:w-3/12 shadow-xl rounded-tl-[50px] rounded-br-[50px]">
                    <div className="bg-[#438501] rounded-tl-[50px] rounded-br-[50px] px-12 py-6">
                        { step === 1 && (
                            <>
                                <p className="text-lg text-white text-center font-semibold">Чем вы занимаетесь?</p>
                                <p className="text-lg text-white text-center mt-1">Определите свою нишу</p>
                                <div className="flex flex-row space-x-12 justify-center mt-6">
                                    <button onClick={() => setData({ ...data, occupation: "Услуги" })} className={ data.occupation === 'Услуги' ? "flex flex-col space-y-2 justify-center items-center opacity-50" : "flex flex-col space-y-2 justify-center items-center hover:opacity-50" }>
                                        <div className="bg-white rounded-full p-6">
                                            <img className="w-12 h-12" src={require("../../images/icons/servicesIcon.png")}/>
                                        </div>
                                        <p className="text-lg text-white font-semibold">Услуги</p>
                                    </button>
                                    <button onClick={() => setData({ ...data, occupation: "Товары" })} className={ data.occupation === 'Товары' ? "flex flex-col space-y-2 justify-center items-center opacity-50" : "flex flex-col space-y-2 justify-center items-center hover:opacity-50" }>
                                        <div className="bg-white rounded-full p-6">
                                            <img className="w-12 h-12" src={require("../../images/icons/productsIcon.png")}/>
                                        </div>
                                        <p className="text-lg text-white font-semibold">Товары</p>
                                    </button>
                                </div>
                            </>
                        ) }
                        { step === 2 && (
                            <>
                                <p className="text-lg text-white text-center font-semibold">Создание аккаунт поставщика</p>
                                <div className="mt-6">
                                    <div className="w-full bg-white p-4 rounded-xl mt-4">
                                        <input name="company" value={data.company} onChange={handleChange} className="bg-transparent outline-none text-lg w-full" placeholder="Название организации"/>
                                    </div>
                                    <div className="mt-4 relative">
                                        <div className={ isOpenCategory ? "w-full bg-white p-4 rounded-t-xl rounded-b-none flex flex-row justify-between items-center" : "w-full bg-white p-4 rounded-xl flex flex-row justify-between items-center" }>
                                            <p className="text-lg opacity-40">{ data.category ? data.category : `Категория ${ data.occupation === 'Товары' ? 'товаров' : 'услуг' }` }</p>
                                            <button onClick={() => setIsOpenCategory(!isOpenCategory)}>
                                                { isOpenCategory ? <IoIosArrowUp className="text-lg text-black/40"/> : <IoIosArrowDown className="text-lg text-black/40"/> }
                                            </button>
                                        </div>
                                        { isOpenCategory && (
                                            <div className="bg-white rounded-b-xl p-4 absolute z-10 w-full h-32 overflow-y-auto shadow-2xl flex flex-col items-start justify-start">
                                                { categories.map((item, index) => (
                                                    <button key={index} onClick={() => selectCategory(item)} className="hover:opacity-50 py-1">
                                                        <p className="text-lg text-left">{item}</p>
                                                    </button>
                                                )) }
                                            </div>
                                        ) }
                                    </div>
                                    <div className="mt-4 relative">
                                        <div className={ isOpenSubcategory ? "w-full bg-white p-4 rounded-t-xl rounded-b-none flex flex-row justify-between items-center" : "w-full bg-white p-4 rounded-xl flex flex-row justify-between items-center" }>
                                            <p className="text-lg opacity-40">{typesOfCargo.length > 0 ? typesOfCargo.join(', ') : `Подкатегория ${data.occupation === 'Товары' ? 'товаров' : 'услуг'}`}</p>
                                            <button onClick={() => setIsOpenSubCategory(!isOpenSubcategory)}>
                                                { isOpenSubcategory ? <IoIosArrowUp className="text-lg text-black/40"/> : <IoIosArrowDown className="text-lg text-black/40"/> }
                                            </button>
                                        </div>
                                        { isOpenSubcategory && (
                                            <div className="bg-white rounded-b-xl p-4 absolute z-10 w-full h-32 overflow-y-auto shadow-2xl flex flex-col items-start">
                                                { subcategories.length > 0 ? (
                                                    <>
                                                        { subcategories.map((item, index) => (
                                                            <button key={index} onClick={() => selectTypesOfCargo(item)} className="hover:opacity-50 w-full py-1 flex flex-row items-center justify-between">
                                                                <p className="text-lg text-left">{item}</p>
                                                                { typesOfCargo.includes(item) ? (
                                                                    <IoMdCheckmark className="text-lg text-[#5BB600]"/>
                                                                ) : (
                                                                    <IoMdAdd className="text-lg text-black/40"/>
                                                                ) }
                                                            </button>
                                                        )) }
                                                    </>
                                                ) : (
                                                    <div className="flex justify-center items-center w-full h-full">
                                                        <p className="text-lg text-black text-center">Сначала выберите категорию</p>
                                                    </div>
                                                ) }
                                            </div>
                                        ) }
                                    </div>
                                    <div className="mt-4 relative">
                                        <div className={ isOpenEntity ? "w-full bg-white p-4 rounded-t-xl rounded-b-none flex flex-row justify-between items-center" : "w-full bg-white p-4 rounded-xl flex flex-row justify-between items-center" }>
                                            <p className="text-lg opacity-40">{ data.entity ? data.entity : 'Юр.лицо' }</p>
                                            <button onClick={() => setIsOpenEntity(!isOpenEntity)}>
                                                { isOpenEntity ? <IoIosArrowUp className="text-lg text-black/40"/> : <IoIosArrowDown className="text-lg text-black/40"/> }
                                            </button>
                                        </div>
                                        { isOpenEntity && (
                                            <div className="bg-white rounded-b-xl p-4 absolute z-10 w-full shadow-2xl flex flex-col items-start">
                                                <button onClick={() => selectEntity("Юр.лицо")} className="hover:opacity-50">
                                                    <p className="text-lg text-black py-1">Юр.лицо</p>
                                                </button>
                                                <button onClick={() => selectEntity("Физ.лицо")} className="hover:opacity-50">
                                                    <p className="text-lg text-black py-1">Физ.лицо</p>
                                                </button>
                                            </div>
                                        ) }
                                    </div>
                                    <div className="w-full bg-white p-4 rounded-xl flex flex-row justify-between items-center mt-4">
                                        <input name="bin" value={data.bin} onChange={handleChange} className="bg-transparent outline-none text-lg w-full" placeholder={ data.entity === 'Юр.лицо' ? "БИН" : "ИИН" }/>
                                    </div>
                                </div>
                            </>
                        ) }
                    </div>
                    <div className="px-12 py-2">
                        { message && <p className="text-lg text-red-500 text-center">{message}</p> }
                        { step === 1 && (
                            <>
                                <button disabled={!data.occupation} onClick={() => setStep(2)} className={ !data.occupation ? "bg-[#438501] opacity-50 p-4 w-full rounded-xl mt-4" : "bg-[#438501] hover:opacity-50 p-4 w-full rounded-xl mt-4" }>
                                    <p className="text-lg text-white">Далее</p>
                                </button>
                            </>
                        )}
                        { step === 2 && (
                            <>
                                <button disabled={!data.company || !data.category || !subcategories || !data.entity || !data.bin} onClick={regProvider} className={ !data.company || !data.category || !subcategories.length > 0 || !data.entity || !data.bin ? "bg-[#438501] opacity-50 p-4 w-full rounded-xl mt-4" : "bg-[#438501] hover:opacity-50 p-4 w-full rounded-xl mt-4"}>
                                    <p className="text-lg text-white">Зарегистрироваться</p>
                                </button>
                            </>
                        )}
                        <p className="text-md text-center py-2 text-[#91949E]">Есть аккаунт? <button onClick={() => navigate('/auth')} className="hover:opacity-50"><p className="text-[#5BB600]">Войдите</p></button></p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default BecomeProvider;