import { Link, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../ux/Navbar";
import { IoIosArrowBack } from "react-icons/io";
import { useState, useEffect } from "react";
import ReactLoading from 'react-loading';
import WriteReview from "../ux/WriteReview";
import FeedbackCard from "../ux/FeedbackCard";

function FeedbacksPage() {
    const location = useLocation();
    console.log("state feedbacks: ", location.state);
    const [isLoading, setIsLoading] = useState(true);
    const [userData, setUserData] = useState(null);
    const [feedbacks, setFeedbacks] = useState([]);
    const navigatate = useNavigate();
    const [isOpenWriteReview, setIsOpenWriteReview] = useState();
    const [selectedProviderId, setSelectedProviderId] = useState();



    useEffect(() => {
        fetchUserInfo();
    }, []);

    const fetchUserInfo = async () => {
        const authToken = document.cookie.replace(/(?:(?:^|.*;\s*)auth_token\s*=\s*([^;]*).*$)|^.*$/, "$1");

        try {
            const response = await fetch(`https://bigsnab.kz/api-getUserInfo`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}` // Устанавливаем токен в заголовке Authorization
                }
            });

            const data = await response.json();

            if (data.success) {
                setUserData(data.user);
                fetchFeedbacks(data.user.id);
            } else {
                // Обработка ошибки, если не удалось получить информацию о пользователе
                console.error("Failed to fetch user data:", data.message);
            }
        } catch (error) {
            // Обработка ошибки запроса
            console.error("Error fetching user data:", error);
        } finally {
            setIsLoading(false); // Устанавливаем isLoading в false после завершения загрузки
        }
    };

    const fetchFeedbacks = async (userId) => {
        const response = await fetch(`https://bigsnab.kz/api-getFeedbacks/${userId}/${location.state.id}`);

        const responseJson = await response.json();

        if (responseJson.success) {
            setFeedbacks(responseJson.feedbacks);
            console.log(responseJson.feedbacks);
        }
    }

    const acceptProvider = async (providerId, orderId) => {
        console.log("providerId", providerId);
        console.log("orderId", orderId);
        try {
            const response = await fetch('https://bigsnab.kz/api-acceptOrder', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ providerId: providerId, orderId: orderId })
            });

            const responseJson = await response.json();

            if (responseJson.success) {
                window.location.reload();
            }

        } catch {

        }
    }

    const openWriteReview = (providerId) => {
        setSelectedProviderId(providerId);
        setIsOpenWriteReview(true)
    }

    return (
        <>
            { isLoading && (
                <div className="w-screen h-screen flex justify-center items-center bg-[#438501]">
                    <ReactLoading type="spin" color="#FFF" height={25} width={25} />
                </div>
            ) }
            { !isLoading && (
                <div className="min-h-screen flex flex-col items-center">
                    <Navbar />
                    <div className="w-full flex flex-col items-center">
                        <div className="flex flex-row items-center space-x-2 mt-20">
                            <Link to='/' className="w-7 h-7 flex justify-center items-center rounded-full bg-[#91949E] hover:opacity-50">
                                <IoIosArrowBack className="text-lg text-white"/>
                            </Link>
                            <p className="text-lg font-semibold text-[#292B2F] text-center">Отклики на заказ "{location.state.title}"</p>
                        </div>
                        <div className="flex flex-col p-2 overflow-x-auto overflow-y-auto h-[80vh]">
                            { feedbacks.length > 0 ? (
                                <>
                                    { feedbacks.map((item, index) => (
                                        <FeedbackCard key={index} selectProviderId={setSelectedProviderId} item={item} openWriteReview={() => setIsOpenWriteReview(true)} userId={userData.id}/>
                                    )) }
                                </>
                            ) : (
                                <div className="h-[70vh] flex justify-center items-center">
                                    <p className="text-lg">Пока на ваш заказ никто не дал отклик</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) }
            { isOpenWriteReview && <WriteReview providerId={selectedProviderId} onClose={() => setIsOpenWriteReview(false)}/> }
        </>
    )
};

export default FeedbacksPage