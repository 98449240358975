import React from 'react';
import { Link, useLocation, useNavigate  } from 'react-router-dom';
import Navbar from "../ux/Navbar";
import OrderHistory from '../ux/Bussines/OrderHistory';
import AdminPanel from '../ux/Bussines/AdminPanel';

function DownloadPage() {
    return (
        <div className="min-h-screen flex flex-col items-center">
            <Navbar />
            <div className="w-full flex h-screen flex-col items-center justify-center">
                <div className='laptop:w-10/12 desktop:w-10/12 desktop2:w-8/12 h-5/6 bg-[#438501] rounded-tl-[200px] rounded-br-[200px] flex flex-row mobile:p-16 tablet:p-24'>
                    <div>
                        <p className='text-white font-medium mobile:text-md laptop:text-lg desktop:text-lg desktop2:text-4xl mobile:w-full tablet:w-10/12'>Покупка и Продажа Строительных Материалов в Одном Приложении</p>
                        <p className='mobile:text-md laptop:text-md desktop:text-lg desktop2:text-lg text-white mobile:w-full tablet:w-10/12 mt-6'>Скачайте BigSnab для удобного управления покупками и продажами строительных материалов. Оптимизируйте свои расходы и улучшите процессы, присоединившись к сообществу профессионалов. Улучшите свой бизнес уже сегодня!</p>
                        <a href="https://bigsnab.kz/api-downloadApk/BigSnab.apk" download>
                            <button className='bg-[#FFF] rounded-xl mobile:w-64 tablet:w-64 desktop:w-96 hover:opacity-50 p-3 mt-6'>
                                <p className='text-lg text-[#5BB600]'>Скачать APK</p>
                            </button>
                        </a>
                        <div className='rounded-xl bg-[#FFF] p-3 mobile:w-64 tablet:w-64 desktop:w-96 mt-4 flex flex-row items-center space-x-2'>
                            <img src={require("../../images/qr.png")} className='mobile:h-16 tablet:w-24 mobile:w-16 tablet:h-24 rounded-xl'/>
                            <p className='mobile:text-xs desktop:text-md mobile:w-32 desktop:w-64'>Отсканируйте QR-код камерой смартфона, чтобы скачать приложение</p>
                        </div>
                    </div>
                    <img className='mobile:hidden tablet:flex' src={require("../../images/mockup.png")}/>
                </div>
            </div>
        </div>
    );
}

export default DownloadPage;
