import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import MainApp from './MainApp';
import './CusstomScrollBar.css';
import EndTrialModal from './components/ux/EndTrialModal';

function App() {
  const [isLogged, setIsLogged] = useState(null);
  const [userData, setUserData] = useState({});
  const [isEndTrial, setIsEndTrial] = useState(false);

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  useEffect(() => {
    const authToken = getCookie("auth_token");
    if (authToken) {
      setIsLogged(true);
      fetchUserInfo(authToken);
    } else {
      setIsLogged(false);
    }
  }, []); // Здесь зависимости пустые, чтобы `useEffect` вызвался только один раз при монтировании компонента

  useEffect(() => {
    if (userData.id) {
      fetchIsEndTrial();
    }
  }, [userData.id]); // Вызываем проверку окончания пробного периода, только когда есть userId

  const fetchUserInfo = async (authToken) => {
    try {
      const response = await fetch(`https://bigsnab.kz/api-getUserInfo`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}` // Устанавливаем токен в заголовке Authorization
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      if (data.success) {
        setUserData(data.user);
        console.log(data.user);
      } else {
        // Обработка ошибки, если не удалось получить информацию о пользователе
        console.error("Failed to fetch user data:", data.message);
      }
    } catch (error) {
      // Обработка ошибки запроса
      console.error("Error fetching user data:", error);
    }
  };

  const fetchIsEndTrial = async () => {
    try {
      const response = await fetch(`https://bigsnab.kz/api-checkTrialLimit/${userData.id}`);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseJson = await response.json();

      if (responseJson.success) {
        console.log("responseJson.isTrialEnd", responseJson.isTrialEnd);
        setIsEndTrial(responseJson.isTrialEnd);
      }
    } catch (error) {
      console.error("fetchIsEndTrial", error);
    }
  };

  if (isLogged === null) {
    return (
      <div className="w-screen h-screen flex justify-center items-center bg-[#438501]">
        <ReactLoading type="spin" color="#FFF" height={25} width={25} />
      </div>
    );
  }

  return (
    <Router>
      <MainApp isLogged={isLogged} userData={userData} fetchUserInfo={fetchUserInfo} />
      {isEndTrial && <EndTrialModal onClose={() => setIsEndTrial(false)}/>}
    </Router>
  );
}

export default App;
