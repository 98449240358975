import { useEffect, useState } from "react";
import { IoIosArrowDown, IoIosArrowForward, IoIosArrowBack, IoIosArrowUp } from "react-icons/io";
import Agreement from "../Agreement";
import WarningModal from "../WarningModal";

function formatPrice(price) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function AdminPanel({ feedbacks, userData, providerInfo }) {
    const [orders, setOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const availableCities = [
        "Алматы", "Астана", "Шымкент", "Актобе", "Караганда",
        "Тараз", "Павлодар", "Семей", "Усть-Каменогорск", "Талдыкорган",
        "Кызылорда", "Атырау", "Петропавловск", "Туркестан", "Темиртау"
    ];
    const [isOpenCityFilter, setIsOpenCityFilter] = useState(false);
    const [cityFilter, setCityFilter] = useState("Алматы");
    const [isHighPriceFilter, setIsHighPriceFilter] = useState(false);
    const [isUrgentFilter, setIsUrgentFilter] = useState(false);
    const [isAllFilter, setIsAllFilter] = useState(false);
    const [minPriceFilter, onChangeMinPriceFilter] = useState();
    const [maxPriceFilter, onChangeMaxPriceFilter] = useState();
    const [isOpenArgement, setIsOpenArgement] = useState(false);
    const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);

    useEffect(() => {
        if (providerInfo) {
            fetchOrders();
        }
    }, [providerInfo]);

    useEffect(() => {
        applyFilters();
    }, [orders, cityFilter, isHighPriceFilter, isUrgentFilter, minPriceFilter, maxPriceFilter, isAllFilter]);

    const applyFilters = () => {
        if (isAllFilter) {
            setFilteredOrders(orders);
            return;
        }
    
        let filtered = [...orders];
    
        // Фильтрация по цене
        if (minPriceFilter) {
            filtered = filtered.filter(order => parseFloat(order.price) >= parseFloat(minPriceFilter));
        }
    
        if (maxPriceFilter) {
            filtered = filtered.filter(order => parseFloat(order.price) <= parseFloat(maxPriceFilter));
        }
    
        // Фильтрация по городу
        if (cityFilter) {
            filtered = filtered.filter(order => order.city === cityFilter);
        }
    
        if (isHighPriceFilter) {
            filtered.sort((a, b) => {
                const priceA = parseInt(a.maxPrice) || parseInt(a.minPrice) || a.price;
                const priceB = parseInt(b.maxPrice) || parseInt(b.minPrice) || b.price;
                return priceB - priceA;
            });
        }
    
        // Сортировка по срочности
        if (isUrgentFilter) {
            filtered.sort((a, b) => new Date(a.executionDate.split('.').reverse().join('-')) - new Date(b.executionDate.split('.').reverse().join('-')));
        }
    
        setFilteredOrders(filtered);
    };
    

    const fetchOrders = async () => {
        try {
            const response = await fetch(`https://bigsnab.kz/api-getAllOrders`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 
                    title: providerInfo.category, 
                    additionalInfo1: providerInfo.productType1, 
                    additionalInfo2: providerInfo.productType2,
                    additionalInfo3: providerInfo.productType3, 
                    additionalInfo4: providerInfo.productType4, 
                    additionalInfo5: providerInfo.productType5,
                    type: providerInfo.occupation
                })
            });
            const responseJson = await response.json();
    
            if (responseJson.success) {
                // Фильтрация заказов, на которые пользователь уже дал отклик
                const updatedOrders = responseJson.orders
                    .filter(order => !feedbacks.some(feedback => feedback.orderId === order.id))
                    .map(order => {
                        const isFeedback = feedbacks.some(feedback => feedback.orderId === order.id);
                        return { ...order, isFeedback };
                    });
                setOrders(updatedOrders);
                setFilteredOrders(updatedOrders);
            }
        } catch (error) {
            console.error("Ошибка при загрузке заказов:", error);
        }
    };
    
    

    const selectCityFilter = (city) => {
        setCityFilter(city);
        setIsOpenCityFilter(false);
        setIsAllFilter(false);
    };

    const handleAllFilter = () => {
        setIsAllFilter(true);
        setIsHighPriceFilter(false);
        setIsUrgentFilter(false);
        setCityFilter("Алматы");
        onChangeMinPriceFilter("");
        onChangeMaxPriceFilter("");
    };

    const handleHighPriceFilter = () => {
        setIsHighPriceFilter(!isHighPriceFilter);
        setIsAllFilter(false);
    };

    const handleUrgentFilter = () => {
        setIsUrgentFilter(!isUrgentFilter);
        setIsAllFilter(false);
    };

    const additionalInfo = [
        selectedOrder?.additionalInfo1,
        selectedOrder?.additionalInfo2,
        selectedOrder?.additionalInfo3,
        selectedOrder?.additionalInfo4,
        selectedOrder?.additionalInfo5,
    ].filter(info => info !== null);

    return (
        <div className="tablet:w-8/12 laptop:w-7/12 desktop:w-5/12">
            { selectedOrder === null && (
                <div className="w-full p-2 flex flex-row justify-between items-center">
                    <div className="flex flex-col">
                        <p className="mobile:text-xs tablet:text-md desktop:text-md text-[#91949E]">Цены, тенге</p>
                        <div className="flex flex-row space-x-4">
                            <div className="px-2 py-1 border-b-[1px] border-b-[#91949E]">
                                <p className="mobile:text-xs laptop:text-md desktop:text-text-base text-[#438501]">От</p>
                                <input value={minPriceFilter} onChange={(e) => onChangeMinPriceFilter(e.target.value)} className="outline-none mobile:text-xs laptop:text-md desktop:text-md bg-transparent mobile:w-6 tablet:w-16 dekstop:w-24" placeholder="0тг"/>
                            </div>
                            <div className="px-2 py-1 border-b-[1px] border-b-[#91949E]">
                                <p className="mobile:text-xs laptop:text-md desktop:text-text-base text-[#438501]">До</p>
                                <input value={maxPriceFilter} onChange={(e) => onChangeMaxPriceFilter(e.target.value)} className="outline-none mobile:text-xs laptop:text-md desktop:text-md bg-transparent mobile:w-6 tablet:w-16 dekstop:w-24" placeholder="0тг"/>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row items-center space-x-3">
                        <button onClick={handleAllFilter} className={ isAllFilter ? "hover:opacity-50 flex flex-row items-center space-x-1" : "hover:opacity-100 opacity-50 flex flex-row items-center space-x-1" }>
                            <p className="mobile:text-xs tablet:text-md desktop:text-md">Все</p>
                        </button>
                        <button onClick={handleHighPriceFilter} className={ isHighPriceFilter ? "hover:opacity-50 flex flex-row items-center space-x-1" : "hover:opacity-100 opacity-50 flex flex-row items-center space-x-1" }>
                            <p className="mobile:text-xs tablet:text-md desktop:text-md">По цене</p>
                            { isHighPriceFilter ? <IoIosArrowUp className="mobile:text-xs tablet:text-md desktop:text-md"/> : <IoIosArrowDown className="mobile:text-xs tablet:text-md desktop:text-md"/> }
                        </button>
                        <button onClick={handleUrgentFilter} className={ isUrgentFilter ? "hover:opacity-50 flex flex-row items-center space-x-1" : "hover:opacity-100 opacity-50 flex flex-row items-center space-x-1" }>
                            <p className="mobile:text-xs tablet:text-md desktop:text-md">Срочные</p>
                            { isUrgentFilter ? <IoIosArrowUp className="mobile:text-xs tablet:text-md desktop:text-md"/> : <IoIosArrowDown className="mobile:text-xs tablet:text-md desktop:text-md"/> }
                        </button>
                        <div className="relative">
                            <button onClick={() => setIsOpenCityFilter(!isOpenCityFilter)} className="hover:opacity-100 opacity-50 flex flex-row items-center space-x-1">
                                <p className="mobile:text-xs tablet:text-md desktop:text-md">{cityFilter}</p>
                                { isOpenCityFilter ? <IoIosArrowUp className="mobile:text-xs tablet:text-md desktop:text-md"/> : <IoIosArrowDown className="mobile:text-xs tablet:text-md desktop:text-md"/> }
                            </button>
                            { isOpenCityFilter && (
                                <div className="absolute z-10 bg-white shadow-xl p-3 flex flex-col">
                                    { availableCities.map((item, index) => (
                                        <button onClick={() => selectCityFilter(item)} key={index} className="hover:opacity-50">
                                            <p className="text-md text-left">{item}</p>
                                        </button>
                                    )) }
                                </div>
                            ) }
                        </div>
                    </div>
                </div>
            ) }
            { selectedOrder !== null ? (
                <div className="flex flex-col justify-center items-center w-full">
                    <div className="w-80">
                        <div className="flex flex-row items-center space-x-2">
                            <button className="hover:opacity-50" onClick={() => setSelectedOrder(null)}>
                                <IoIosArrowBack className="text-md text-[#438501]"/>
                            </button>
                            <p className="text-lg text-[#91949E]">Дата создания: {selectedOrder.date}</p>
                        </div>
                        <div className="border-b-[1px] border-b-[#91949E] py-2 px-2">
                            <p className="text-md text-[#91949E]">Название</p>
                            <p className="text-lg">{selectedOrder.title}</p>
                        </div>
                        <div className="border-b-[1px] border-b-[#91949E] py-2 px-2">
                            <p className="text-md text-[#91949E]">Дата исполнения</p>
                            <p className="text-lg">{selectedOrder.executionDate}</p>
                        </div>
                        <div className="border-b-[1px] border-b-[#91949E] py-2 px-2">
                            <p className="text-md text-[#91949E]">Город</p>
                            <p className="text-lg">{selectedOrder.city}</p>
                        </div>
                        <div className="py-2">
                            <p className="text-md text-[#91949E] px-2">Ценовой диапазон</p>
                            <div className="flex flex-row space-x-4">
                                <div className="px-2 w-full py-1 border-b-[1px] border-b-[#91949E]">
                                    <p className="text-md text-[#438501]">От</p>
                                    <p className="text-lg">{formatPrice(selectedOrder.minPrice)} тг</p>
                                </div>
                                <div className="px-2 w-full py-1 border-b-[1px] border-b-[#91949E]">
                                    <p className="text-md text-[#438501]">До</p>
                                    <p className="text-lg">{formatPrice(selectedOrder.maxPrice)} тг</p>
                                </div>
                            </div>
                        </div>
                        <div className="border-b-[1px] border-b-[#91949E] flex flex-row flex-wrap">
                            {additionalInfo.map((item, index) => (
                                <div key={index} className="px-4 py-1.5 m-1 rounded-full bg-[#438501] w-fit">
                                    <p className="text-md text-white ">{item}</p>
                                </div>
                            ))}
                        </div>
                        <div className="mt-2 mb-2">
                            <p className="text-md text-[#91949E] px-2">Доп.условия</p>
                            <div className="bg-white rounded-xl p-4 shadow-lg">
                                <p className="text-md">{selectedOrder.comment}</p>
                            </div>
                        </div>
                        { parseInt(userData?.providerId) !== parseInt(selectedOrder.provider) ? (
                            <>
                                { selectedOrder.isFeedback ? (
                                    <>
                                        <div className="p-4 w-full rounded-xl border-2 border-[#5BB600] mt-4">
                                            <p className="text-lg text-[#5BB600] text-center">Вы уже откликнулись</p>
                                        </div>
                                        <p className="text-md mt-4 mb-4 text-[#91949E] text-center">Ожидайте, пока Заказчик откликнется на Вашу заявку, дабы были видны его контакты</p>
                                    </>
                                ) : (
                                    <button onClick={() => setIsOpenArgement(true)} className="p-4 w-full rounded-xl bg-[#5BB600] hover:opacity-50 mt-4 mb-6">
                                        <p className="text-lg text-white">Отправить согласие</p>
                                    </button>
                                ) }
                            </>
                        ) : (
                            <>
                                { selectedOrder.telegram && (
                                    <div className="border-b-[1px] border-b-[#91949E] py-2 px-2">
                                        <p className="text-md text-[#91949E]">Telegram</p>
                                        <p className="text-lg">{selectedOrder.telegram}</p>
                                    </div>
                                ) }
                                { selectedOrder.whatsapp && (
                                    <div className="border-b-[1px] border-b-[#91949E] py-2 px-2">
                                        <p className="text-md text-[#91949E]">WhatsApp</p>
                                        <p className="text-lg">{selectedOrder.whatsapp}</p>
                                    </div>
                                ) }
                                { selectedOrder.viber && (
                                    <div className="border-b-[1px] border-b-[#91949E] py-2 px-2">
                                        <p className="text-md text-[#91949E]">Viber</p>
                                        <p className="text-lg">{selectedOrder.viber}</p>
                                    </div>
                                ) }
                                <div className="mb-6"/>
                            </>
                        ) }
                    </div>
                </div>
            ) : (
                <div className="w-full p-2 flex flex-row flex-wrap justify-between">
                    { filteredOrders.length > 0 ? (
                        <>
                            { filteredOrders.map((item, index) => (
                                <div key={index} className="m-3 border-[1px] rounded-2xl mobile:w-full desktop:w-[46%] border-[#91949E] p-4 flex flex-col h-fit">
                                    <div className="flex flex-row w-full">
                                        <img src={`https://bigsnab.kz/api-previewImage/${item.previewImage}`} className="h-24 w-24 object-cover rounded-2xl bg-[#BDBDBD]" alt={item.previewImage}/>
                                        <div className="ml-4 flex-1 space-y-0.5">
                                            <div className="flex flex-row justify-between flex-1 items-center">
                                                <p className="mobile:text-base laptop:text-md text-[#292B2F]">{item.title}</p>
                                                <p className="text-xs text-[#91949E]">{item.date}</p>
                                            </div>
                                            <div className="flex flex-row justify-between flex-1 items-center h-auto">
                                                <div className="flex-1">
                                                    <p className="text-[#292B2F]">{formatPrice(item.price)} тг</p>
                                                    { item.count && <p className="text-[#292B2F]">{item.count} {item?.unit}</p> }
                                                </div>
                                                <button onClick={() => setSelectedOrder(item)} className="hover:opacity-50">
                                                    <IoIosArrowForward className="text-lg text-[#292B2F]"/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) }
                        </>
                    ) : (
                        <div className="w-full h-[50vh] flex-col flex justify-center items-center">
                            <p className="text-lg text-semibold">Заказов не найдено</p>
                            <p className="text-lg">По вашему запросу не найдено подходящих заказов</p>
                        </div>
                    ) }
                </div>
            ) }
            { isOpenArgement && <Agreement openWarningModal={() => setIsOpenWarningModal(true)} onClose={() => setIsOpenArgement(false)} providerInfo={providerInfo} userData={userData} clientId={selectedOrder.userId} orderId={selectedOrder.id}/> }
            { isOpenWarningModal && <WarningModal onClose={() => setIsOpenWarningModal(false)} title="Вы достигли лимита откликов" description="В этом месяце вы достигли лимита по откликам. Вы можете приобрести более лучшую версию подписки и снять ограничения"/> }
        </div>
    )
};

export default AdminPanel;